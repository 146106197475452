import React from 'react'
import './MostPopular.scss'
import "../../HomePage/DaySection/DaySection.scss";
import {BsHeart} from 'react-icons/bs'
import {MdCompare} from 'react-icons/md'
import {FaThumbtack} from 'react-icons/fa'
import PopularParagraph from './PopularParagraph';

import Pin from './../../../assets/images/pin.png'
import Image2 from './../../../assets/images/bg-popular3.jpg';
import Header from '../../Shared/Header/Header';
import SecondaryBtn from '../../Shared/Buttons/Template/SecondaryBtn';

const MostPopular = ({photographer}) => {
    return (
        <section className="section-popular page-section boxed-layout" style={{marginBottom: "0"}}>
            <Header titleType={2} title="Most<br/>Popular"/>
            <div className="popular-bottom gl-col">
                <div className="popular-left gl-col-6">
                    <div className="card-popular" style={{backgroundImage: `url(${Image2})`}}>
                        <div className="pin"><img src={Pin} alt="pin"/></div>
                    </div>
                </div>
                <div className="popular-right gl-col-6">
                    <h3>Get Prepared</h3>
                    <h4>By {photographer?.userId?.firstName} {photographer?.userId?.lastName} / {photographer?.companyName}</h4>
                    <div className="popular-price">$1000</div>
                    <SecondaryBtn className="btn-default-height">
                        Add to cart
                    </SecondaryBtn>
                    <div className="btns-group-popular">
                        <div className="btn-cube"><BsHeart/></div>
                        <div className="btn-cube"><MdCompare/></div>
                        <div className="btn-cube"><FaThumbtack/></div>
                    </div>

                    <PopularParagraph photographer={photographer}/>

                </div>
            </div>
        </section>
    )
};
export default MostPopular
