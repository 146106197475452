import React, { useState } from 'react';
import './Banderole.scss';
import SmallCard from './SmallCard/SmallCard';
import PhotographersCard from './PhotographersCard/PhotographersCard';
import BigCard from './BigCard/BigCard';
import ImageOverview from '../ImageOverview/ImageOverview';
import CardReqPhotog from '../Cards/CardReqPhotog/CardReqPhotog';

const Banderole = (props) => {
    const [modal, setModal] = useState(false);
    const [modalBackground, setModalBackground] = useState(null);

    const showOverview = () => {
        setModal(true);
    };

    const handleImageOnClick = (src) => {
        setModalBackground(src);
        showOverview();
    };

    const redirectToDetails = (id) => {
        window.location = `/image/details/${id}`;
    };

    return (
        <div className="banderole-container">
            <ImageOverview modal={modal} setModal={setModal} backgroundImage={modalBackground}/>
            <div className={`banderole-items ${props.type} ${props.className ?? ''}`}>
                {props.type === 'exhibitions' && (
                    props.cards?.length > 0
                        ? props.cards?.map((card, k) =>
                            <SmallCard
                                customClass="triple-box"
                                mainImage={card?.images[0]?.fileName}
                                secondaryImage={card?.images[1]?.fileName}
                                tertiaryImage={card?.images[2]?.fileName}
                                type={props.type} key={k}
                                title={card.title}
                                moodboardId={card._id}
                                parentAction={props.parentAction}
                            />
                        )
                        : <h4>No moodboard</h4>
                )}
                {props.type === 'images' && (
                    props.cards?.length > 0
                        ? props.cards?.map((previews, k) =>
                            <SmallCard
                                imageOnClick={() => previews._id ? redirectToDetails(previews._id) : handleImageOnClick()}
                                mainImage={previews.fileName ? previews.fileName : previews.main}
                                type={props.type}
                                key={k}
                            />
                        )
                        : <h4>No image</h4>
                )}
                {(props.type === 'photographers' && !props.photographersIds) && (
                    props.cards?.length > 0
                        ? props.cards?.map((previews, k) =>
                            <PhotographersCard
                                title={previews.title}
                                localisation={previews.place}
                                key={k}
                            />
                        )
                        : <h4 style={{height: "100px"}}>No photographer</h4>
                )}
                {(props.type === 'media-users') && (
                    props.medias?.length > 0
                        ? props.medias?.map((media, k) =>
                            <CardReqPhotog
                                item={media}
                                key={k}
                                type="reviewing"
                            />
                        )
                        : <h4 style={{height: "100px"}}>No media user</h4>
                )}
                {(props.type === 'photographers' && props.photographersIds) && (
                    props.photographersIds?.length > 0
                        ? props.photographersIds?.map((id, k) =>
                            <PhotographersCard key={k} id={id}/>
                        )
                        : <h4 style={{height: "100px"}}>No photographer</h4>
                )}
                {props.type === 'big-card' && props.cards?.map((previews, k) =>
                    <BigCard
                        title={previews.title}
                        bg={previews.main}
                        key={k}
                    />
                )
                }
                {(props.type === 'edit-story' || props.type === 'public-story') && props.cards?.map((previews, k) =>
                    <BigCard
                        id={previews._id}
                        title={previews.title}
                        bg={previews?.images?.[0]?.fileName}
                        key={k}
                        type={props.type}
                        isSerie={props.isSerie}
                    />
                )
                }
                {props.type === 'agencies' && (
                    props.agencies?.length > 0
                        ? props.agencies?.map((agency, k) =>
                            <CardReqPhotog
                                item={agency}
                                key={k}
                                type="reviewing"
                            />
                        )
                        : <h4 style={{height: "100px"}}>No agency</h4>
                )}
            </div>
        </div>
    );
};
export default Banderole;
