import React from "react";
import { useState } from "react";
import Footer from "../components/Shared/PageLayout/Footer/Footer";
import Navbars from "../components/Shared/PageLayout/Navbar/navbar";
import BtnBack from "../components/Shared/Buttons/BtnBack/BtnBack";
import BtnClassic from "../components/Shared/Buttons/BtnClassic/BtnClassic";
import BtnTag from "../components/Shared/Buttons/BtnTag/BtnTag";
import Edit from "../components/Shared/Buttons/Edit/Edit";
import ProfileBtn from "../components/Shared/Buttons/ProfileBtn/ProfileBtn";
import CardEdit from "../components/Shared/Cards/CardEdit/CardEdit";
import CardSerie from "../components/Shared/Cards/CardSeries/CardSerie";
import LittleCard from "../components/Shared/Cards/LittleCard/LittleCard";
import MediumCard from "../components/Shared/Cards/MediumCard/MediumCard";
import Container500 from "../components/Shared/Containers/Container500/Container500";
import ContainerDirection from "../components/Shared/Containers/ContainerRow/ContainerDirection";
import ImageOverview from "../components/Shared/ImageOverview/ImageOverview";
import LineBlack from "../components/Shared/Line/LineBlack";
import Notif from "../components/Shared/Notif/Notif";

import Image1 from "../assets/images/bg-exhi1.jpg";
import OverUpload from "../components/Shared/OverUpload/OverUpload";

const Testregistertest = () => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <OverUpload />
      <ContainerDirection
        bg="#F55D76"
        align="center"
        justify="center"
        width="100%"
        gap="26px"
      >
        <ProfileBtn>Save</ProfileBtn>
        <BtnTag>Tag</BtnTag>
        <BtnClassic>Add Images</BtnClassic>
      </ContainerDirection>

      <ContainerDirection
        height="500px"
        bg="#DE5849"
        align="center"
        justify="center"
        width="100%"
        gap="26px"
        direction="column"
      >
        <ProfileBtn>Save</ProfileBtn>
        <BtnTag width="180px">Other Long Tag</BtnTag>
        <BtnClassic>Add Images</BtnClassic>
      </ContainerDirection>
      <ContainerDirection
        bg="#6153FA"
        align="center"
        justify="center"
        height="600px"
        width="100%"
        gap="26px"
        direction="column"
      >
        <ContainerDirection
          bg="#F55D76"
          width="400px"
          direction="column"
          overflow="hidden"
        >
          <BtnClassic width="300px">Add Images</BtnClassic>
          <BtnClassic>Add Images</BtnClassic>
          <BtnClassic width="500px" margin="10px 0">
            Add Images
          </BtnClassic>
        </ContainerDirection>
        <ContainerDirection bg="#F55D76" width="400px">
          <ContainerDirection justify="center" align="center" gap="5px">
            <BtnTag>Tag</BtnTag>
            <BtnTag>Tag</BtnTag>
            <BtnTag>Tag</BtnTag>
          </ContainerDirection>
          <ContainerDirection
            direction="column"
            width="50%"
            bg="#000"
            justify="center"
            align="center"
            gap="50px"
          >
            <BtnTag left="20px">Tag</BtnTag>
            <BtnTag>Tag</BtnTag>
          </ContainerDirection>
        </ContainerDirection>
      </ContainerDirection>
      <ContainerDirection
        width="100%"
        height="500px"
        align="center"
        justify="center"
        gap="70px"
      >
        <CardSerie serie="test" color="red" />
        <CardSerie serie="test" />
        <LittleCard />
        <MediumCard />
      </ContainerDirection>
      <ContainerDirection bg="#4E2DE0" width="100%" height="500px">
        <BtnBack top="18px">Cancel</BtnBack>
        <Notif top="200px" bg="yellow" color=" black">
          99+
        </Notif>
        <Notif left="50px">9</Notif>
        <Notif top="100px">6</Notif>
        <LineBlack top="200px" left="300px" />
        <Edit top="300px" />
      </ContainerDirection>

      <ContainerDirection
        align="center"
        justify="center"
        width="100%"
        height="500px"
        gap="16px"
      >
        <CardEdit
          title="Image title"
          subtitle="Why Re Invent The Light Bulb…"
          price="$ 1,000"
        />
        <CardEdit
          title="Image title"
          subtitle="Why Re Invent The Light Bulb…"
          price="$ 1,000"
          backgroundImage={Image1}
          onClickImage={() => setModal(!modal)}
        />
      </ContainerDirection>
      <ImageOverview
        backgroundImage={Image1}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
};

export default Testregistertest;
