import React, {useEffect, useState} from 'react';
import {AiFillCaretDown as CaretDown, AiFillCaretUp as CaretUp} from 'react-icons/ai';
import TfSlider from './TfSlider/TfSlider';

const defaultPeriod = [1900, (new Date()).getFullYear()]

const TimeframeFilter = ({period, updatePeriod}) => {
    const setDefaultValue = () => {
        return [
            period.from ? +period.from : defaultPeriod[0],
            period.to ? +period.to : defaultPeriod[1]
        ];
    }
    const [isExpanded, setIsExpanded] = useState(false);
    const [value, setValue] = React.useState(setDefaultValue());

    const toggleSlider = () => {
        setIsExpanded(!isExpanded);
    }

    const handleSave = () => {
        updatePeriod({from: +value[0], to: +value[1]})
    }

    useEffect(() => {
        setValue(setDefaultValue());
    }, [period]);

    return (
        <div>
            <div className="btn-timeframe" onClick={toggleSlider}>
                <p className="tf-subtitle">Timeframe</p>
                <p className="tf-value typo-size-smaller">
                    from: <span>{period.from ? period.from : '-'}</span>
                    to: <span>{period.to ? period.to : '-'}</span>
                </p>
                {isExpanded
                    ? <CaretUp className="timeframe-caret"/>
                    : <CaretDown className="timeframe-caret"/>
                }
            </div>
            {isExpanded &&
            <TfSlider value={value}
                      period={period}
                      setValue={setValue}
                      handleSave={handleSave}
                      defaultPeriod={defaultPeriod}
            />
            }
        </div>
    );
};
export default TimeframeFilter;
