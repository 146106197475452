import React from "react";
import FormUploadImage from "../../components/PhotographerAction/FormUploadImage/FormUploadImage";
import PhotoUploadProvider from "../../context/UploadContext";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const Formuploadimage = () => {
    return (
        <PhotoUploadProvider>
            <PageLayout>
                <FormUploadImage/>
            </PageLayout>
        </PhotoUploadProvider>
    );
};

export default Formuploadimage;
