import React from 'react';
import Moodboard from '../../components/Shared/Moodboard/MoodboardList/Moodboard';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {useHistory} from 'react-router-dom';
import MoodboardsList from '../../components/PhotographerAction/MoodboardsList/MoodboardsList';

const Mymoodboardlist = () => {
    const history = useHistory();
    const parentAction = (moodboardId) => history.push(`/moodboard/edit/${moodboardId}`);
    return (
        <PageLayout>
            <Moodboard/>
            <MoodboardsList noControls className="moodboards-list" parentAction={parentAction}/>
        </PageLayout>
    );
};

export default Mymoodboardlist;
