import React from 'react';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout';
import { MoodBoardForm } from '../../../components/PhotographerAction/Moodboard/Forms/Form';


export const AddMoodboardFormContainer = () => {
    return(
        <PageLayout>
            <MoodBoardForm />
        </PageLayout>
    )
}