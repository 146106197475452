import React from 'react';
import './GetPrepareHeader.scss';
import {BsHeart} from 'react-icons/bs';
import {FaThumbtack} from 'react-icons/fa';
import {BiShoppingBag} from 'react-icons/bi';
import {Link} from 'react-router-dom';

const GetPrepareHeader = ({image, photographer}) => {
    return (
        <div className="header-container gl-col-12">
            <header className="details-header">
                <h3>{image?.title}</h3>
                <Link style={{color: "initial", textDecoration: "none"}}
                      to={`/photographer/details?photographerId=${photographer?._id}`}
                >
                    {image?.credit &&
                    <p style={{marginBottom: "0"}}>By {`${image?.credit}`}</p>
                    }
                </Link>
            </header>
            <div className="btns-gtprep typo-size-smaller" style={{marginBottom: "1rem"}}>
                <div className="like">
                    <BsHeart/>
                    {image?.totalLikes} likes
                </div>
                <div className="pins">
                    <FaThumbtack/>
                    {image?.totalPins} pins
                </div>
                <div className="purchased">
                    <BiShoppingBag/>
                    {image?.totalPurchases} Purchased
                </div>
            </div>
        </div>
    );
}
export default GetPrepareHeader;
