import React, { useEffect, useState } from "react";
import "./StoryCredits.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircleLoc from "../../../Shared/CircleLoc/CircleLoc";
import { config } from "../../../../environments";
import { useHttpClient } from "../../../../hooks/http-hook";

const StoryCredits = (props) => {
  const labelEnum = { INITIAL: "Share Now", COPIED: "Link copied !" };
  const [copied, setCopied] = useState(false);
  const [btnLabel, setBtnLabel] = useState(labelEnum.INITIAL);

  const [photographer, setPhotographer] = useState();
  const photographerUrl = `${config.baseUrlDev}/photographer/${props.photographerId}`;
  const { sendRequest } = useHttpClient();

  const shareUrl = `${window.location.protocol}://${window.location.host}/story/details/${props.storyId}`;

  const retrievePhotographer = async () => {
    await sendRequest(photographerUrl, "GET")
      .then((data) => setPhotographer(data))
      .catch((e) => console.warn("not found"));
  };

  const onCopy = () => {
    return !copied ? setCopied(true) : null;
  };

  useEffect(() => {
    if (copied === true) {
      setBtnLabel(labelEnum.COPIED);
      setTimeout(() => setCopied(false), 6000);
    } else {
      setBtnLabel(labelEnum.INITIAL);
    }
  }, [copied]);

  useEffect(() => {
    if (props.photographerId) {
      retrievePhotographer();
    }
  }, [props.photographerId]);

  return (
    <div
      className={`story-credits ${props.className ?? ""}`}
      style={props.style}
    >
      <div className="story-author">
        <CircleLoc />
        <div className="author-label">
          Created by
          <br />
          {photographer?.userId?.firstName} {photographer?.userId?.lastName}
        </div>
      </div>
      <div className="btns-container">
        <CopyToClipboard onCopy={onCopy} text={shareUrl}>
          <span className={`button ${copied ? "secondary" : "primary"}`}>
            {btnLabel}
          </span>
        </CopyToClipboard>
        <div className="tag tertiary">URL: /story/details/{props.storyId}</div>
      </div>
    </div>
  );
};

export default StoryCredits;
