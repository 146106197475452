import React from 'react';
import './UploadResults.scss';
import ResultCard from '../ResultCard/ResultCard';

const UploadResults = (props) => {
    return (
        <ul className="upload-results-list">
            {
                props.results.map((res, k) => {
                    return (
                        <ResultCard key={k}
                                    id={res.id}
                                    name={res.fileName}
                                    original={res.originalName}
                                    status={res.status}
                        >
                            {res.fileName}
                        </ResultCard>
                    )
                })
            }
        </ul>
    )
}
export default UploadResults;
