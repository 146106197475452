import React, {useEffect, useState} from 'react';
import './PhotographerSidebar.scss'

import SidebarModal from '../../../Shared/SidebarModal/SidebarModal';
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import SecondaryBtn from '../../../Shared/Buttons/Template/SecondaryBtn';
import TertiaryBtn from '../../../Shared/Buttons/Template/TertiaryBtn';
import {useHistory} from 'react-router-dom';
import ContactBtn from '../../../Shared/Buttons/ContactBtn/ContactBtn';

const SidebarPhotoReview = ({image, setModal, parentAction}) => {
    const {sendRequest} = useHttpClient();
    const history = useHistory();
    const [photographer, setPhotographer] = useState();

    useEffect(() => {
        if (image?.photographer && !photographer) {
            getPhotographer(image?.photographer);
        }
    }, []);

    const getPhotographer = async (photographerId) => {
        return await sendRequest(
            `${config.baseUrlDev}/photographer/${photographerId}`,
        )
            .then((res) => setPhotographer(res))
            .catch((e) => console.warn({e}));
    }

    return (
        <SidebarModal className="images-details"
                      setModal={setModal}
                      type="review-image"
                      parentAction={parentAction}
                      author={photographer}
                      image={image}
                      leftPanel
        >
            <header>
                <h4>Photo infos</h4>
            </header>

            <ContactBtn email={photographer?.userId?.email}>
                <SecondaryBtn className="email-link action-btn btn-full-width">
                    Contact by e-mail
                </SecondaryBtn>
            </ContactBtn>
            <TertiaryBtn className="action-btn wired"
                         parentAction={() => history.push(`/upload/edit/${image._id}`)}
            >
                Modify metadata
            </TertiaryBtn>
        </SidebarModal>
    )
};
export default SidebarPhotoReview;
