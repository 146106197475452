import React from 'react'
import './MostAll.scss'
import pin from "../../../assets/images/pin.png";
import Header from '../../Shared/Header/Header';


const MostAll = () => {
    return (
        <>
            <div className="section-mostall page-section boxed-layout">
                <div className="most-liked">
                    <Header titleType={2} title="Most<br/>Liked"/>
                    <div className="card-most">
                        <img className="pin-pixt" src={pin} alt="pin-pixt"/>
                    </div>
                </div>
                <div className="most-purchased">
                    <div className="card-most">
                        <img className="pin-pixt" src={pin} alt="pin-pixt"/>
                    </div>
                    <Header style={{justifyContent: "flex-end"}} className="row-reverse" titleType={2} title="Most<br/>Purchased"/>
                </div>
            </div>
        </>
    )
};
export default MostAll;
