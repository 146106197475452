import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Tag from "../../components/ExplorePage/TagAll/Tag/Tag";
import StoryDetails from "../../components/PhotographerAction/Story/StoryDetails/StoryDetails";
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import { Redirect } from "react-router-dom";
import SimpleImageGrid from "../../components/Shared/SimpleImageGrid/SimpleImageGrid";
import { useHttpClient } from "../../hooks/http-hook";
import { config } from "../../environments";

const StoryDetail = ({ match }) => {
  const storyId = match?.params?.storyId;
  const storyUrl = `${config.baseUrlDev}/story/${storyId}`;

  const [story, setStory] = useState();

  const { sendRequest } = useHttpClient();
  const history = useHistory();

  useEffect(async () => {
    if (storyId) {
      await sendRequest(storyUrl, "GET")
        .then((data) => setStory(data))
        .catch((e) => setStory(null));
    }
  }, [storyId]);

  return !storyId || story === null ? (
    <Redirect to="/not-found" />
  ) : (
    <PageLayout>
      <StoryDetails story={story} />
      {story?.keywords?.length > 0 && (
        <div
          className="display-flex boxed-layout justify-content-end"
          style={{ marginBottom: "1rem" }}
        >
          {story?.keywords?.map((kw, k) => (
            <Tag className="tag-item" key={k}>
              {kw}
            </Tag>
          ))}
        </div>
      )}
      <SimpleImageGrid images={story?.images} mode="public" />
    </PageLayout>
  );
};

export default StoryDetail;
