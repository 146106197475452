import React, {useContext, useEffect, useState} from 'react';
import './AgencyGallery.scss';
import Image4 from "../../../assets/images/bg-same3.jpg";
import Image2 from "../../../assets/images/bg-myimg1.jpg";
import Image5 from "../../../assets/images/bg-myimg3.jpg";
import Image1 from "../../../assets/images/bg-about1.jpg";
import Image3 from "../../../assets/images/bg-myimg2.jpg";
import Image7 from "../../../assets/images/bg-myimg5.jpg";
import Image6 from "../../../assets/images/bg-myimg4.jpg";
import PicturesGridRegular from "../../Shared/Pictures/PicturesGridRegular/PicturesGridRegular";
import {useHttpClient} from '../../../hooks/http-hook';
import {AuthContext} from '../../../context/auth-context';
import {config} from '../../../environments';
import {statusEnum} from '../../../assets/statics/reviews-step.enum';

const AgencyGallery = ({agencyId}) => {
    const {sendRequest} = useHttpClient();

    const [images, setImages] = useState([]);

    const retrieveAllImages = async () => {
        const url = `${config.baseUrlDev}/images/filter?status=${statusEnum.ACCEPTED}&agencyId=${agencyId}`
        await sendRequest(url, 'GET', null)
            .then((data) => setImages(shuffleArray(data).slice(0, 7)))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (agencyId) {
            retrieveAllImages();
        }
    }, [agencyId]);

    const fakeData = {
        title: 'Gallery',
        data: [
            { main: Image1 },
            { main: Image2 },
            { main: Image3 },
            { main: Image4 },
            { main: Image5 },
            { main: Image6 },
            { main: Image7 },
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };
    return <PicturesGridRegular title={fakeData.title} data={images.length > 0 ? shuffleArray(images) : shuffleArray(fakeData.data)} />;
};
export default AgencyGallery;
