import React, {useEffect} from "react";
import "./ValidatePurchase.css";
import {SiLetsencrypt} from "react-icons/si";
import Aos from "aos";
import "aos/dist/aos.css";
import PageLayout from '../../Shared/PageLayout/PageLayout';

const ValidatePurchase = () => {
    useEffect(() => {
        Aos.init({duration: 2000});
    }, []);
    return (
        <PageLayout>
            <div className="section-acc-create page-section boxed-layout" data-aos="zoom-in-up">
                <div className="box-acc-create">
                    <div className="content-acc-create">
                        <h2>Purchase validated with success</h2>
                        <div>
                            <SiLetsencrypt className="icon-encrypt"/>
                        </div>
                        <div className="validation-message">
                            <h3>Blockchain encrypted key for image ...</h3>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
export default ValidatePurchase;
