import React, {Component} from "react";

const ShopContext = React.createContext();

class ShopProvider extends Component {
    state = {
        produits: [],
        product: {},
        checkout: {},
        isCartopen: false,
        test: "test",
    };

    getStripeCart = () => {
        const c = localStorage.getItem('pixt_checkout_cart');
        try {
            return JSON.parse(c);
        } catch (e) {
            localStorage.removeItem('pixt_checkout_cart');
            return undefined;
        }
    }

    setStripeCart = (newCart) => {
        localStorage.setItem('pixt_checkout_cart', JSON.stringify(newCart));
    }

    clearStripeCart = () => {
        return localStorage.setItem('pixt_checkout_cart', JSON.stringify([]));
    }

    addItemToCheckout = async (id, priceId, token) => {
        if (!token) {
            return;
        }
        let cart = this.getStripeCart();
        if (!cart) {
            cart = [];
        }
        const exist = cart.find(p => {
            return p.id === id;
        });
        if (!exist) {
            cart.push({id, priceId: priceId, quantity: 1});
        }
        localStorage.setItem('pixt_checkout_cart', JSON.stringify(cart));
    };

    closecart = async () => {
        this.setState({isCartopen: false});
    };
    opencart = async () => {
        this.setState({isCartopen: true});
    };

    render() {
        return (
            <ShopContext.Provider
                value={{
                    ...this.state,
                    closecart: this.closecart,
                    opencart: this.opencart,
                    getStripeCart: this.getStripeCart(),
                    setStripeCart: this.setStripeCart,
                    addItemToCheckout: this.addItemToCheckout,
                }}
            >
                {this.props.children}
            </ShopContext.Provider>
        );
    }
}

const ShopConsumer = ShopContext.Consumer;
export {ShopConsumer, ShopContext};

export default ShopProvider;
