import React, {useEffect, useState} from 'react';
import './SidebarPhotographer.scss';

import SidebarModal from '../../../Shared/SidebarModal/SidebarModal';
import SecondaryBtn from '../../../Shared/Buttons/Template/SecondaryBtn';
import PhotographerField from './PhotographerField/PhotographerField';
import {config} from '../../../../environments';
import {useHttpClient} from '../../../../hooks/http-hook';
import {reviewTypeEnum} from '../../../../assets/statics/review-type.enum';
import {Link} from 'react-router-dom';
import TertiaryBtn from '../../../Shared/Buttons/Template/TertiaryBtn';
import {viewAdminEnum} from '../../../../assets/statics/reviews-admin-step.enum';
import ContactBtn from '../../../Shared/Buttons/ContactBtn/ContactBtn';
import useReadableAddress from '../../../../hooks/useReadableAddress';

const SidebarPhotographer = ({photographer, setModal, type, requestData, parentAction, dataType}) => {
    const [agency, setAgency] = useState();
    const {sendRequest} = useHttpClient();
    const {getAddress, addressTypeEnum} = useReadableAddress();

    useEffect(() => {
        if (type === reviewTypeEnum.AGENCY) {
            fetchOwnedAgency();
        }
    }, [type]);

    const fetchOwnedAgency = async () => {
        const url = `${config.baseUrlDev}/agency/user/${photographer._id}`;
        return await sendRequest(url, 'GET', null, {'Content-Type': 'application/json'})
            .then((data) => setAgency(data))
            .catch((e) => console.warn({e}));
    }

    return (
        <SidebarModal className="photographer-details"
                      setModal={setModal}
                      leftPanel
                      type={type}
                      parentAction={parentAction}
                      requestData={requestData}
                      photographer={photographer}
                      agency={agency}
        >
            <header>
                <h4>{type === reviewTypeEnum.AGENCY ? 'Agency info' : 'Personal info'}</h4>
            </header>
            <ContactBtn email={photographer?.userId ? photographer?.userId?.email : photographer?.email}>
                <SecondaryBtn className="email-link action-btn btn-full-width">
                    Contact by e-mail
                </SecondaryBtn>
            </ContactBtn>
            {(dataType !== viewAdminEnum.MEDIA_USERS && dataType !== viewAdminEnum.PHOTOGRAPHERS && type !== reviewTypeEnum.AGENCY) &&
            <TertiaryBtn className="action-btn">
                <Link to={`/photographer/details?photographerId=${photographer?._id}`}>
                    Photographer's page
                </Link>
            </TertiaryBtn>
            }
            {agency &&
            <PhotographerField label="Agency" value={agency?.companyName}/>
            }
            <PhotographerField label="First Name" value={photographer?.userId ? photographer?.userId?.firstName : photographer?.firstName}/>
            <PhotographerField label="Last Name" value={photographer?.userId ? photographer?.userId?.lastName : photographer?.lastName}/>
            {!agency &&
            <PhotographerField label="Job title" value={photographer?.job ? photographer?.job : photographer?.jobTitle ? photographer?.jobTitle : photographer?.userId?.job}/>
            }
            <PhotographerField label="Location" value={photographer?.userId ? getAddress(photographer?.userId?.address, addressTypeEnum.SHORT) : getAddress(photographer?.address, addressTypeEnum.SHORT)}/>
            <PhotographerField label={agency ? 'Email contact' : 'Email'} value={photographer?.userId ? photographer?.userId?.email : photographer?.email}/>
            <PhotographerField label="Telephone contact" value={photographer?.userId ? photographer?.userId?.phoneNumber : photographer?.phoneNumber}/>
        </SidebarModal>
    )
};
export default SidebarPhotographer;
