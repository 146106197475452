import React, {useEffect, useState} from "react";
import MoodBoard from "../../components/Shared/Moodboard/MoodboardPublic/moodboard";
import MoodboardHeader from "../../components/Shared/Moodboard/MoodboardPublic/MoodboardHeader";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {useHttpClient} from '../../hooks/http-hook';
import {Redirect, useHistory} from 'react-router-dom';
import {config} from '../../environments';
import SimpleImageGrid from '../../components/Shared/SimpleImageGrid/SimpleImageGrid';
import Tag from '../../components/ExplorePage/TagAll/Tag/Tag';

const MoodBoardjscript = ({match}) => {
    const moodboardId = match?.params?.moodboardId;
    const moodboardUrl = `${config.baseUrlDev}/moodboard/${moodboardId}`;

    const [moodboard, setMoodboard] = useState();

    const {sendRequest} = useHttpClient();
    const history = useHistory();

    useEffect(async () => {
        if (moodboardId) {
            await sendRequest(moodboardUrl, 'GET')
                .then((data) => setMoodboard(data))
                .catch((e) => setMoodboard(null));
        }
    }, [moodboardId]);

    return !moodboardId || moodboard === null
        ? <Redirect to="/not-found"/>
        : (
            <PageLayout>
                <MoodboardHeader moodboard={moodboard}/>
                {moodboard?.keywords?.length > 0 &&
                <div className="display-flex boxed-layout justify-content-end" style={{marginBottom: "1rem"}}>
                    {moodboard?.keywords?.map((kw, k) => <Tag className="tag-item" key={k}>{kw}</Tag>)}
                </div>
                }
                <SimpleImageGrid images={moodboard?.images}
                                 mode="public"
                />
            </PageLayout>
        );
};

export default MoodBoardjscript;
