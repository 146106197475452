import React from 'react'
import './BoxContent.css'
import Image1 from '../../../assets/images/bg-about6.jpg'
import Image2 from '../../../assets/images/bg-about7.jpg'
import Image3 from '../../../assets/images/bg-about8.jpg'

const BoxContent = () => {
    return (
        <>
            <div className="section-about-bxcontent">
                <div className="bxcontent-top">
                    <div className="line-bxcontent"></div>
                    <h3>Content</h3>
                </div>
                <div className="bxcontent-grid">
                    <div className="box-bx-content" style={{backgroundImage: `url(${Image1})`}}></div>
                    <div className="box-bx-content" style={{backgroundImage: `url(${Image2})`}}></div>
                    <div className="box-bx-content" style={{backgroundImage: `url(${Image3})`}}></div>
                </div>
            </div>
        </>
    )
}

export default BoxContent
