import React, { useState } from "react";
import BtnClassic from "../../../../Shared/Buttons/BtnClassic/BtnClassic";
import "./MColorsData.scss";

const MColorsData = (props) => {
  const [defaultdatas, setDefaultDatas] = useState([
    {
      rgb: "rgb(32, 115, 9)",
      hex: "#207309",
    },
    {
      rgb: "rgb(166, 45, 5)",
      hex: "#a62d05",
    },
    {
      rgb: "rgb(240, 240, 216)",
      hex: "#f0f0d8",
    },
    {
      rgb: "rgb(12, 15, 17)",
      hex: "#0c0f11",
    },
    {
      rgb: "rgb(87, 95, 104)",
      hex: "#575f68",
    },
  ]);

  return (
    <>
      <div className="box-mcolors-edit">
        <h3>Main Colors</h3>
        {defaultdatas.map((val, k) => {
          return (
            <div className="mcolors-datas" key={k}>
              <div
                className="cube-mcolors"
                style={{ background: val.hex }}
              ></div>
              <div className="content-mcolors">
                <div className="rgb-data"><h3>RGB</h3><h4>{val.rgb}</h4></div>
                <div className="hex-data"><h3>HEX</h3><h4>{val.hex}</h4></div>
              </div>
            </div>
          );
        })}

        <div className="btns-row-up">
          <BtnClassic
            width="127px"
            height="36px"
            bg="#f3f5f7"
            color="#000"
            position="relative"
            margin="20px 0px"
            left="0"
            onClick={() => props.handleBtnClick(props.type, false)}
          >
            Prev
          </BtnClassic>
          <BtnClassic
            width="127px"
            height="36px"
            bg="#FFE942"
            color="#000"
            position="relative"
            margin="20px 0px"
            left="183px"
            justifySelf="flex-end"
            onClick={() => props.handleBtnClick(props.type, true)}
          >
            Next
          </BtnClassic>
        </div>
      </div>
    </>
  );
};

export default MColorsData;
