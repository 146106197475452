const url = window.location.toString();

const isProdEnv = () => {
    return !(url.includes('127.0.0.1:3000') || url.includes('localhost:3000') || url.includes('42cloud'));
}

const isPreprodEnv = () => {
    return url.includes('preprod.42cloud');
}

const isStagingEnv = () => {
    return url.includes('sandbox.42cloud');
}

const isLocalEnv = () => {
    return !(isProdEnv() || isPreprodEnv() || isStagingEnv());
}

module.exports = {isProdEnv, isPreprodEnv, isStagingEnv, isLocalEnv};
