import React from 'react';
import {BsArrowLeft} from 'react-icons/bs';

const ViewError = ({setCurrentView, addToMbViewEnum}) => {
    return (
        <div style={{padding: "0.5rem 1rem"}}>
            <div onClick={() => setCurrentView(addToMbViewEnum.SELECT)}>
                <BsArrowLeft/> Back
            </div>
            <div className="text-center">
                An error occured and<br/>we were unable<br/>to perform the desired action.
                <br/>Please try again.
            </div>
        </div>
    )
}
export default ViewError;
