import React from "react";
import Navbars from "../../components/Shared/PageLayout/Navbar/navbar";
import Order from "../../components/CheckoutAction/Order/Order";

const Order1 = () => {
  return (
    <>
      <Navbars />
      <Order />
    </>
  );
};

export default Order1;
