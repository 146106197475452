import React from 'react';
import {BsArrowLeft} from 'react-icons/bs';
import SecondaryBtn from '../../../Buttons/Template/SecondaryBtn';

const ViewCreate = ({setCurrentView, addToMbViewEnum, handleTitleChange, createMoodboard, newTitle}) => {
    return (
        <div>
            <div className="display-flex flex-column"
                 style={{padding: "0.5rem 1rem"}}
            >
                <p onClick={() => setCurrentView(addToMbViewEnum.SELECT)} className="typo-size-small">
                    <BsArrowLeft/> Create new moodboard
                </p>
                <label htmlFor="new-title">Name</label>
                <input type="text" id="new-title" className="input-reg" value={newTitle}
                       onChange={handleTitleChange}/>
            </div>
            <SecondaryBtn parentAction={() => (newTitle.trim() !== '') ? createMoodboard() : null}
                          className={`btn-default-height btn-full-width ${newTitle.trim() === '' ? 'disabled' : ''}`}
                          style={{marginLeft: "0", fontSize: "1em"}}
            >
                Create
            </SecondaryBtn>
        </div>
    );
};
export default ViewCreate;
