import React, {useContext, useEffect, useState} from 'react';
import './AgencySetting.scss';
import AgencySelect from './AgencySelect/AgencySelect';
import AgencyList from './AgencyList/AgencyList';
import {AuthContext} from '../../../../context/auth-context';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';
import AgencyCard from './AgencyCard/AgencyCard';
import {config} from '../../../../environments';
import {useHttpClient} from '../../../../hooks/http-hook';
import Toaster from '../../Toaster/Toaster';
import { render } from 'react-dom';

const AgencySetting = () => {
    const {sendRequest} = useHttpClient();
    const {userId, userStatus, userType} = useContext(AuthContext);
    const isPhotographer = userType === roleEnum.PHOTOGRAPHER;
    const isAccepted = userStatus === statusEnum.ACCEPTED;
    const [ownedAgency, setOwnedAgency] = useState();

    const fetchOwnedAgency = async () => {
        const url = `${config.baseUrlDev}/agency/user/${userId}`;
        return await sendRequest(url, 'GET', null, {'Content-Type': 'application/json'})
            .then((data) => setOwnedAgency(data))
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg="Unexpected error during fetch of agencies"/>)
            });
    }

    useEffect(() => {
        if (userType === roleEnum.AGENCY && userId) {
            fetchOwnedAgency();
        }
    }, [userType]);

    return (
        <div>
            {isPhotographer
                ? isAccepted
                    ? (<>
                        <AgencySelect/>
                        <AgencyList style={{marginTop: "4rem"}}/>
                    </>)
                    : <p>Your account must be accepted to access to this setting.</p>
                : ownedAgency &&
                (<>
                    <h2>Agency</h2>
                    <AgencyCard id={ownedAgency._id} name={ownedAgency.companyName}/>
                </>)
            }
        </div>
    )
}
export default AgencySetting;
