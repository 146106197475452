import React from "react";
import Navbars from "../../components/Shared/PageLayout/Navbar/navbar";
import Footer from "../../components/Shared/PageLayout/Footer/Footer";
import MicroPayment from "../../components/CheckoutAction/MicroPayment2/MicroPayment";

const MicroPayment2 = () => {
  return (
    <>
      <Navbars />
      <MicroPayment />
      <Footer />
    </>
  );
};

export default MicroPayment2;
