import React, {useEffect, useState} from 'react';
import {AiFillCaretDown as Caret, AiFillCaretRight as RightCaret} from 'react-icons/ai';

const InputLayout = ({urlLayout, updateSearchValues}) => {
    const [layout, setLayout] = useState(false);
    const [layoutValue, setLayoutValue] = useState(false);
    const [searchLayout, setSearchLayout] = useState();

    const handleLayoutVal = (event) => {
        const displayedValue = event.target.getAttribute('data-value');
        const searchValue = event.target.getAttribute('data-search');
        if (searchValue === searchLayout) {
            setLayoutValue();
            setSearchLayout();
            setLayout(!layout);
        } else {
            setLayoutValue(displayedValue);
            setSearchLayout(searchValue);
            setLayout(!layout);
        }
    };

    useEffect(() => {
        setSearchLayout(urlLayout);
        if (urlLayout) {
            return setLayoutValue(urlLayout === '1' ? 'Vertical' : 'Horizontal');
        }
    }, [urlLayout]);

    useEffect(() => {
        updateSearchValues({layout: searchLayout});
    }, [searchLayout]);

    return (
        <div className="search-exp-layout search-custom-dropdown search-bar-item"
             onClick={() => setLayout(!layout)}
        >
            <span className="search-input-data">Layout</span>
            <span>{layoutValue}</span>
            {layout ? (
                <Caret className="search-caret"/>
            ) : (
                <RightCaret className="search-caret"/>
            )}
            {layout && (
                <div className="search-dropdown">
                    <span onClick={handleLayoutVal} data-value="Horizontal" data-search="0">Horizontal</span>
                    <span onClick={handleLayoutVal} data-value="Vertical" data-search="1">Vertical</span>
                </div>
            )}
        </div>
    )
}
export default InputLayout;
