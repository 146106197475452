import React from "react";
import "./ContainerDirection.css";

const ContainerDirection = (props) => {
  return (
    <>
      <div
        className={`container-direction ${props.className}`}
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundColor: props.bg,
          padding: props.padding,
          height: props.height,
          minHeight: props.minHeight,
          width: props.width,
          margin: props.margin,
          flexDirection: props.direction,
          alignItems: props.align,
          justifyContent: props.justify,
          gap: props.gap,
          overflow: props.overflow,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          border: props.border,
          position: props.position,
          zIndex: props.zIndex,
          display:props.display,
          gridTemplateColumns: props.gridtemplatecol,
          gridGap: props.gridgap
        }}
        
      >
        {props.children}
      </div>
    </>
  );
};

export default ContainerDirection;
