import React, {useContext, useEffect, useState} from "react";
import {roleEnum} from '../../../assets/statics/authent-signup.enum';
import {AuthContext} from '../../../context/auth-context';
import {config} from '../../../environments';
import {useHttpClient} from '../../../hooks/http-hook';
import SmallBanderole from '../../Shared/Banderole/Template/SmallBanderole/SmallBanderole';
import './MoodboardsList.scss';

const BASE_FILTER_MOODBOARD_URL = `${config.baseUrlDev}/moodboard/filter`

const MoodboardsList = (props) => {
    const {isLoading, sendRequest} = useHttpClient();
    const {userIdProfile, userType, token} = useContext(AuthContext);

    const [moodboards, setMoodboards] = useState([]);

    const generateFilterMoodboardRoleQuery = (role) => {
        let query = '';
        if (role === roleEnum.PHOTOGRAPHER) {
            query = `photographerId=${userIdProfile}`;
        }

        if (role === roleEnum.AGENCY) {
            query = `agencyId=${userIdProfile}`;
        }

        return query;
    }

    useEffect(() => {
        if (userType) {
            const loadCurrentUserMoodboard = async () => {
                const roleQuery = generateFilterMoodboardRoleQuery(userType);

                const found = await sendRequest(
                    `${BASE_FILTER_MOODBOARD_URL}?${roleQuery}`,
                    'GET',
                    null,
                    {'Content-Type': 'application/json', 'cookies': token}
                )

                if (found && !isLoading) {
                    setMoodboards(found);
                }
            }

            if (userIdProfile) {
                loadCurrentUserMoodboard();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType, userIdProfile])

    const fakeData = {
        data: [
            {images: [{fileName: 'bg-myimg5.jpg'}, {fileName: 'bg-other9.jpg'}, {fileName: 'bg-other8.jpg'}]},
            {images: [{fileName: 'bg-exhi02.jpg'}, {fileName: 'bg-exhi3.jpg'}, {fileName: 'bg-exhi4.jpg'}]},
            {images: [{fileName: 'bg-exhi03.jpg'}, {fileName: 'bg-exhi5.jpg'}, {fileName: 'bg-popular2.jpg'}]},
            {images: [{fileName: 'bg-exploreall.jpg'}, {fileName: 'bg-explore1.jpg'}, {fileName: 'bg-other2.jpg'}]},
            {images: [{fileName: 'bg-other3.jpg'}, {fileName: 'bg-other6.jpg'}, {fileName: 'bg-other5.jpg'}]},
            {images: [{fileName: 'bg-myimg5.jpg'}, {fileName: 'bg-other9.jpg'}, {fileName: 'bg-other8.jpg'}]},
            {images: [{fileName: 'bg-exhi01.jpg'}, {fileName: 'bg-exhi1.jpg'}, {fileName: 'bg-exhi2.jpg'}]},
            {images: [{fileName: 'bg-other3.jpg'}, {fileName: 'bg-other6.jpg'}, {fileName: 'bg-other5.jpg'}]},
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };

    return (
        <SmallBanderole noControls={props.noControls}
                        title='Exhibitions'
                        data={moodboards.length > 0 ? shuffleArray(moodboards) : shuffleArray(fakeData.data)}
                        titleType={props.titleType}
                        className={props.className}
                        type="exhibitions"
                        containerId="moodboards-list"
                        parentAction={props.parentAction}
        />
    );
};
export default MoodboardsList;
