import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import PicturesGrid from '../../components/Shared/Pictures/PicturesGrid/PicturesGrid';
import { Redirect } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import { config } from '../../environments';
import Header from '../../components/Shared/Header/Header';

const PageSerie = ({ match }) => {
    const serieId = match?.params?.serieId;
    const [serieTitle, setSerieTitle] = useState('Default Serie title');
    const [serieImages, setSerieImages] = useState([]);

    const { sendRequest } = useHttpClient();
    const serieUrl = `${config.baseUrlDev}/serie/no-control/${serieId}`;

    useEffect(() => {
        sendRequest(serieUrl, 'GET')
            .then((s) => {
                setSerieTitle(s.title);
                setSerieImages(s.images);
            })
            .catch((e) => setSerieImages(null));
    }, [serieId]);

    return !serieId || !serieImages
        ? <Redirect to='/not-found'/>
        : (
            <PageLayout>
                <Header title={serieTitle} titleType={3} className="boxed-layout"/>
                <PicturesGrid data={serieImages}/>
            </PageLayout>
        );
};

export default PageSerie;
