import React from "react";
import "./MediumCard.css";

const MediumCard = (props) => {
  return (
    <>
      <div
        className="mediumcard"
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      ></div>
    </>
  );
};

export default MediumCard;
