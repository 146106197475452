import React, {useContext, useEffect} from 'react';
import './ProfileMenu.scss';
import {NavLink} from 'react-router-dom';
import {AuthContext} from '../../../../../context/auth-context';
import {roleEnum} from '../../../../../assets/statics/authent-signup.enum';

const ProfileMenu = (props) => {
    const {userType, user} = useContext(AuthContext);
    const auth = useContext(AuthContext);

    return (
        <div className="profile-avatar"
             style={{backgroundImage: user?.avatarFileUrl ? `url(${user?.avatarFileUrl})` : ''}}
             onClick={props.dropdownHandler}
        >
            {props.dropDown &&
            <div className="dropdown-box">
                {(userType === roleEnum.USER || userType === roleEnum.MEDIA_USER) &&
                <li className="dropdown-li">
                    <NavLink className="links" to="/purchases">
                        Purchases
                    </NavLink>
                </li>
                }
                <li className="dropdown-li">
                    <NavLink className="links" to="/profile/settings">
                        Profile Settings
                    </NavLink>
                </li>
                <li className="dropdown-li">
                    <NavLink className="links" to="/">
                        Help
                    </NavLink>
                </li>
                <li className="dropdown-li">
                    <NavLink className="dropdown-li"
                             to="/"
                             onClick={() => auth.logout()}
                    >
                        Logout
                    </NavLink>
                </li>
            </div>
            }
        </div>
    );
};
export default ProfileMenu;
