import React, {useState} from "react";
import MoreFilter from '../MoreFilter/MoreFilter';
import ExploreSearchBar from '../ExploreSearchBar/ExploreSearchBar';

const SearchExplore = ({searchValues, updateSearchValues, submitSearch}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="search-form">
            <ExploreSearchBar searchValues={searchValues}
                              updateSearchValues={updateSearchValues}
                              showMore={showMore}
                              setShowMore={setShowMore}
                              submitSearch={submitSearch}
            />
            {showMore &&
            <MoreFilter searchValues={searchValues}
                        updateSearchValues={updateSearchValues}
            />
            }
        </div>
    );
};
export default SearchExplore;
