import React, {useEffect, useState} from 'react';
import './Series.scss';
import BigBanderole from "../../Shared/Banderole/Template/BigBanderole/BigBanderole";
import {useHttpClient} from '../../../hooks/http-hook';
import {config} from '../../../environments';

const Series = (props) => {
    const {sendRequest} = useHttpClient();

    const [series, setSeries] = useState();

    const retrieveAllSeries = async () => {
        const url = `${config.baseUrlDev}/serie/filter?photographerId=${props.photographerId}`
        await sendRequest(url, 'GET')
            .then((data) => setSeries(data.slice(0, 7)))
            .catch((e) => setSeries([]));
    }

    useEffect(() => {
        if (props.photographerId) {
            retrieveAllSeries();
        }
    }, [props.photographerId]);

    const fakeData = {
        title: 'Series',
        data: [
            { title: 'Series 1', main: 'bg-about1.jpg' },
            { title: 'Series 2', main: 'bg-myimg1.jpg' },
            { title: 'Series 3', main: 'bg-myimg2.jpg' },
            { title: 'Series 4', main: 'bg-same3.jpg' },
            { title: 'Series 5', main: 'bg-myimg3.jpg' },
            { title: 'Series 6', main: 'bg-myimg4.jpg' },
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };
    return (
        <BigBanderole title={fakeData.title}
                      data={Array.isArray(series) ? (series.length > 0 ? series : shuffleArray(fakeData.data)) : []}
                      titleType={props.titleType}
                      isSerie
                      containerId="photographer-series" />
    );
};
export default Series;
