import React, {useContext, useEffect, useState} from "react";
import Banderole from "../../../Shared/Banderole/Banderole";
import Header from "../../../Shared/Header/Header";
import {useHttpClient} from '../../../../hooks/http-hook';
import {AuthContext} from '../../../../context/auth-context';
import {config} from '../../../../environments';

const Photographers = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);
    const {sendRequest} = useHttpClient();
    const {token, userIdProfile} = useContext(AuthContext);

    const [photographersIds, setPhotographersIds] = useState([]);

    const retrieveAgencyDetails = async () => {
        const url = `${config.baseUrlDev}/agency/${userIdProfile}`;
        await sendRequest(url, 'GET', null, {'Content-Type': 'application/json', cookies: token})
            .then((res) => setPhotographersIds(res.photographers))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (token && userIdProfile) {
            retrieveAgencyDetails();
        }
    }, [token])

    const parentId = "photographers-banderole";

    const defaultTitle = 'Photographers';
    const photographers = [
        { title: 'Andrea Bruce / NOOR', place: 'Lyon, France', link: '' },
        { title: 'Martha Dennis', place: 'Lyon, France', link: '' },
        { title: 'Andrea Bruce / NOOR', place: 'Lyon, France', link: '' },
        { title: 'Violet Soto', place: 'Lyon, France', link: '' },
        { title: 'Mario Stephens', place: 'Lyon, France', link: '' },
        { title: 'Martha Dennis', place: 'Lyon, France', link: '' },
        { title: 'Violet Soto', place: 'Lyon, France', link: '' },
    ];
    return (
        <section className="boxed-layout page-section" id={parentId}>
            <Header title={defaultTitle} titleType={props.titleType} parentId={parentId}
                    setControls={{setLeftNav, setRightNav}} showControls={{leftNav, rightNav}} />
            <Banderole cards={photographers} type="photographers" parentId={parentId} photographersIds={photographersIds}/>
        </section>
    )
};
export default Photographers;
