import React from 'react'
import './Innovative.css'
const Innovative = () => {
    return (
        <>
            <div className="section-innovative">
                <div className="box-innovative">
                    <div className="innovative-content">
                    The innovative photography marketplace built on trust
                    </div>
                    <h4>By Andrea Bruce / NOOR</h4>
                </div>
            </div>
        </>
    )
}

export default Innovative
