import React from 'react';
import BigBanderole from "../../Banderole/Template/BigBanderole/BigBanderole";

const MySeries = (props) => {
    const fakeData = {
        title: 'My Series',
        data: [
            {title: 'Series 1', main: 'bg-exhi3.jpg'},
            {title: 'Series 2', main: 'bg-exhi2.jpg'},
            {title: 'Series 3', main: 'bg-exhi4.jpg'},
            {title: 'Series 4', main: 'bg-exhi1.jpg'},
            {title: 'Series 5', main: 'bg-exhi5.jpg'},
            {title: 'Series 6', main: 'bg-exhi3.jpg'},
        ]
    };
    return (
        <BigBanderole title={fakeData.title} data={fakeData.data}
                      titleType={props.titleType}
                      containerId="my-series" />
    );
};
export default MySeries;
