import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import SecondaryBtn from '../../../../Shared/Buttons/Template/SecondaryBtn';
import {AiFillSave} from 'react-icons/ai';
import TertiaryBtn from '../../../../Shared/Buttons/Template/TertiaryBtn';
import {ImCancelCircle} from 'react-icons/im';
import Paper from '@material-ui/core/Paper';

const TfSlider = ({value, period, handleSave, setValue, defaultPeriod}) => {
    const handlePeriodChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleClear = () => {
        setValue([
            period.from ? period.from : defaultPeriod[0],
            period.to ? period.to : defaultPeriod[1]
        ]);
    }

    return (
        <Paper className="slider-timeframe"
             style={{
                 position: "absolute",
                 width: "100%",
                 maxWidth: "300px",
                 padding: "0.5rem 1rem",
             }}
        >
            <Typography id="discrete-slider" gutterBottom>
                Select a period
            </Typography>
            <div className="slider-container display-flex" style={{alignItems: "center"}}>
                <Slider value={value}
                        onChange={handlePeriodChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={defaultPeriod[0]}
                        max={defaultPeriod[1]}
                />
                <div className="display-flex" style={{marginLeft: "1rem"}}>
                    <SecondaryBtn className="btn-default-height"
                                  parentAction={handleSave}
                    >
                        <AiFillSave/>
                    </SecondaryBtn>
                    <TertiaryBtn style={{marginLeft: "0.25rem"}}
                                 className="btn-default-height"
                                 parentAction={handleClear}
                    >
                        <ImCancelCircle/>
                    </TertiaryBtn>
                </div>
            </div>
        </Paper>
    );
};
export default TfSlider;
