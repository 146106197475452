import React from 'react'
import ProfileSettings from '../../components/Shared/ProfileSettings/ProfileSettings'
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const ProfileSettingsPage = () => {
    return (
        <PageLayout>
            <ProfileSettings/>
        </PageLayout>
    )
}

export default ProfileSettingsPage
