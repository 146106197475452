import React, {useEffect, useState} from 'react';
import './ImageInfo.scss';
import {BsBoxArrowUpRight} from 'react-icons/bs';
import {Link} from 'react-router-dom';

const ImageInfo = ({label, value, link, agencyId, secondary, className}) => {
    const [text, setText] = useState('');

    useEffect(() => {
        if (value) {
            (typeof value === 'object')
                ? !Array.isArray(value)
                ? setText(Object.keys(value).map((k) => value[k] ? value[k] : '-').join(', '))
                : setText(value.join(', '))
                : setText(value);
        } else {
            setText('-')
        }
    }, [value]);

    return (
        <div className={`custom-label-field ${className ? className : ''} ${secondary ? 'inline-label' : ''}`}>
            {secondary
                ? <h6 className="typo-size-small">{label}</h6>
                : <h6 style={{marginBottom: "0"}}>{label}</h6>
            }
            <div className="info-credit">
                {link
                    ? <Link to={`/photographer/details?photographerId=${link}`} className="detail-info-link">
                        {text} <BsBoxArrowUpRight className="arrow-credit"/>
                    </Link>
                    : agencyId
                        ? <Link to={`/agency/details/${agencyId}`} className="detail-info-link">
                            {text} <BsBoxArrowUpRight className="arrow-credit"/>
                        </Link>
                        : <p style={{whiteSpace: "pre-line", marginBottom: "0"}}>{text}</p>
                }
            </div>
        </div>
    );
}
export default ImageInfo;
