import React, {useEffect, useState} from "react";
import "./GetPreparedLeft.scss";
import Img from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../../assets/statics/cloud-image';
import Tag from '../../../../ExplorePage/TagAll/Tag/Tag';

const GetPreparedLeft = ({image}) => {
    const [instructions, setInstructions] = useState([]);

    useEffect(() => {
        if (image?.instructions?.length > 0) {
            let arr = [];
            for (let si of image?.instructions) {
                if (si) {
                    for (let i of si.split('\n')) {
                        if (i !== '') {
                            arr.push(i);
                        }
                    }
                }
            }
            setInstructions(arr);
        }
    }, [image]);

    return (
        <div className="get-prepare-left double-item gl-col-6">
            <Img src={cloudimageConfig.baseURL + image?.fileName}/>
            <div className="tags-container">
                <h5>Image Tags</h5>
                <div className="tags">
                    {image?.keywords?.map((kw, k) => <Tag key={k}>{kw}</Tag>)}
                    {image?.keywords?.length === 0 && <Tag>No tag</Tag>}
                </div>
            </div>
            <div className="instructions">
                <h4>Special instructions</h4>
                <div className="special-instruction grey-block">
                    {instructions?.map((si, k) => (
                        <li className="instruction-tag" key={k}>{si}</li>
                    ))}
                    {instructions?.length === 0 &&
                    <li className="instruction-tag">No special instruction.</li>
                    }
                </div>
            </div>
        </div>
    );
};
export default GetPreparedLeft;
