import React, { useEffect, useState } from 'react';
import "./style.scss";

export function SharedMediaHeader ({elementValues}) {
    const defaultTitle = `My<br/>moodboard`;
    const defaultDescription = `When asked, “If you could wish for one thing only, what would that
                    wish be?” almost everyone; from beauty pagent contestants, to
                    politicians, to religious leaders, to children, to the average
                    person on the street states, “Peace On Earth” or “An end to all
                    wars”.`;

    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription)

    useEffect(() => {
        if (elementValues) {
            setTitle(elementValues.title ? elementValues.title : defaultTitle);
            setDescription(elementValues.description ? elementValues.description : defaultDescription);
        }
    },[defaultDescription, defaultTitle, elementValues]);
    
    return (
        <section className="section-header page-section boxed-layout">
            <div className="header-content">
                <header className="main-title title">
                    <h1 className="typo-size-bigger" dangerouslySetInnerHTML={{__html: title}}/>
                </header>
                <p className="description" style={{marginBottom: "2rem"}}>
                    {description}
                </p>
            </div>
        </section>
    );
}