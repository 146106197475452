import React, {useEffect, useState} from 'react';
import './Exhibitions.scss';
import SmallBanderole from "../../Shared/Banderole/Template/SmallBanderole/SmallBanderole";
import {useHttpClient} from '../../../hooks/http-hook';
import {config} from '../../../environments';

const Exhibitions = (props) => {
    const {sendRequest} = useHttpClient();

    const [moodboards, setMoodboards] = useState();
    const title = 'Exhibitions';

    const retrieveAllMoodboards = async () => {
        const url = `${config.baseUrlDev}/moodboard/filter?${props.type === 'photographer' ? 'photographerId' : 'agencyId'}=${props.itemId}`
        await sendRequest(url, 'GET')
            .then((data) => setMoodboards(data.slice(0, 7)))
            .catch((e) => setMoodboards([]));
    }

    useEffect(() => {
        if (props.itemId) {
            retrieveAllMoodboards();
        }
    }, [props.itemId]);

    const fakeData = {
        data: [
            {images: [{fileName: 'bg-exhi01.jpg'}, {fileName: 'bg-exhi1.jpg'}, {fileName: 'bg-exhi2.jpg'}]},
            {images: [{fileName: 'bg-exhi03.jpg'}, {fileName: 'bg-exhi5.jpg'}, {fileName: 'bg-popular2.jpg'}]},
            {images: [{fileName: 'bg-myimg5.jpg'}, {fileName: 'bg-other9.jpg'}, {fileName: 'bg-other8.jpg'}]},
            {images: [{fileName: 'bg-exhi02.jpg'}, {fileName: 'bg-exhi3.jpg'}, {fileName: 'bg-exhi4.jpg'}]},
            {images: [{fileName: 'bg-exploreall.jpg'}, {fileName: 'bg-explore1.jpg'}, {fileName: 'bg-other2.jpg'}]},
            {images: [{fileName: 'bg-other3.jpg'}, {fileName: 'bg-other6.jpg'}, {fileName: 'bg-other5.jpg'}]},
            {images: [{fileName: 'bg-myimg5.jpg'}, {fileName: 'bg-other9.jpg'}, {fileName: 'bg-other8.jpg'}]},
            {images: [{fileName: 'bg-other3.jpg'}, {fileName: 'bg-other6.jpg'}, {fileName: 'bg-other5.jpg'}]},
        ]
    };

    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };

    return (
        <SmallBanderole title={title}
                        data={Array.isArray(moodboards) ? (moodboards.length > 0 ? moodboards : shuffleArray(fakeData.data)) : []}
                        titleType={props.titleType}
                        type="exhibitions" containerId="photographer-exhibitions"/>
    );
};
export default Exhibitions;
