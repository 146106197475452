import React, {useEffect, useState} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {required, requiredCheckbox, requiredPhone} from '../../../Shared/Forms/validators-final-form';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';
import {useHttpClient} from '../../../../hooks/http-hook';

const CompanyForm = ({registrationBody, roles, submitStep, btnLabel, errorHandler}) => {
    const [ext, setExt] = useState([]);

    const {sendRequest} = useHttpClient();

    useEffect(() => {
        const link = 'https://raw.githubusercontent.com/euvl/country-phone-number-codes/master/codes.json';
        sendRequest(
            link,
            "GET",
            null,
            {'content-type': 'text/plain; charset=utf-8'},
        )
            .then((data) => setExt(data))
            .catch((error) => console.warn({error}));
    }, []);

    const getErrors = () => {
        try {
            const d = JSON.parse(errorHandler);
            if (d.errors) {
                return Object.keys((d.errors)).map((item, k) => `${k > 0 ? ', ' : ''}${item}`);
            }
        } catch (e) {
            return 'Error';
        }
    }

    return (
        <Form
            initialValues={registrationBody}
            onSubmit={submitStep}
            render={({handleSubmit, submitting}) => (
                <form onSubmit={handleSubmit} className="registration-form">
                    {roles !== roleEnum.USER &&
                    <>
                        <Field name="companyType" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label>Company type</label>
                                    <input {...input} type="text"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Company type"/>
                                </div>
                            )}
                        </Field>
                        <Field name="companyName" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label>Company name</label>
                                    <input {...input} type="text"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Company name"/>
                                </div>
                            )}
                        </Field>
                    </>
                    }
                    <Field name="email" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>{roles === roleEnum.PHOTOGRAPHER ? `Email` : 'Company email'}</label>
                                <input {...input} type="text"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                       placeholder="Email"/>
                            </div>
                        )}
                    </Field>
                    <Field name="phoneNumber" validate={requiredPhone}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>Telephone contact</label>
                                <input {...input} type="text"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                       placeholder="0648756212"
                                       minLength="7"
                                       maxLength="15"
                                />
                            </div>
                        )}
                    </Field>

                    <Field name="telExt" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>Ext. for phone</label>
                                <select {...input} type="select"
                                        className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                >
                                    <option/>
                                    {ext.map((e, k) => <option
                                        style={{display: "flex", justifyContent: "space-between"}}
                                        value={e.dial_code} key={k}>
                                        {e.name} ({e.dial_code})
                                    </option>)}
                                </select>
                            </div>
                        )}
                    </Field>
                    <Field name="terms" validate={requiredCheckbox} type="checkbox">
                        {({input, meta}) => (
                            <div className="field-group" style={{display: "flex", alignItems: "center"}}>
                                <input {...input} type="checkbox"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}/>
                                <label style={{margin: 0}}>&nbsp;I accept terms and conditions</label>
                            </div>
                        )}
                    </Field>
                    <div className="field-group">
                        {errorHandler &&
                        <div>
                            <div className="authent-error">[{getErrors()}] Unexpected error</div>
                        </div>
                        }
                        <FormSpy subscription={{pristine: true, invalid: true, submitting}}>
                            {props => {
                                const isInvalid = submitting || props.invalid;
                                return (
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                        style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                    >
                                        {btnLabel}
                                    </button>
                                )
                            }}
                        </FormSpy>
                    </div>
                </form>
            )}
        />
    )
};
export default CompanyForm;
