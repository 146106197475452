import React, {useContext, useState} from 'react';
import './PicturesGrid.scss';
import Header from "../../Header/Header";
import ImageOverview from "../../ImageOverview/ImageOverview";
import {ShopContext} from "../../../../context/shopContext";

import {BackgroundImg} from "react-cloudimage-responsive";
import {AuthContext} from '../../../../context/auth-context';
import PicturesGridItem from './PicturesGridItem/PicturesGridItem';

const PicturesGrid = (props) => {
    const [modal, setModal] = useState(false);
    const [modalBackground, setModalBackground] = useState(null);

    const {token} = useContext(AuthContext);
    const {addItemToCheckout, opencart} = useContext(ShopContext);

    const [currentPin, setCurrentPin] = useState('');

    const showPin = (key) => {
        (currentPin === `num${key}`)
            ? setCurrentPin('')
            : setCurrentPin(`num${key}`);
    };

    const showOverview = () => {
        setModal(true);
    };

    const handleImageOnClick = (event, picture) => {
        if (event.target.classList.contains('cloudimage-background-content')) {
            setModalBackground(picture);
            showOverview();
        }
    };

    const handleAddCart = (id, priceId) => {
        addItemToCheckout(id, priceId, token);
        opencart();
    };

    return (
        <section className="page-section boxed-layout">
            <ImageOverview
                modal={modal}
                setModal={setModal}
                backgroundImage={modalBackground}
            />
            {props.title &&
            <Header titleType={2} title={props.title}/>
            }
            {props.data.length === 0 &&
            <p style={{padding: "4em 0"}}>Oups, we didn't find what you are looking for !</p>
            }
            <div className="grid-content">
                {props.data.map((picture, k) => (
                        <PicturesGridItem key={k}
                                          k={k}
                                          showPin={showPin}
                                          currentPin={currentPin}
                                          picture={picture}
                                          handleAddCart={handleAddCart}
                                          displayMode={props.displayMode}
                                          modalBackground={modalBackground}
                                          setModalBackground={setModalBackground}
                                          handleImageOnClick={handleImageOnClick}
                        />
                    )
                )}
            </div>
        </section>
    );
};
export default PicturesGrid;
