import React from 'react';
import './PhotographerMenu.scss';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';

const PhotographerMenu = ({view, setView}) => {
    return (
        <nav className="media-reviews-menu">
            <h4>Manage Uploads</h4>
            <ul className="menu-list">
                <li className={`menu-item ${view === statusEnum.ACCEPTED && 'active'}`}
                    onClick={() => setView(statusEnum.ACCEPTED)}
                >
                    Accepted
                </li>
                <li className={`menu-item ${view === statusEnum.PENDING && 'active'}`}
                    onClick={() => setView(statusEnum.PENDING)}
                >
                    Pending
                </li>
                <li className={`menu-item ${view === statusEnum.DECLINED && 'active'}`}
                    onClick={() => setView(statusEnum.DECLINED)}
                >
                    Declined
                </li>
            </ul>
        </nav>
    )
}
export default PhotographerMenu;
