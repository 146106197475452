import React, {useEffect, useState} from "react";
import "./CertificateDownload.scss";
import ImageSection from '../ImageSection/ImageSection';
import {useHistory} from 'react-router-dom';

const CertificateDownload = ({lineItems}) => {
    const [items, setItems] = useState([]);
    const history = useHistory();

    const resetCheckoutData = () => {
        localStorage.removeItem('pixt_checkout_data');
        localStorage.removeItem('pixt_checkout_status');
        localStorage.removeItem('pixt_checkout_cart');
    }

    useEffect(() => {
        if (lineItems) {
            resetCheckoutData();
            try {
                localStorage.setItem('pixt_checkout_line_items', JSON.stringify(lineItems));
            } catch (e) {
                history.push('/');
            }
            setItems(lineItems);
        }
    }, [lineItems]);

    return (
        <section
            className={`page-section checkout-result-certificate boxed-layout ${items.length <= 1 ? 'vertical-content-centered' : ''}`}>
            {items && items.length > 0 &&
            items.map((item, k) => <ImageSection imageId={item} key={k}/>)
            }
        </section>
    );
};

export default CertificateDownload;
