import React from "react";
import "./Label.css";

const Label = (props) => {
    return (
        <label className={props.className ? props.className : "label-reg"}
               onClick={props.parentAction}
               htmlFor={props.htmlFor}
        >
            {props.children}
        </label>
    );
};

export default Label;
