import React, {useContext, useEffect, useState} from "react";
import "./RightSideEdit.scss";
import {ImQrcode} from "react-icons/im";
import Img from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import {Link} from 'react-router-dom';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';
import {useHttpClient} from '../hook/http-hook';
import {AuthContext} from '../../../../context/auth-context';
import {config} from '../../../../environments';
import PrimaryBtn from '../../../Shared/Buttons/Template/PrimaryBtn';

const RightSideEdit = ({data, image}) => {
        const {sendRequest} = useHttpClient();
        const {token} = useContext(AuthContext);
        const [bc, setBc] = useState();
        const [certLink, setCertLink] = useState();
        const [submitting, setSubmitting] = useState(false);

        const retrieveBc = async () => {
            await sendRequest(
                `${config.baseUrlDev}/images/BlockchainSignature/${image?._id}`,
                'GET',
                null,
                {'Content-Type': 'application/json', cookies: token}
            )
                .then((res) => setBc(res))
                .catch((e) => console.warn({e}));
        }

        const getCertificate = async () => {
            setSubmitting(true);
            setCertLink();
            await sendRequest(
                `${config.baseUrlDev}/images/downloadCertificate/${image?._id}`,
                'GET',
                null,
                {cookies: token}
            )
                .then((res) => {
                    setCertLink(res.pdf);
                    window.open(res.pdf);
                })
                .catch((e) => console.warn({e}))
                .finally(() => setSubmitting(false));
        }

        useEffect(() => {
            if (image?.blockchainSignature) {
                if (typeof image?.blockchainSignature === 'string') {
                    setBc(JSON.parse(image.blockchainSignature));
                } else {
                    setBc(image.blockchainSignature);
                }
            } else if (image && !image.blockchainSignature && token) {
                retrieveBc();
            }
        }, [image, token]);
    
        return (
            <>
                <div className="box-r-side">
                    <Img className="img-r-side" src={cloudimageConfig.baseURL + data.fileName}/>
                    <div className="row-certif-r-side">
                        <div className="certif-bool">
                            <ImQrcode className="qr-code invalid"/>
                            <h5>{
                                bc?.data?.signature
                                    ? 'Certificated'
                                    : 'Not certificated'
                            }</h5>
                            <div className="code-s">{bc?.data?.provable?.signature.slice(0, 18)}...</div>
                        </div>
                        <PrimaryBtn className={`btn-default-height color-white ${submitting ? 'disabled' : bc ? '' : 'disabled'}`}
                                    parentAction={() => submitting ? null : bc ? getCertificate() : null}
                        >
                            Download Certificate
                        </PrimaryBtn>
                    </div>
                    {certLink &&
                    <div className="url-box-img">
                        <h5>Certificate</h5>
                        <a href={certLink} target="_blank" rel="noreferrer nofollow noopener">{certLink}</a>
                    </div>
                    }
                    <div className="url-box-img">
                        <h5>URL</h5>
                        <Link to={`/image/details/${data._id}`}>https://p.pix.t/{data._id}</Link>
                    </div>
                    <div className="status-box-img">
                        <h5>Status</h5>
                        <p>
                            {data?.status === statusEnum.PENDING &&
                            <>Needs review and approval from your agency in order to be released</>
                            }
                            {data?.status === statusEnum.ACCEPTED &&
                            <>Your image is released</>
                            }
                            {data?.status === statusEnum.DECLINED &&
                            <>Your image has been refused</>
                            }
                        </p>
                    </div>
                </div>
            </>
        );
    }
;

export default RightSideEdit;
