import React from 'react';
import TertiaryBtn from '../../../Buttons/Template/TertiaryBtn';

const NoAvatarActions = ({hasChanged, resetProfile}) => {
    return (
        <div className="vertical-content-centered">
            <label className="button primary" htmlFor="avatar">
                Add profile image
            </label>
            {hasChanged &&
            <>
                <button className="button secondary btn-default-width"
                        type="submit"
                >
                    Save
                </button>
                <TertiaryBtn className="btn-default-width"
                             parentAction={resetProfile}
                             style={{marginTop: "0.25rem"}}
                >
                    Reset
                </TertiaryBtn>
            </>
            }
        </div>
    );
};
export default NoAvatarActions;
