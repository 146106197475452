import React from 'react';
import './AdminDemandsMenu.scss'
import {viewAdminEnum} from '../../../assets/statics/reviews-admin-step.enum';

const AdminDemandsMenu = ({view, setView}) => {
    return (
        <nav className="admin-demands-menu">
            <h4>Manage Pending Demands</h4>
            <ul className="menu-list">
                <li className={`menu-item ${view === viewAdminEnum.PHOTOGRAPHERS && 'active'}`}
                    onClick={() => setView(viewAdminEnum.PHOTOGRAPHERS)}
                >
                    Photographers
                </li>
                <li className={`menu-item ${view === viewAdminEnum.AGENCIES && 'active'}`}
                    onClick={() => setView(viewAdminEnum.AGENCIES)}
                >
                    Agencies
                </li>
                <li className={`menu-item ${view === viewAdminEnum.MEDIA_USERS && 'active'}`}
                    onClick={() => setView(viewAdminEnum.MEDIA_USERS)}
                >
                    Media users
                </li>
            </ul>
        </nav>
    )
}
export default AdminDemandsMenu;
