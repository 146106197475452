import React, {useEffect, useState} from "react";
import "./RegistrationForm.scss";

import AuthentHeader from "../AuthentHeader/AuthentHeader";
import {
    endpointEnum,
    formEnum,
    formTitle,
    roleEnum,
    stepEnum,
    subEnum,
} from "../../../assets/statics/authent-signup.enum";
import {useHttpClient} from "../../../hooks/http-hook";
import {useHistory, useLocation} from "react-router-dom";
import {FORM_ERROR} from "final-form";
import CompanyForm from "./CompanyForm/CompanyForm";
import PersonalForm from "./PersonalForm/PersonalForm";
import RoleForm from "./RoleForm/RoleForm";
import PrimaryBtn from "../../Shared/Buttons/Template/PrimaryBtn";
import { render } from "@testing-library/react";
import Toaster from "../../Shared/Toaster/Toaster";

const RegistrationForm = () => {
    const history = useHistory();
    const {error, setError, sendRequest} = useHttpClient();
    const search = useLocation().search;
    const toRedirect = new URLSearchParams(search).get("from");
    const [currentStep, setCurrentStep] = useState(stepEnum.STEP_ROLE);
    const [roles, setRoles] = useState(roleEnum.USER);
    const [formStep, setFormStep] = useState(formEnum.STEP_FIRST);
    const [formSubtitle, setFormSubtitle] = useState(subEnum.STEP_FIRST);
    const [registrationBody, setRegistrationBody] = useState({});

    const btnLabel = "Next step";
    const submitEndpoint = endpointEnum.REGISTRATION;

    useEffect(() => {
        if (formStep === formEnum.STEP_SUBMIT) {
            return onSubmit();
        }
    }, [formStep]);

    const submitStep = async (formValues) => {
        setError();
        await setRegistrationBody({...registrationBody, ...formValues});
        switch (currentStep) {
            case stepEnum.STEP_ROLE:
                const isUser = formValues.roles === roleEnum.USER;
                setRoles(formValues.roles);
                setFormSubtitle(
                    `${subEnum.STEP_PROGRESS} ${isUser ? "1 of 1" : "1 of 2"}`
                );
                setFormStep(isUser ? formEnum.STEP_END : formEnum.STEP_PROGRESS);
                setCurrentStep(stepEnum.STEP_PERSONAL);
                break;
            case stepEnum.STEP_PERSONAL:
                if (roles !== roleEnum.USER) {
                    setCurrentStep(stepEnum.STEP_COMPANY);
                    setFormSubtitle(`${subEnum.STEP_PROGRESS} 2 of 2`);
                    setFormStep(formEnum.STEP_END);
                } else {
                    setFormStep(formEnum.STEP_SUBMIT);
                    break;
                }
                break;
            case stepEnum.STEP_COMPANY:
                setFormStep(formEnum.STEP_SUBMIT);
                break;
            default:
                break;
        }
    };

    const toggleForm = () => {
        switch (currentStep) {
            case stepEnum.STEP_COMPANY:
                setCurrentStep(stepEnum.STEP_PERSONAL);
                setFormStep(formEnum.STEP_PROGRESS);
                setFormSubtitle(`${subEnum.STEP_PROGRESS} 1 of 2`);
                break;
            case stepEnum.STEP_PERSONAL:
                setCurrentStep(stepEnum.STEP_ROLE);
                setFormStep(formEnum.STEP_FIRST);
                setFormSubtitle(`${subEnum.STEP_FIRST}`);
                break;
            default:
                setCurrentStep(stepEnum.STEP_ROLE);
                setFormStep(formEnum.STEP_FIRST);
                break;
        }
    };

    const onSubmit = async () => {
        let userData = {...registrationBody};
        userData.roles = [userData.roles];
        delete userData.terms;
        return await sendRequest(submitEndpoint, "POST", JSON.stringify(userData), {
            "Content-Type": "application/json",
        })
            .then(() => {
                history.push("/validateregistration");
                render(<Toaster success toastMsg="Registered successfuly !"/>)
                setTimeout(() => {
                    return toRedirect
                        ? history.push(`/login?from=${toRedirect}`)
                        : history.push("/login");
                }, 5000);
            })
            .catch((e) => {
                setFormStep(formEnum.STEP_END);
                return {[FORM_ERROR]: <Toaster error toastMsg="Unexpected error !"/>};
            });
    };

    return (
        <>
            <div className="login-form-container">
                <AuthentHeader title={formTitle} subtitle={formSubtitle}/>
                <div>
                    {currentStep === stepEnum.STEP_ROLE && (
                        <RoleForm
                            formStep={formStep}
                            btnLabel={btnLabel}
                            submitStep={submitStep}
                            registrationBody={registrationBody}
                        />
                    )}
                    {currentStep === stepEnum.STEP_PERSONAL && (
                        <PersonalForm
                            roles={roles}
                            formStep={formStep}
                            btnLabel={btnLabel}
                            submitStep={submitStep}
                            registrationBody={registrationBody}
                            errorHandler={error}
                        />
                    )}
                    {currentStep === stepEnum.STEP_COMPANY && (
                        <CompanyForm
                            roles={roles}
                            formStep={formStep}
                            btnLabel={btnLabel}
                            submitStep={submitStep}
                            registrationBody={registrationBody}
                            errorHandler={error}
                        />
                    )}
                </div>
                {currentStep !== stepEnum.STEP_ROLE && (
                    <div className="field-group">
                        <PrimaryBtn
                            className="color-white btn-full-width"
                            parentAction={() => toggleForm()}
                            style={{
                                padding: "0.5rem 0",
                            }}
                        >
                            Previous step
                        </PrimaryBtn>
                    </div>
                )}
            </div>
        </>
    );
};
export default RegistrationForm;
