import React from 'react';
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import AuthentLayout from "../../components/AuthenticationAction/AuthentLayout/AuthentLayout";
import RegistrationForm from "../../components/AuthenticationAction/RegistrationForm/RegistrationForm";

const RegistrationPage = () => {
    return (
        <PageLayout>
            <section className="page-layout">
                <AuthentLayout title="Create<br />your<br />account">
                    <RegistrationForm />
                </AuthentLayout>
            </section>
        </PageLayout>
    )
}
export default RegistrationPage;
