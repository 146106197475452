import React from "react";

const Paragraphs = () => {
  return (
    <>
      <div className="whatis-p">
        <p>
          - Pix.T is the innovative photography marketplace built on trust: a
          secure, easy-to-use technical protocol designed in the interest of
          photographers.
        </p>
      </div>
      <div className="whatis-p">
        <p>
          - Pix.T prevents digital image theft through blockchain-backed image
          streaming players.
        </p>
      </div>
      <div className="whatis-p">
        <p>
          - Pix.T gives photographers exclusive print sales via news
          partnerships, managing digital licensing, logistics and revenue
          shares.
        </p>
      </div>
      <div className="whatis-p">
        <p>
          - Our unique blockchain solution guarantees transparency and security,
          restoring true value in top photography for our digital future.
        </p>
      </div>
    </>
  );
};

export default Paragraphs;
