import React, {useEffect, useState} from "react";
import {useEditReducer} from "../../hook/editReducer";
import "./MetadataData.scss";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import PhotographerField
    from '../../../../AgencyAction/AgencyPhotographers/SidebarPhotographer/PhotographerField/PhotographerField';
import AddMetadataForm from './AddMetadataForm/AddMetadataForm';


const MetadataData = (props) => {
    const formTitle = 'Metadata';

    const [iptc, setIptc] = useState({});
    const [exif, setExif] = useState({});
    const [state, dispatch] = useEditReducer();

    const addMetadata = (newMetadata) => {
        if (state.exifActive) {
            const currentMd = {...exif};
            currentMd[newMetadata['meta-title']] = newMetadata['meta-value'];
            setExif(currentMd);
        } else if (state.iptcActive) {
            const currentMd = {...iptc};
            currentMd[newMetadata['meta-title']] = newMetadata['meta-value'];
            setIptc(currentMd);
        }
    }

    const onSubmit = (formValues) => {
        let md = {};
        if (state.exifActive) {
            md = {exif: {...exif, ...formValues}};
        } else if (state.iptcActive) {
            md = {iptc: {...iptc, ...formValues}};
        }
        props.parentAction(md);
    }

    useEffect(() => {
        if (props.originalData?.IPTCmetadata && !Array.isArray(props.originalData?.IPTCmetadata)) {
            setIptc(props.originalData.IPTCmetadata);
        }
        if (props.originalData?.EXIFmetadata && !Array.isArray(props.originalData?.EXIFmetadata)) {
            setExif(props.originalData.EXIFmetadata);
        }
    }, [props])

    return (
        <div className="box-metadata-edit">
            <AuthentHeader title={formTitle}/>
            <div className="edit-choice">
                <h5
                    className={`${state.exifActive && "active"}`}
                    onClick={() => dispatch({type: "TO_EXIF"})}
                >
                    EXIF
                </h5>
                <h5
                    className={`${state.iptcActive && "active"}`}
                    onClick={() => dispatch({type: "TO_IPTC"})}
                >
                    IPTC
                </h5>
            </div>

            <div className="inputs-edit-up">
                <Form
                    initialValues={state.exifActive ? exif : iptc}
                    onSubmit={onSubmit}
                    render={({submitError, handleSubmit, submitting}) => {
                        const formFn = (formType) => {
                            if (Object.keys(formType).length === 0) {
                                return (
                                    <div className="field-group array-field-group">
                                        <label>No metadata.</label>
                                    </div>
                                )
                            }
                            return Object.keys(formType).map((key, k) => {
                                const elType = typeof formType[key];
                                return (elType !== 'object')
                                    ? (
                                        <Field name={key} key={k}>
                                            {({input, meta}) => (
                                                <div className="field-group">
                                                    <label htmlFor={key}><strong>{key}</strong></label>
                                                    <input {...input}
                                                           type={elType === 'number' ? elType : 'text'}
                                                           id={key}
                                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    )
                                    : <div className="field-group array-field-group">
                                        <label><strong>{key}</strong></label>
                                        <div className="array-container">
                                            {
                                                !formType[key] ? <></> : Array.isArray(formType[key])
                                                    ? formType[key].map((el) => <PhotographerField value={el}/>)
                                                    : Object.keys(formType[key]).map((el) => (
                                                        <PhotographerField label={el}
                                                                           value={formType[key][el]}
                                                                           className="display-flex space-between"
                                                        />
                                                    ))
                                            }
                                        </div>
                                    </div>
                            })
                        }
                        return (
                            <form onSubmit={handleSubmit} className="metadata-form">
                                {(state.exifActive && exif) && formFn(exif)}
                                {(state.iptcActive && iptc) && formFn(iptc)}
                                {submitError &&
                                <div>
                                    <div className="authent-error">{submitError}</div>
                                </div>
                                }
                                <div className="field-group">
                                    <FormSpy subscription={{pristine: true, invalid: true}}>
                                        {props => {
                                            const isInvalid = submitting || props.invalid;
                                            return (
                                                <button
                                                    type="submit"
                                                    disabled={isInvalid}
                                                    className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                        padding: "0.5rem 0"
                                                    }}
                                                >
                                                    Save
                                                </button>
                                            )
                                        }}
                                    </FormSpy>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>

            <hr/>
            <h4>Add new metadata</h4>
            <AddMetadataForm parentAction={addMetadata}/>
        </div>
    );
};
export default MetadataData;
