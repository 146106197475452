import React from "react";
import './AgencyParagraphs.css'

const AgencyParagraphs = ({description}) => {
    return (
        description
            ? <p className="bio-paragraph typo-size-bio" style={{whiteSpace: "pre-line"}}>{description}</p>
            : <>
                <p className="bio-paragraph typo-size-bio">
                    Italian-Togolaise photographer Silvia Rosi draws on the aesthetic of
                    West African studio portraiture to connect with her family history and
                    perform a fictional portrait of her father, a man she never met, as a
                    journey of self-discovery to understand her own history through the
                    bodies of the people who played a crucial role in shaping it. Rosi
                    uses props to retell the story of her father’s migration from his
                    hometown in Africa to Italy: the books and suit signal he is a member
                    of the educated middle class, who finds himself working humble jobs in
                    Italy, picking tomatoes, as a migrant. The matrilineal skill of head
                    carrying, traditionally used by market women to transport goods, is
                    employed as a ‘metaphor of struggle which is present in the market and
                    resonates in the act of migration’. The making of this series was
                    supported by Jerwood Arts and Photoworks.
                </p>
                <p className="bio-paragraph typo-size-bio">
                    Silvia Rosi gained a BA degree in photography from the London College
                    of Communication (UAL). Her portraits have been recognised by the
                    Jerwood/Photoworks Awards 2020, the Lens Culture Portrait Award 2020
                    and have been selected for the BJP Portrait of Britain project 2020.
                    Her work has been seen in exhibitions including the Getxo
                    International Image Festival, the Athens Photo Festival at the Benaki
                    Museum and the Landskrona Foto Competition, Sweden. Rosi’s commercial
                    clients include the Financial Times, Wallpaper and the New Yorker and
                    she has received commissions from Jerwood Arts/Photoworks, Southbank
                    Centre, Autograph ABP.
                </p>
            </>
    );
};

export default AgencyParagraphs;
