import React, {useContext, useEffect, useState} from 'react';
import './AgencyList.scss';
import AgencyCard from '../AgencyCard/AgencyCard';
import {useHttpClient} from '../../../../../hooks/http-hook';
import {AuthContext} from "../../../../../context/auth-context";
import {config} from '../../../../../environments';

const AgencyList = ({style}) => {
    const [joined, setJoined] = useState([]);
    const {sendRequest} = useHttpClient();
    const {userIdProfile, token} = useContext(AuthContext);
    const submitEndpoint = `${config.baseUrlDev}/agency/${userIdProfile}/all`;

    const retrieveAgencies = async () => {
        return await sendRequest(submitEndpoint, "GET", null, {"Content-Type": "application/json", cookies: token})
            .then((data) => setJoined(
                data.map((agency) => {
                    return {name: agency.companyName, id: agency._id}
                }))
            )
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        retrieveAgencies();
    }, []);

    return (
        <section className="photographer-agency-joined" style={style}>
            <h4>My agencies</h4>
            <ul className="agency-list">
                {joined.map((agency, k) => <AgencyCard key={k} id={agency.id} name={agency.name}/>)}
            </ul>
        </section>
    )
}
export default AgencyList;
