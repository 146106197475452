import React from 'react';
import './MenuReviews.scss';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';

const MenuReviews = ({view, setView}) => {
    return (
        <nav className="photo-reviews-menu">
            <h4>Manage Uploads - Reviews</h4>
            <ul className="menu-list">
                <li className={`menu-item ${view === statusEnum.ACCEPTED && 'active'}`}
                    onClick={() => setView(statusEnum.ACCEPTED)}
                >
                    Our images
                </li>
                <li className={`menu-item ${view === statusEnum.PENDING && 'active'}`}
                    onClick={() => setView(statusEnum.PENDING)}
                >
                    Pending reviews
                </li>
                <li className={`menu-item ${view === statusEnum.DECLINED && 'active'}`}
                    onClick={() => setView(statusEnum.DECLINED)}
                >
                    Declined
                </li>
            </ul>
        </nav>
    )
}
export default MenuReviews;
