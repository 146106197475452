import React from 'react'
import PhotogEditUp from '../../components/PhotographerAction/PhotogEditUp/PhotogEditUp'
import PhotoEditProvider from "../../components/PhotographerAction/PhotogEditUp/context/EditContext";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const PhotogEditUploaded = ({match}) => {
    const imageId = match?.params?.imageId;
    return (
        <PhotoEditProvider>
            <PageLayout>
                <PhotogEditUp imageId={imageId}/>
            </PageLayout>
        </PhotoEditProvider>
    )
}

export default PhotogEditUploaded
