import React from 'react';
import PhotographersImages from '../../components/AgencyAction/AgencyDashboard/PhotographersImages/PhotographersImages';
import PhotographersSeries from '../../components/AgencyAction/AgencyDashboard/PhotographersSeries/PhotographersSeries';
import Statistics from '../../components/AgencyAction/AgencyDashboard/Statistics/Statistics';
import AgencyDashboardExhibitions
    from "../../components/AgencyAction/AgencyDashboard/AgencyDashboardExhibitions/AgencyDashboardExhibitions";
import Photographers from "../../components/AgencyAction/AgencyDashboard/Photographers/Photographers";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const AgencyDashboard = () => {

    return (
        <PageLayout>
            <Statistics/>
            <PhotographersImages titleType={3}/>
            <PhotographersSeries titleType={3}/>
            <AgencyDashboardExhibitions titleType={3}/>
            <Photographers titleType={3}/>
        </PageLayout>
    )
}

export default AgencyDashboard
