import React, {useEffect, useState} from 'react';
import './BoxNextPrev.scss';
import {IoIosArrowRoundBack as LeftArrow, IoIosArrowRoundForward as RightArrow} from 'react-icons/io';

const BoxNextPrev = ({parentId, setControls, showControls}) => {
    const [container, setContainer] = useState();
    const [banderole, setBanderole] = useState();
    const [itemWidth, setItemWidth] = useState(0);
    const [gapWidth, setGapWidth] = useState(0);
    const [position, setPosition] = useState(0);

    const [clicked, setClicked] = useState(false);

    const showRight = banderole?.offsetWidth >= container?.offsetWidth;

    useEffect(() => {
        setControls.setRightNav(showRight);
    }, [showRight]);

    useEffect(() => {
        setContainer(document.querySelector(`#${parentId} .banderole-container`));
        setBanderole(document.querySelector(`#${parentId} .banderole-container .banderole-items`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (banderole) {
            const items = banderole.querySelectorAll('.box-banderole-card');
            if (container && banderole.offsetWidth > container.offsetWidth) {
                setItemWidth(items[0].offsetWidth);
                setGapWidth((banderole.offsetWidth - (items[0].offsetWidth * items.length)) / (items.length - 1))
            }
        }
    }, [banderole, container]);

    useEffect(() => {
        if (banderole) {
            banderole.style.transform = `translateX(${position}px)`;
            (banderole.getBoundingClientRect().left < container.getBoundingClientRect().left)
                ? setControls.setLeftNav(true)
                : setControls.setLeftNav(false);
            (banderole.getBoundingClientRect().right > container.getBoundingClientRect().right)
                ? setControls.setRightNav(true)
                : setControls.setRightNav(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);

    const handleLeftClick = () => {
        setPosition(position + itemWidth + gapWidth);
    };

    const handleRightClick = () => {
        if (itemWidth === 0 && gapWidth === 0 && showRight) {
            setClicked(true);
            if (banderole && container && banderole?.offsetWidth > container?.offsetWidth) {
                const items = banderole.querySelectorAll('.box-banderole-card');
                setItemWidth(items[0]?.offsetWidth);
                setGapWidth((banderole?.offsetWidth - (items[0]?.offsetWidth * items.length)) / (items.length - 1))
            }
        } else {
            setPosition(position - itemWidth - gapWidth);
        }
    };

    useEffect(() => {
        if (clicked) {
            setClicked(false);
            setPosition(position - itemWidth - gapWidth);
        }
    }, [itemWidth]);

    return (
        <div className="banderole-nav">
            <div className={`banderole-nav-item left ${showControls?.leftNav ? 'enable' : 'disabled'}`}
            >
                <LeftArrow/>
                <div id="banderole-prev" className="nav-overlay"
                     onClick={showControls?.leftNav ? handleLeftClick : null}/>
            </div>
            <div className={`banderole-nav-item right ${showControls?.rightNav && showRight ? 'enable' : 'disabled'}`}>
                <RightArrow/>
                <div id="banderole-next" className="nav-overlay"
                     onClick={showControls?.rightNav && showRight ? handleRightClick : null}/>
            </div>
        </div>
    )
};
export default BoxNextPrev;
