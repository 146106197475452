/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';

import "./MoodboardEdit.scss";

import {useHttpClient} from '../../../../hooks/http-hook';
import {AuthContext} from '../../../../context/auth-context';

import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../Shared/Forms/validators-final-form';

import MoodboardHeader from '../../../../components/Shared/Moodboard/MoodboardPublic/MoodboardHeader';
import MoodboardCredits
    from '../../../../components/Shared/Moodboard/MoodboardPublic/MoodboardCredits/MoodboardCredits';

import {roleEnum} from '../../../../assets/statics/authent-signup.enum';
import {config} from '../../../../environments';
import {usePhotographerAgencies} from '../../../../hooks/photographer-agencies-hook';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';
import {useHistory} from 'react-router-dom';
import FormActions from '../FormActions/FormActions';
import ImagesManagement from '../ImagesManagement/ImagesManagement';

const urlEndpoint = {
    CREATE: 'add',
    UPDATE: 'update',
}

const urlMethod = {
    CREATE: 'POST',
    UPDATE: 'PATCH',
}

export const MoodBoardForm = (props) => {
    const [initialValues, setInitialValues] = useState();
    const [moodboardId, setMoodboardId] = useState('');
    const createUpdatedUrl = `${config.baseUrlDev}/moodboard`;
    const retrieveMediaUrl = `${config.baseUrlDev}/images/filter`;

    const {token, userIdProfile, userType} = useContext(AuthContext);
    const {isLoading, sendRequest} = useHttpClient();
    const [keywords, setKeywords] = useState([])
    const [moodboardAgencyId, setMooodboardAgencyId] = useState(null);
    const [photographerAgencyImages, setPhotographerAgencyImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const {photographerAgencies} = usePhotographerAgencies(userIdProfile);

    const history = useHistory();

    const onSubmit = async (formValues) => {
        formValues.keywords = keywords;
        formValues.images = selectedImages;
        await sendRequest(
            `${createUpdatedUrl}/${initialValues?._id ? urlEndpoint.UPDATE + '/' + moodboardId : urlEndpoint.CREATE}`,
            `${initialValues?._id ? urlMethod.UPDATE : urlMethod.CREATE}`,
            JSON.stringify(formValues),
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then((data) => history.push(`/moodboard/edit/${data._id}`))
            .catch((e) => console.warn(e));
    }

    const updateTagsList = (tags) => {
        setKeywords(tags);
    }

    const handleOnSelectAgency = (event) => {
        const value = event.target.value;
        setMooodboardAgencyId(value);
        setSelectedImages([]);
    }

    const toggleImage = (imageId) => {
        if (selectedImages.includes(imageId)) {
            const i = selectedImages.indexOf(imageId);
            const sI = [...selectedImages];
            sI.splice(i, 1);
            setSelectedImages(sI);
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (props.initialValues) {
            setInitialValues(props.initialValues);
            setMooodboardAgencyId(props.initialValues?.agencyId);
            setMoodboardId(props?.initialValues?._id)
            setKeywords(props.initialValues?.keywords);
            setSelectedImages(props.initialValues?.images);
        }
    }, [props]);

    useEffect(async () => {
        if (moodboardAgencyId) {
            await sendRequest(
                `${retrieveMediaUrl}?agencyId=${moodboardAgencyId}&photographerId=${userIdProfile}&status=${statusEnum.ACCEPTED}`,
                'GET'
            )
                .then((data) => setPhotographerAgencyImages(data.map((img) => img._id)))
                .catch((e) => console.warn({e}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moodboardAgencyId, userIdProfile])

    return (
        <>
            {userType === roleEnum.AGENCY && <MoodboardHeader moodboard={props.initialValues}/>}
            {userType === roleEnum.PHOTOGRAPHER &&
            <section className="box-cont-head page-section boxed-layout">
                <Form onSubmit={onSubmit}
                      initialValues={initialValues}
                      render={({submitError, handleSubmit, submitting}) => (
                          <form onSubmit={handleSubmit} className="moodboard-edit-form">
                              <div className="form-container">
                                  <Field name="agencyId" validate={required}>
                                      {({input, meta}) => (
                                          <div className="field-group">
                                              <label htmlFor="agency">Agency</label>
                                              <select {...input}
                                                      type="select"
                                                      id="agency"
                                                      onChangeCapture={handleOnSelectAgency}
                                                      className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                              >
                                                  <option/>
                                                  {photographerAgencies.map((a, k) => <option value={a._id}
                                                                                              key={k}>{a.companyName}</option>)}
                                              </select>
                                          </div>
                                      )}
                                  </Field>
                                  <Field name="title" validate={required}>
                                      {({input, meta}) => (
                                          <div className="field-group">
                                              <label htmlFor="moodboard-title">Title</label>
                                              <input {...input} type="text" id="moodboard-title"
                                                     className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                              />
                                          </div>
                                      )}
                                  </Field>
                                  <Field name="description">
                                      {({input}) => (
                                          <div className="field-group">
                                              <label htmlFor="moodboard-description">Description</label>
                                              <textarea {...input} type="text" id="moodboard-description"
                                                        className="input-reg"
                                              />
                                          </div>
                                      )}
                                  </Field>
                                  <Field name="isPrivate" type="checkbox">
                                      {({input}) => (
                                          <div className="field-group keep-private">
                                              <p style={{margin: "0"}}>Visibility</p>
                                              <input {...input} className="input-reg" type="checkbox"
                                                     id="moodboard-visibility"/>
                                              <label htmlFor="moodboard-visibility">Keep this moodboard
                                                  private</label>
                                          </div>
                                      )}
                                  </Field>
                              </div>
                              <div className="field-group">
                                  <FormSpy subscription={{invalid: true}}>
                                      {props => {
                                          const isInvalid = submitting || props.invalid;
                                          return (
                                              <button
                                                  type="submit"
                                                  disabled={isInvalid || isLoading}
                                                  className={`button primary ${(isInvalid || isLoading) ? 'disabled' : ''}`}
                                                  style={{textAlign: "center", padding: "0.5rem 2rem"}}
                                              >
                                                  Save
                                              </button>
                                          )
                                      }}
                                  </FormSpy>
                              </div>
                              {submitError &&
                              <div>
                                  <div className="authent-error">{submitError}</div>
                              </div>
                              }
                          </form>
                      )}
                />
                {moodboardId &&
                <MoodboardCredits photographerId={initialValues?.photographerId}
                                  moodboardId={moodboardId}
                                  style={{marginTop: "2rem"}}
                />
                }
                <FormActions keywords={keywords}
                             isSelectedAgency={moodboardAgencyId && true}
                             setIsOpen={setIsOpen}
                             updateTagsList={updateTagsList}
                />
            </section>
            }
            <ImagesManagement photographerAgencyImages={photographerAgencyImages}
                              toggleImage={toggleImage}
                              selectedImages={selectedImages}
                              isOpen={isOpen}
                              handleClose={handleClose}
            />
        </>
    )
}
