import React, {useContext, useEffect, useState} from "react";
import "./AddToMoodboard.scss";
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import {AuthContext} from '../../../../context/auth-context';
import ViewSelection from './ViewSelection/ViewSelection';
import ViewAdded from './ViewAdded/ViewAdded';
import ViewCreate from './ViewCreate/ViewCreate';
import ViewError from './ViewError/ViewError';

const addToMbViewEnum = {
    SELECT: 'select_view',
    ADDED: 'added_view ',
    CREATE: 'create_view',
    ERROR: 'view_error',
}

const AddToMoodboard = (props) => {
        const {agencyId, k, picture} = props;

        const [moodboards, setMoodboards] = useState([]);

        const {token, userIdProfile} = useContext(AuthContext);
        const {sendRequest} = useHttpClient();

        const [currentView, setCurrentView] = useState(addToMbViewEnum.SELECT);
        const [selectedMb, setSelectedMb] = useState();
        const [newTitle, setNewTitle] = useState('');

        const handleTitleChange = (e) => {
            setNewTitle(e.target.value);
        }

        const saveMoodboard = async (mb) => {
            const updateUrl = `${config.baseUrlDev}/moodboard/update`;
            await sendRequest(`${updateUrl}/${mb._id}`,
                'PATCH',
                JSON.stringify({images: mb.images}),
                {'cookies': token, 'Content-Type': 'application/json'}
            )
                .then(() => {
                    setSelectedMb(mb);
                    setCurrentView(addToMbViewEnum.ADDED);
                })
                .catch((e) => setCurrentView(addToMbViewEnum.ERROR));
        }

        const createMoodboard = async () => {
            const createUrl = `${config.baseUrlDev}/moodboard/add`;
            await sendRequest(createUrl,
                'POST',
                JSON.stringify({title: newTitle.trim(), agencyId}),
                {'cookies': token, 'Content-Type': 'application/json'}
            )
                .then((createdMb) => {
                    handleSelectMood(createdMb);
                    saveMoodboard(createdMb);
                    retrieveMoodboards();
                })
                .catch((e) => setCurrentView(addToMbViewEnum.ERROR));
        }

        const handleSelectMood = (mb) => {
            if (!mb.images.includes(picture._id)) {
                mb.images.push(picture._id);
                saveMoodboard(mb);
            }
        };

        const retrieveMoodboards = async () => {
            const url = `${config.baseUrlDev}/moodboard/filter?photographerId=${userIdProfile}&agencyId=${agencyId}`;
            await sendRequest(url, 'GET')
                .then((res) => {
                    const mbs = res.map((mb) => {
                        mb.images = mb.images.map((img) => img._id)
                        return mb;
                    });
                    setMoodboards(mbs)
                })
                .catch((e) => setCurrentView(addToMbViewEnum.ERROR));
        }

        useEffect(() => {
            if (agencyId) {
                retrieveMoodboards();
            }
        }, [agencyId]);

        useEffect(() => {
            if (currentView === addToMbViewEnum.ADDED) {
                setTimeout(() => setCurrentView(addToMbViewEnum.SELECT), 4000);
            }
        }, [currentView]);

        return (
            <div className={`addtomood num${k}`}>
                {currentView === addToMbViewEnum.SELECT &&
                <ViewSelection moodboards={moodboards}
                               setCurrentView={setCurrentView}
                               addToMbViewEnum={addToMbViewEnum}
                               handleSelectMood={handleSelectMood}
                />
                }
                {currentView === addToMbViewEnum.ADDED &&
                <ViewAdded picture={picture} selectedMb={selectedMb}/>
                }
                {currentView === addToMbViewEnum.CREATE &&
                <ViewCreate newTitle={newTitle}
                            setCurrentView={setCurrentView}
                            addToMbViewEnum={addToMbViewEnum}
                            createMoodboard={createMoodboard}
                            handleTitleChange={handleTitleChange}
                />
                }
                {currentView === addToMbViewEnum.ERROR &&
                <ViewError setCurrentView={setCurrentView} addToMbViewEnum={addToMbViewEnum}/>
                }
            </div>
        );
    }
;
export default AddToMoodboard;
