import React from 'react';
import './AgencyCard.scss';
import {Link} from 'react-router-dom';
import {BsBoxArrowUpRight} from 'react-icons/bs';

const AgencyCard = ({id, name}) => {
    return (
        <li className="agency-card">
            Agency
            <div className="agency-data">
                <h5>{name}</h5>
                <Link to={`/agency/details/${id}`}>
                    <BsBoxArrowUpRight className="redirect-icon"/>
                </Link>
            </div>
        </li>
    );
}
export default AgencyCard;
