import React from 'react';
import './AuthentLayout.scss';
import Illustration from '../Illustration/Illustration';

const AuthentLayout = (props) => {
    return (
        <div className="authent-layout">
            <Illustration title={props.title}/>
            <div className="authent-form-container">
                <div className="authent-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
export default AuthentLayout;
