import React, {useContext, useEffect, useState} from 'react';
import Header from "../../Shared/Header/Header";
import Banderole from "../../Shared/Banderole/Banderole";
import {config} from '../../../environments';
import {useHttpClient} from '../../../hooks/http-hook';
import {AuthContext} from '../../../context/auth-context';

const AdminAgencies = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);
    const {sendRequest} = useHttpClient();
    const {token} = useContext(AuthContext);

    const parentId = "admin-agencies-banderole";
    const [agencies, setAgencies] = useState([]);

    const retrieveAllAgencies = async () => {
        const url = `${config.baseUrlDev}/agency/all`;
        await sendRequest(url, 'GET', null, {'Content-Type': 'application/json', cookies: token})
            .then((res) => setAgencies(res))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (token) {
            retrieveAllAgencies()
        }
    }, [token]);

    const defaultTitle = 'Latest Agencies';

    return (
        <section className="boxed-layout page-section" id={parentId}>
            <Header title={defaultTitle} titleType={props.titleType} parentId={parentId}
                    setControls={{setLeftNav, setRightNav}} showControls={{leftNav, rightNav}}/>
            <Banderole agencies={agencies} type="agencies" parentId={parentId}/>
        </section>
    )
}
export default AdminAgencies;
