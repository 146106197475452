import React from "react";
import Checkout from "../../components/CheckoutAction/Checkout/Checkout";

const CheckOut = () => {
  return (
    <>
      <Checkout />
    </>
  );
};

export default CheckOut;
