import React from "react";
import "./CreditsData.scss";
import AuthentHeader from "../../../../AuthenticationAction/AuthentHeader/AuthentHeader";
import { Field, Form, FormSpy } from "react-final-form";
import { required } from "../../../../Shared/Forms/validators-final-form";

const CreditsData = (props) => {
  const formTitle = "Credits";

  const onSubmit = (formValues) => {
    props.parentAction(formValues);
  };

  return (
    <div className="box-credits-edit">
      <AuthentHeader title={formTitle} />
      <Form
        initialValues={props.originalData}
        onSubmit={onSubmit}
        render={({ submitError, handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="login-form">
            <Field name="fullName" validate={required}>
              {({ input, meta }) => (
                <div className="field-group">
                  <label htmlFor="fullName">Credits</label>
                  <input
                    {...input}
                    type="text"
                    id="fullName"
                    className={`input-reg ${
                      meta.error && meta.touched ? "in-error" : ""
                    }`}
                    placeholder="Grayson"
                  />
                </div>
              )}
            </Field>
            {submitError && (
              <div>
                <div className="authent-error">{submitError}</div>
              </div>
            )}
            <div className="field-group">
              <FormSpy subscription={{ pristine: true, invalid: true }}>
                {(props) => {
                  const isInvalid = submitting || props.invalid;
                  return (
                    <button
                      type="submit"
                      disabled={isInvalid}
                      className={`button secondary ${
                        isInvalid ? "disabled" : ""
                      }`}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "0.5rem 0",
                      }}
                    >
                      Save
                    </button>
                  );
                }}
              </FormSpy>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default CreditsData;
