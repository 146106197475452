import React from 'react';
import TertiaryBtn from '../../../Buttons/Template/TertiaryBtn';
import {ImCancelCircle} from 'react-icons/im';
import SecondaryBtn from '../../../Buttons/Template/SecondaryBtn';
import {CgArrowsExchange} from 'react-icons/cg';
import {AiFillSave} from 'react-icons/ai';

const AvatarActions = ({hasChanged, handleAvatarRemove, resetProfile}) => {
    return (
        <div className="btn-action">
            {hasChanged &&
            <TertiaryBtn className="btn-default-height"
                         parentAction={resetProfile}
            >
                Reset
            </TertiaryBtn>
            }
            <TertiaryBtn className="btn-default-height"
                         parentAction={() => handleAvatarRemove()}
            >
                <ImCancelCircle/>
            </TertiaryBtn>
            <SecondaryBtn className="btn-default-height btn-change-avatar">
                <label htmlFor="avatar">
                    <CgArrowsExchange/>
                </label>
            </SecondaryBtn>
            {hasChanged &&
            <button className="button primary btn-default-height"
                    type="submit"
            >
                <AiFillSave/>
            </button>
            }
        </div>
    )
};
export default AvatarActions;
