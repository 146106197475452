import React, { useEffect } from "react";
import "./ValidateRegistration.scss";
import { GrValidate } from "react-icons/gr";
import Aos from "aos";
import "aos/dist/aos.css";

const ValidateRegistration = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="page-section boxed-layout" data-aos="zoom-in-up">
        <div className="box-account-create">
          <h2 style={{fontSize: "70px"}}>Account created</h2>
          <GrValidate className="icon-validate" />
          <h4 className="tertiary-color text-center" style={{fontSize: "2.5rem", maxWidth: "65%"}}>
            Thank you! We'll take a moment to review your application and get back to you shortly.
            <br/>Be sure to check your email for the confirmation.
          </h4>
        </div>
      </div>
    </>
  );
};

export default ValidateRegistration;
