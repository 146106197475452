import React, {useContext, useEffect, useState} from "react";
import './Cart.scss';
import {ShopContext} from "../../../../../context/shopContext";
import {Col, Container, Icon, Modal, Row, Text,} from "atomize";
import {BackgroundImg} from 'react-cloudimage-responsive';
import SecondaryBtn from "../../../Buttons/Template/SecondaryBtn";
import {useHttpClient} from '../../../../../hooks/http-hook';
import {config} from '../../../../../environments';
import {AuthContext} from '../../../../../context/auth-context';
import {cloudimageConfig} from '../../../../../assets/statics/cloud-image';
import {Link, useHistory} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js";
import {checkoutStatusEnum} from '../../../../../assets/statics/checkout-status.enum';

const Cart = () => {
    const {isCartopen, closecart, getStripeCart, setStripeCart} = useContext(ShopContext);
    const {sendRequest} = useHttpClient();
    const {token, customerId} = useContext(AuthContext);
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);

    const stripePromise = loadStripe(
        config.stripeSecret
    );

    const [cartItems, setCartItems] = useState([]);

    const getRequest = async (url, callback, errorCallback) => {
        return await sendRequest(
            url,
            'GET',
            null,
            {cookies: token}
        )
            .then((data) => callback(data))
            .catch(() => errorCallback ? errorCallback() : null);
    }

    const createCheckout = async () => {
        setSubmitting(true);
        const link = `${config.baseUrlDev}/stripe/checkout/add`;
        const cartToSend = [...getStripeCart].map((item) => {
            item.price = {...item}.priceId;
            item.quantity = 1;
            delete item.id;
            delete item.priceId;
            return item;
        });
        if (cartToSend) {
            const body = {
                lineItems: cartToSend,
            };
            if (customerId) {
                body.customer = customerId;
            }
            await sendRequest(
                link,
                'POST',
                JSON.stringify(body),
                {'Content-Type': 'application/json'}
            )
                .then(async (stripeData) => stripeCheckout(stripeData))
                .catch((error) => {
                    console.warn({error});
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
        }
    }

    const stripeCheckout = async (data) => {
        const stripe = await stripePromise;
        localStorage.setItem('pixt_checkout_data', JSON.stringify(data));
        localStorage.setItem('pixt_checkout_status', checkoutStatusEnum.CREATED);
        stripe.redirectToCheckout({sessionId: data.id})
    }

    useEffect(() => {
        if (!token) {
            closecart();
            const from = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
            return history.push(`/login?from=${from}`);
        }

        const link = `${config.baseUrlDev}/images`;
        if (getStripeCart && getStripeCart.length > 0) {
            const temp = [];
            getStripeCart.map(async (i, k) => {
                if (i.id && i.priceId !== 'randomPriceId') {
                    return await getRequest(
                        `${link}/${i.id}`,
                        async (img) => {
                            const photographerEndpoint = `${config.baseUrlDev}/photographer/${img.data.photographer}`;
                            await getRequest(
                                photographerEndpoint,
                                (p) => {
                                    temp.push({
                                        ...img.data,
                                        quantity: 1,
                                        author: `${img?.data?.fullName ? img?.data?.fullName : (p?.userId?.firstName + ' ' + p?.userId?.lastName)}`,
                                        authorId: p._id,
                                    });
                                    if (k === getStripeCart.length - 1) {
                                        setCartItems(temp)
                                    }
                                }
                            );
                        },
                        () => {
                            const tmp = getStripeCart;
                            tmp.splice(k, 1);
                            setStripeCart(tmp);
                        }
                    )
                }
            })
        }
    }, [getStripeCart]);

    if (cartItems) {
        return (
            <div className="cart-container">
                <div className="boxed-layout cart-content">
                    <Modal isOpen={isCartopen} onClose={closecart} id="cart-modal" p={".5rem"}>
                        <Container d="flex" flexDir="column">
                            <Row justify="flex-end">
                                <Icon id="close-cart-icon" name="Cross" color="black500"
                                      onClick={() => closecart()} style={{cursor: "pointer"}}/>
                            </Row>
                            <Row flexDir="column">
                                {cartItems.length < 1 ? (
                                    <Row p={"1rem 0"}>
                                        <Col>
                                            <Text
                                                tag="h5"
                                                textColor="black500"
                                                textSize="paragraph"
                                                hoverTextColor="black700"
                                                transition="0.3s"
                                            >
                                                Cart Is Empty
                                            </Text>
                                        </Col>
                                    </Row>
                                ) : (
                                    cartItems && cartItems.map((item, k) => (
                                        <Row key={k} p={".25rem 0"}>
                                            <Col>
                                                <Link to={`/image/details/${item._id}`} onClick={closecart}>
                                                    <BackgroundImg
                                                        src={cloudimageConfig.baseURL + item.fileName}
                                                        style={{
                                                            height: "84px",
                                                            width: "133px",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "50%"
                                                        }}
                                                    />
                                                </Link>
                                            </Col>
                                            <Col d="flex" flexDir="column" justify="center">
                                                <Text textWeight="800">{item.title}</Text>
                                                <Link to={`/photographer/details?photographerId=${item.authorId}`}
                                                      onClick={closecart}>
                                                    <Text textSize=".75rem">By {item.author}</Text>
                                                </Link>
                                            </Col>
                                            <Col d="flex" flexDir={"column"} justify={"center"} textSize="1.25rem"
                                                 style={{textAlign: 'right'}}>
                                                <Text>&#36; {item.price}</Text>
                                            </Col>
                                        </Row>
                                    ))
                                )}
                            </Row>
                            <Row p={"1rem 0"}>
                                <SecondaryBtn
                                    parentAction={(getStripeCart?.length > 0 && !submitting) ? createCheckout : null}
                                    className={`btn-full-width ${submitting ? 'disabled' : ''}`}
                                    style={{fontSize: "1.25rem"}}>
                                    Checkout
                                </SecondaryBtn>
                            </Row>
                        </Container>
                    </Modal>
                </div>
            </div>
        );
    }
    return null;
};

export default Cart;
