import React, {useContext} from 'react';
import './Moodboard.scss';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import {FiPlus} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../../../context/auth-context';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';

const Moodboard = () => {
    const {userType} = useContext(AuthContext);

    return (
        <section className="page-section boxed-layout moodboard-list-section">
            <header className="mb-list-header">
                <h1>My<br/>Moodboards</h1>
                {userType === roleEnum.PHOTOGRAPHER &&
                <Link to="/moodboard/add">
                    <TertiaryBtn className="create-btn" style={{height: "100%"}}>
                        <FiPlus className="plus-add"/>
                        Create a new moodboard
                    </TertiaryBtn>
                </Link>
                }
            </header>
        </section>
    );
};
export default Moodboard;
