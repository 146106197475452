import React, {useEffect} from 'react';
import './SidebarModal.scss';
import {AiOutlineClose} from 'react-icons/ai';
import {BackgroundImg} from 'react-cloudimage-responsive';
import CardPhotography from '../Cards/CardPhotography/CardPhotography';
import CardReqPhotog from '../Cards/CardReqPhotog/CardReqPhotog';

const SidebarModal = (props) => {
    return (
        <section className={`sidebar-modal ${props.className ? props.className : ''}`}>
            <div className="left-panel">
                {props.leftPanel &&
                <div className="review-content">
                    {props.image &&
                    <CardPhotography author={props.author}
                                     image={props.image}
                                     item={props.image}
                                     className="review-image"
                                     parentAction={props.parentAction}
                                     imageW="600"
                    />
                    }
                    {props.agency &&
                    <CardReqPhotog item={props.agency}
                                   type="reviewing"
                                   contentType={props.type}
                                   request
                                   requestData={props.photographer}
                                   parentAction={props.parentAction}
                    />
                    }
                    {(props.photographer && !props.agency) &&
                    <CardReqPhotog item={props.photographer}
                                   type="reviewing"
                                   contentType={props.type}
                                   request
                                   parentAction={props.parentAction}
                                   status={props.requestData?.status}
                                   requestData={props.requestData}
                    />
                    }
                </div>
                }
            </div>
            <div className="sidebar-container">
                <div className="sidebar-content">
                    <AiOutlineClose className="sidebar-close-icon" onClick={() => props.setModal(false)}/>
                    {props.children}
                </div>
            </div>
        </section>
    )
}
export default SidebarModal;
