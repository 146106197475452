import {config} from '../../environments';

export const formTitle = 'Glad to see you again';
export const formEnum = {
    CONNECTION: 'login',
    RESET: 'forgot_password',
};
export const subEnum = {
    CONNECTION: 'Connection',
    RESET: 'Reset password',
}
export const btnEnum = {
    CONNECTION: 'Sign in',
    RESET: 'Send me a mail',
}
export const endpointEnum = {
    CONNECTION: `${config.baseUrlDev}/auth/login`,
    RESET: `${config.baseUrlDev}/credentials/resetpassword`,
}
