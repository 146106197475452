import {useState} from 'react';
import jwt from 'jwt-decode';
import {roleEnum} from '../assets/statics/authent-signup.enum';
import {statusEnum} from '../assets/statics/reviews-step.enum';

export default function useToken() {
    const removeToken = () => {
        localStorage.removeItem('access_token');
    };

    const getToken = () => {
        const thisToken = localStorage.getItem('access_token');

        if (thisToken) {
            try {
                const {exp} = jwt(thisToken);
                if (new Date(exp * 1000) - new Date() <= 0) {
                    removeToken();
                    return undefined;
                }
            } catch (e) {
                removeToken();
                return undefined;
            }
        }

        return thisToken;
    };

    const getRole = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return undefined;
        }
        const {roles} = jwt(accessToken);
        return (roles.length === 0) ? roleEnum.USER : roles[0];
    }

    const getStatus = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return undefined;
        }
        const {status} = jwt(accessToken);
        return (!status) ? statusEnum.PENDING : status;
    }

    const getUserId = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return undefined;
        }
        const {_id} = jwt(accessToken);
        return _id;
    }

    const getIdProfile = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return undefined;
        }
        const {idProfile} = jwt(accessToken);
        return idProfile;
    }

    const getCustomerId = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return undefined;
        }
        const {customerId} = jwt(accessToken);
        return customerId;
    }

    const getRemainingTime = () => {
        const accessToken = getToken();
        if (!accessToken) {
            return 0;
        }
        const {exp} = jwt(accessToken);
        // return new Date(exp * 1000) - new Date();
        return 3600000;
    }

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        if (userToken) {
            localStorage.setItem('access_token', userToken);
            setToken(userToken);
        }
    };

    return {
        setToken: saveToken,
        token,
        status: getStatus(),
        tokenId: getUserId(),
        tokenRole: getRole(),
        tokenIdProfile: getIdProfile(),
        tokenCustomerId: getCustomerId(),
        tokenRemainingTime: getRemainingTime(),
        tokenClear: removeToken,
    }
}
