import React from "react";
import "./CardSerie.css";

const CardSerie = (props) => {
  return (
    <>
      <div
        className="cardseries"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin,
        }}
      >
        <h4 style={{color: props.color}}>{props.serie}</h4>
      </div>
    </>
  );
};

export default CardSerie;
