import React from 'react';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import AuthentLayout from '../../components/AuthenticationAction/AuthentLayout/AuthentLayout';
import LoginForm from '../../components/AuthenticationAction/LoginForm/LoginForm';

const Login = () => {
    return (
        <PageLayout>
            <AuthentLayout title="Sign in">
                <LoginForm />
            </AuthentLayout>
        </PageLayout>
    )
}
export default Login;
