import React from 'react';
import {NavLink} from 'react-router-dom';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import Name from '../../name';

const PhotographerLinks = (props) => {
    return (
        <>
            <li>
                <NavLink className="links" to="/photographer/dashboard">
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/moodboard/list">
                    Moodboards
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/story/list">
                    Stories
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/upload">
                    Upload
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/photographer/reviews">
                    Requests
                </NavLink>
            </li>
            <li>
                <div className="links user">
                    <span onClick={props.dropdownHandler}>
                        <Name placeholder="Photographer"/>
                    </span>
                    <ProfileMenu dropDown={props.dropDown} dropdownHandler={props.dropdownHandler}/>
                </div>
            </li>
        </>
    )
};
export default PhotographerLinks;
