import React from 'react';
import './Tag.scss';
import {AiFillCloseCircle} from 'react-icons/ai';

const Tag = ({style, className, children, removeAction}) => {
    return (
        <div style={style ? style : {}} className={`btn-tag typo-size-small ${className ? className : ''}`}>
            {children}
            {removeAction &&
                <>&nbsp;<AiFillCloseCircle onClick={removeAction}/></>
            }
        </div>
    )
}

export default Tag
