import React, {useEffect, useState} from "react";
import "./ExploreAll.scss";
import SearchExplore from "./SearchExplore/SearchExplore";
import TagAll from './TagAll/TagAll';
import {useHistory, useLocation} from 'react-router-dom';
import {config} from '../../environments';
import {useHttpClient} from '../../hooks/http-hook';
import {findLicenceLabel} from '../../assets/statics/search-params.enum';

const defaultSearchValues = {
    colors: [],
    layout: undefined,
    licences: [],
    period: {
        from: undefined,
        to: undefined,
    },
    terms: [],
};

function getPrefix(str, noInterrogation) {
    return str === '' ? !noInterrogation ? '?' : '' : '&';
}

const createQueries = (params, label) => {
    if (typeof params === 'string') {
        return `${label}=${params}`;
    } else if (Array.isArray(params) && params.length > 0) {
        return `${label}=${params[0]}${(params.slice(1, params.length).map((t) => `&${label}=${t}`).join(''))}`;
    } else if (typeof params === 'object') {
        let query = '';
        Object.keys(params).map((key) => params[key] ? query += `${query === '' ? '' : '&'}${key}=${params[key]}` : null)
        return query;
    }
    return '';
}

const ExploreAll = ({setSearchResults}) => {
    const search = useLocation().search;

    // default terms from URL
    const [terms] = useState(new URLSearchParams(search).getAll('terms'));
    const [urlLayout] = useState(new URLSearchParams(search).get('layout'));
    const [urlLicences, setUrlLicences] = useState(new URLSearchParams(search).getAll('licences'));

    const [urlPeriod] = useState({
        from: new URLSearchParams(search).get('from'),
        to: new URLSearchParams(search).get('to'),
    });

    // params for search request
    const [searchValues, setSearchValues] = useState(defaultSearchValues);

    const {sendRequest} = useHttpClient();
    const searchBaseUrl = `${config.baseUrlDev}/search/filter`;

    const history = useHistory();

    // update params for search request
    const updateSearchValues = (newValues) => {
        setSearchValues({...searchValues, ...newValues});
    }

    const prepareQueries = async (isDefault) => {
        let params = '';
        let keywords = '';
        let layout = '';
        let licences = '';
        let period = '';
        if (isDefault) {
            if (terms && terms.length > 0) {
                params = createQueries(terms, 'terms');
            }
            if (urlLicences && urlLicences.length > 0) {
                params += `${getPrefix(params, true)}${createQueries(urlLicences, 'licences')}`
            }
            if (urlLayout !== null) {
                params += `${getPrefix(params, true)}${createQueries(urlLayout, 'layout')}`
            }
            if (urlPeriod) {
                params += `${getPrefix(params, true)}${createQueries(urlPeriod)}`
            }
        } else {
            if (searchValues.terms.length > 0) {
                keywords = createQueries(searchValues.terms, 'terms');
            }
            if (searchValues.layout) {
                layout = createQueries(searchValues.layout, 'layout');
            }
            if (searchValues.licences.length > 0) {
                licences = createQueries(searchValues.licences, 'licences');
            }
            if (searchValues.period.from || searchValues.period.to) {
                period = createQueries(searchValues.period);
            }
        }
        searchRequest({params, keywords, layout, licences, period}, isDefault);
    }

    const searchRequest = async (queries, isDefault) => {
        let fQ = '';
        if (isDefault) {
            fQ = `?${queries.params}`;
        } else {
            Object.keys(queries).map((param) => {
                if (queries[param]) {
                    fQ += `${getPrefix(fQ)}${queries[param]}`;
                }
            });
        }
        const url = `${searchBaseUrl}${fQ}`;
        await sendRequest(url, 'GET')
            .then((data) => setSearchResults(data))
            .catch((error) => console.warn({error}));
        history.push(`/explore${fQ}`);
    }

    useEffect(() => {
        let toUpdate = {};
        if (terms.length > 0) {
            toUpdate.terms = terms;
        }
        if (urlLicences.length > 0) {
            const list = [];
            urlLicences.map((l) => {
                if (findLicenceLabel(l)) {
                    list.push(l);
                }
            });
            toUpdate.licences = list;
        }
        if (urlPeriod.from || urlPeriod.to) {
            toUpdate.period = {from: +urlPeriod.from, to: +urlPeriod.to};
        }
        if (urlLayout !== null) {
            toUpdate.layout = urlLayout;
        }
        updateSearchValues(toUpdate);
        prepareQueries(true);
    }, [terms, urlPeriod, urlLicences, urlLayout]);

    return (
        <section className="section-explore page-section boxed-layout">
            <header>
                <h1>Explore<br/>the world</h1>
            </header>
            <SearchExplore searchValues={searchValues}
                           updateSearchValues={updateSearchValues}
                           submitSearch={prepareQueries}/>
            <TagAll/>
        </section>
    );
};
export default ExploreAll;
