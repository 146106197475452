import React, {useContext, useEffect, useState} from "react";
import "./PhotographersImages.css";
import SmallBanderole from "../../../Shared/Banderole/Template/SmallBanderole/SmallBanderole";
import {useHttpClient} from '../../../../hooks/http-hook';
import {AuthContext} from '../../../../context/auth-context';
import {config} from '../../../../environments';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';

const PhotographersImages = (props) => {
    const {sendRequest} = useHttpClient();
    const {token, userIdProfile} = useContext(AuthContext);

    const [images, setImages] = useState([]);
    const title = 'Photographers Images';

    const retrieveAllImages = async () => {
        const url = `${config.baseUrlDev}/images/filter?status=${statusEnum.ACCEPTED}&agencyId=${userIdProfile}&priceId=false`
        await sendRequest(
            url,
            'GET',
            null,
            {cookies: token}
        )
            .then((data) => setImages(shuffleArray(data).slice(0, 7)))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (userIdProfile && token) {
            retrieveAllImages();
        }
    }, [userIdProfile]);

    const fakeData = {
        data: [
            { main: 'bg-same3.jpg' },
            { main: 'bg-myimg1.jpg' },
            { main: 'bg-myimg3.jpg' },
            { main: 'bg-about1.jpg' },
            { main: 'bg-myimg2.jpg' },
            { main: 'bg-myimg5.jpg' },
            { main: 'bg-myimg4.jpg' },
        ]
    };

    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };

    return (
        <SmallBanderole title={title} data={images.length > 0 ? images : shuffleArray(fakeData.data)}
                        titleType={props.titleType}
                        type="images" containerId="photographers-images" />
    );
};

export default PhotographersImages;
