import React from 'react';
import './NotFound.scss';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {errorCodeEnum, errorMessageEnum, errorTitleEnum} from '../../assets/statics/error-code.enum';

const NotFound = ({error}) => {
    return (
        <PageLayout>
            <section className="page-section boxed-layout not-found-section">
                <header className="section-header">
                    <h1>
                        {!error || error === errorCodeEnum.NOT_FOUND
                            ? errorTitleEnum.NOT_FOUND
                            : errorTitleEnum.NOT_AUTHORIZED
                        }
                    </h1>
                    <p>[{`${error ? error : 404}`}]</p>
                </header>
                <h4>
                    {!error || error === errorCodeEnum.NOT_FOUND
                        ? errorMessageEnum.NOT_FOUND
                        : errorMessageEnum.NOT_AUTHORIZED
                    }
                </h4>
                {error === errorCodeEnum.NOT_AUTHORIZED &&
                <p style={{marginTop: "1rem"}}>
                    Are you sure to be signed in?
                    <br/>Your account may not be validated yet?
                </p>
                }
            </section>
        </PageLayout>
    )
}
export default NotFound;
