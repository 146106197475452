import React, {useContext, useEffect, useState} from "react";
import "./ImageSection.scss";
import Header from "../../../Shared/Header/Header";
import PrimaryBtn from "../../../Shared/Buttons/Template/PrimaryBtn";
import {ImQrcode} from "react-icons/im";
import SecondaryBtn from "../../../Shared/Buttons/Template/SecondaryBtn";
import Img from "react-cloudimage-responsive";
import {cloudimageConfig} from "../../../../assets/statics/cloud-image";
import {Link} from "react-router-dom";
import {config} from "../../../../environments";
import {useHttpClient} from "../../../../hooks/http-hook";
import {AuthContext} from "../../../../context/auth-context";

const ImageSection = ({imageId}) => {
    const [image, setImage] = useState();
    const [submitting, setSubmitting] = useState(false);

    const {sendRequest} = useHttpClient();
    const {token} = useContext(AuthContext);

    const baseUrl = `${config.baseUrlDev}/images`;
    const imageUrl = `${window.location.protocol}//${window.location.hostname}/image/details/${image?._id}`;

    const retrieveImageData = async () => {
        await sendRequest(`${baseUrl}/${imageId}`, "GET", null, {cookies: token})
            .then((resData) => setImage(resData.data))
            .catch((e) => console.warn({e}));
    };

    useEffect(() => {
        if (imageId) {
            retrieveImageData();
        }
    }, [imageId]);

    const downloadCertificate = async () => {
        setSubmitting(true);
        window.open(`/pdf/${image?._id}`);
    };

    return (
        <div className="image-section-certificate" style={{display: "flex"}}>
            <div
                className="download-container vertical-content-centered"
                style={{width: "50%", paddingRight: "5rem"}}
            >
                <Header
                    titleType={3}
                    title="Image now<br/>available"
                    className="no-line"
                />
                <p style={{fontSize: "30px"}}>
                    You can now use this image or the Pix.T blockchain certificate when
                    you want. Your image is also available in your Pix.T account under
                    Purchases.
                </p>
                <PrimaryBtn className="btn-full-width">
                    Download image in *.tdb format
                </PrimaryBtn>
                <div className="blockchain-details">
                    <ImQrcode
                        className="here-we"
                        style={{width: "75px", height: "75px"}}
                    />
                    <div
                        style={{display: "flex", flexDirection: "column", width: "100%"}}
                    >
                        <span className="tertiary-color">Blockchain certificate</span>
                        jkaenifke,fkznfekjnemkgrnmfjk
                    </div>
                    <SecondaryBtn
                        style={{minWidth: "200px"}}
                        className={submitting ? "disabled" : ""}
                        parentAction={downloadCertificate}
                    >
                        Download certificate
                    </SecondaryBtn>
                </div>
            </div>
            <div
                className="image-preview vertical-content-centered"
                style={{width: "50%", paddingLeft: "3rem"}}
            >
                <Img src={cloudimageConfig.baseURL + image?.fileName}/>
                <div style={{marginTop: "1rem"}}>
                    <h5 className="tertiary-color">URL</h5>
                    <Link to={`/image/details/${image?._id}`}>{imageUrl}</Link>
                </div>
                <div style={{marginTop: "1rem"}}>
                    <h5 className="tertiary-color">Status</h5>
                    <span className="tag released" style={{marginLeft: "0"}}>
            Released
          </span>
                </div>
            </div>
        </div>
    );
};
export default ImageSection;
