import React from 'react';
import {NavLink} from 'react-router-dom';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import Name from '../../name';

const AgencyLinks = (props) => {
    return (
        <>
            <li>
                <NavLink className="links" to="/agency/dashboard">
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/moodboard/list">
                    Moodboards
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/story/list">
                    Stories
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/agency/photographers">
                    Photographers
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/agency/reviews">
                    Reviews
                </NavLink>
            </li>
            <li>
                <div className="links user">
                    <span onClick={props.dropdownHandler}>
                        <Name placeholder="Agency"/>
                    </span>
                    <ProfileMenu dropDown={props.dropDown} dropdownHandler={props.dropdownHandler}/>
                </div>
            </li>
        </>
    )
};
export default AgencyLinks;
