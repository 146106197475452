import React, { useEffect, useState } from 'react';
import './SameSeries.css';
import SmallBanderole from '../../../Shared/Banderole/Template/SmallBanderole/SmallBanderole';
import { useHttpClient } from '../../../../hooks/http-hook';
import { config } from '../../../../environments';

const SameSeries = ({ titleType, imageId }) => {
    const { sendRequest } = useHttpClient();

    const [serieImages, setSerieImages] = useState([]);
    const serieUrl = `${config.baseUrlDev}/serie/filter?imageId=${imageId}`;

    useEffect(() => {
        if (imageId) {
            sendRequest(serieUrl, 'GET')
                .then((s) => {
                    if (s.length > 0) {
                        setSerieImages(s[0].images);
                    }
                })
                .catch((e) => console.warn({ e }));
        }
    }, [imageId]);

    const fakeData = {
        title: 'Same series',
        data: [
            { main: 'bg-same1.jpg' },
            { main: 'bg-same2.jpg' },
            { main: 'bg-same3.jpg' },
            { main: 'bg-same4.jpg' },
            { main: 'bg-exhi7.jpg' },
            { main: 'bg-exhi8.jpg' },
            { main: 'bg-same1.jpg' },
            { main: 'bg-same2.jpg' },
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };
    return (
        <SmallBanderole title={fakeData.title}
                        data={serieImages.length === 0 ? fakeData.data : shuffleArray(serieImages.slice(0, 7))}
                        titleType={titleType}
                        type='images' containerId='same-series'/>
    );
};
export default SameSeries;
