import React, {useEffect, useRef, useState} from 'react';
import './AvatarSetting.scss';
import {BackgroundImg} from 'react-cloudimage-responsive';
import AvatarActions from './AvatarActions/AvatarActions';
import NoAvatarActions from './NoAvatarActions/NoAvatarActions';

const AvatarSetting = ({user, parentAction}) => {
    const [currentAvatar, setCurrentAvatar] = useState();
    const [avatarFormFile, setAvatarFormFile] = useState();
    const [hasChanged, setHasChanged] = useState();
    const avatarFormRef = useRef();

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAvatarFormFile(file);
            avatarFormRef.current.style.backgroundImage = `url(${URL.createObjectURL(file)})`;
        }
        setHasChanged(true);
    }

    const handleAvatarRemove = () => {
        avatarFormRef.current.reset();
        setAvatarFormFile();
        setCurrentAvatar();
        avatarFormRef.current.style.backgroundImage = '';
        setHasChanged(true);
    }

    const handleAvatarSave = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const cb = () => {
            setHasChanged(false);
            resetProfile();
        }
        const errorCb = (error) => {
            setHasChanged(false);
            resetProfile();
        }
        parentAction(data, cb, 'avatar', errorCb);
    }

    const resetProfile = () => {
        handleAvatarRemove();
        setCurrentAvatar(user?.avatarFileUrl);
        setHasChanged(false);
    }

    useEffect(() => {
        setCurrentAvatar(user?.avatarFileUrl);
    }, [user])

    useEffect(() => {
        avatarFormRef.current.style.backgroundImage = `url(${currentAvatar})`;
    }, [currentAvatar]);

    return (
        <form className="avatar-setting"
              ref={avatarFormRef}
              onSubmit={handleAvatarSave}
              style={{
                  textAlign: "center",
              }}
        >
            <div className="field-group">
                <input type="file" name="avatar" id="avatar" hidden
                       onChange={handleAvatarChange}
                />
                {(!avatarFormFile && !currentAvatar)
                    ? <NoAvatarActions hasChanged={hasChanged}
                                       resetProfile={resetProfile}
                    />
                    : <AvatarActions hasChanged={hasChanged}
                                     handleAvatarRemove={handleAvatarRemove}
                                     resetProfile={resetProfile}
                    />
                }
            </div>
        </form>
    );
};
export default AvatarSetting;
