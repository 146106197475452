import React, { useEffect, useState } from "react";
import StoryCredits from "./StoryCredits";
import "./StoryDetails.scss";

const StoryDetails = ({ story }) => {
  const defaultTitle = `My<br />story`;
  const defaultDesc = `When asked, “If you could wish for one thing only, what would that
                    wish be?” almost everyone; from beauty pagent contestants, to
                    politicians, to religious leaders, to children, to the average
                    person on the street states, “Peace On Earth” or “An end to all
                    wars”.`;

  const [title, setTitle] = useState(defaultTitle);
  const [desc, setDesc] = useState(defaultDesc);

  useEffect(() => {
    if (story) {
      setTitle(story.title ? story.title : defaultTitle);
      setDesc(story.description ? story.description : defaultDesc);
    }
  });

  return (
    <section className="section-story-header page-section boxed-layout">
      <div className="story-hedaer-content">
        <header className="main-title moodboard-title">
          <h1
            className="typo-size-bigger"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </header>
        <p className="story-description" style={{ marginBottom: "2rem" }}>
          {desc}
        </p>
        <StoryCredits
          photographerId={story?.photographerId}
          storyId={story?._id}
        />
      </div>
    </section>
  );
};

export default StoryDetails;
