import React, { useEffect, useState } from 'react';
import './GetPreparedAll.scss';
import GetPreparedLeft from './GetPrepareLeft/GetPreparedLeft';
import GetPreparedRight from './GetPrepareRight/GetPreparedRight';
import { config } from '../../../../environments';
import { useHttpClient } from '../../../../hooks/http-hook';
import GetPrepareHeader from './GetPrepareHeader/GetPrepareHeader';
import PrimaryBtn from '../../../Shared/Buttons/Template/PrimaryBtn';
import { FaThumbtack } from 'react-icons/fa';

const GetPreparedAll = ({ image }) => {
    const { sendRequest } = useHttpClient();

    const [photographer, setPhotographer] = useState();
    const photographerEndpoint = `${config.baseUrlDev}/photographer/no-control`;

    const retrievePhotographerData = async (photographerId) => {
        return await sendRequest(
            `${photographerEndpoint}/${photographerId}`, 'GET'
        )
            .then((res) => setPhotographer(res))
            .catch((error) => console.warn({ error }));
    };

    useEffect(() => {
        if (image) {
            retrievePhotographerData(image.photographer);
        }
    }, [image]);

    return (
        <>
            <div className='page-section section-pagedetails boxed-layout gl-col'>
                <GetPrepareHeader image={image} photographer={photographer}/>
                <div className='btn-top-img gl-col-6' style={{ margin: '0 auto' }}>
                    <div className='tag tertiary typo-size-small'
                         style={{
                             color: 'blue',
                             background: 'none',
                             border: 'solid 1px blue',
                             width: 'min-content'
                         }}
                    >
                        {image?.status === 'accepted' ? 'Released' : 'Pending'}
                    </div>
                    <PrimaryBtn>
                        <FaThumbtack/>
                    </PrimaryBtn>
                </div>
                <div className='gl-col-6' style={{ margin: '0 auto' }}/>
                <div className='details-double gl-col'>
                    <GetPreparedLeft image={image}/>
                    <GetPreparedRight image={image}
                                      photographer={photographer}
                    />
                </div>
            </div>
        </>
    );
};

export default GetPreparedAll;
