/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router';
import SmallBanderole from '../../../components/Shared/Banderole/Template/SmallBanderole/SmallBanderole';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout'
import { Series } from '../../../components/Shared/Series/SeriesList/Series'
import { AuthContext } from '../../../context/auth-context';
import { useSeries } from '../../../hooks/series/useSeries';



export const SeriesList = () => {
    const history = useHistory();
    const {userIdProfile, userType} = useContext(AuthContext);
    const parentAction = (seriesId) => history.push(`/series/edit/${seriesId}`);
    const {filteredSeries, filterSeries} = useSeries();

    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };

    useEffect(async () => {
        if(userIdProfile) {
            await filterSeries(userType, userIdProfile);
        }
    }, [userIdProfile, userType])

    return(
        <PageLayout>
            <Series/>
            <SmallBanderole 
                noControls
                title='Exhibitions'
                data={shuffleArray(filteredSeries)}
                containerId="home-exhibitions"
                type="exhibitions"
                className={"moodboards-list"}
                parentAction={parentAction}
            />
        </PageLayout>
    )
}