import React from "react";
import "./AboutTitle.css";

const AboutTitle = (props) => {
  return (
    <>
    {!props.reverse ? (
        <>
            <div className="container-about-title">
        <div className="about-title1">
          <div className="box-about-title">
            <div className="box-about-top">
              <h3>Title</h3>
              <div className="line-about-title"></div>
            </div>
            <div className="box-about-bottom">
              <div className="box-about-bottom-p">
                <p>
                  We imagine a future where photographs, illustrations,
                  infographics, video content and more will be both secure and
                  flexible, open and profitable, widely seen and always
                  traceable.
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="about-title2"  style={props.bg1 ? {backgroundImage: `url(${props.bg1})`}: null}></div>
      </div>
        </>
    ) : (
        <>
          <div className="container-about-title">
        <div className="about-title2" style={props.bg2 ? {backgroundImage: `url(${props.bg2})`}: null}></div>
        <div className="about-title1">
          <div className="box-about-title">
            <div className="box-about-top">
              <h3>Title</h3>
              <div className="line-about-title"></div>
            </div>
            <div className="box-about-bottom">
              <div className="box-about-bottom-p">
                <p>
                  We imagine a future where photographs, illustrations,
                  infographics, video content and more will be both secure and
                  flexible, open and profitable, widely seen and always
                  traceable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )}
      
    </>
  );
};

export default AboutTitle;
