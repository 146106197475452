import React from 'react';
import ContainerDirection from '../Containers/ContainerRow/ContainerDirection';
import {AiOutlineClose} from 'react-icons/ai';
import './ImageOverview.css';
import Img from "react-cloudimage-responsive";
import {cloudimageConfig} from './../../../assets/statics/cloud-image';

const ImageOverview = (props) => {
    return (
        props.modal && (
            <ContainerDirection width="100%" height="100vh" position="fixed" bg="rgb(0,0,0,0.8)" top="0" left="0"
                                align="center" justify="center" zIndex="990">
                <div className="close-overview" onClick={() => props.setModal(!props.modal)}>
                    <AiOutlineClose className="close-ov-icon"/>
                    Close
                </div>
                <Img className="overview-image-content" src={cloudimageConfig.baseURL + props.backgroundImage}
                     alt=""/>
            </ContainerDirection>
        )
    )
};
export default ImageOverview;
