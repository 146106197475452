import {isPreprodEnv, isProdEnv, isStagingEnv} from './hooks/useCurrentEnv';

const urls = {
    local: 'http://127.0.0.1:3050',
    staging: 'https://api.pixt.sandbox.42cloud.io',
    preprod: 'https://api.pixt.preprod.42cloud.io',
    prod: 'https://api.pixt.co',
};

export const config = {
    baseUrlDev: isProdEnv() ? urls.prod : isPreprodEnv() ? urls.preprod : isStagingEnv() ? urls.staging : urls.local,
    stripeSecret: 'pk_test_51IfSLVCOnb61bWWlMBOpUZheKQo38NhOyNEoBVHeD0mKE8PzcIAAfIiu699MVnsuZYt0ZCg7pw6Cw8nGBy8bc9tz00OG8vnLBN',
    GOOGLE_MAP_API_K: 'AIzaSyD1ZGtFgMC-_MuwxH4ZX8eb_qECZareDv8'
}
