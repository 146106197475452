import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { roleEnum } from '../../assets/statics/authent-signup.enum';
import { AuthContext } from '../../context/auth-context';
import { config } from '../../environments';
import { useHttpClient } from '../http-hook';

const CREATE_SERIES_URL = `${config.baseUrlDev}/serie/add`;
const UPDATE_SERIES_URL = `${config.baseUrlDev}/serie/update`;
const FETCH_SERIES_BY_ID_URL = `${config.baseUrlDev}/serie`;
const BASE_FILTER_SERIES_URL = `${config.baseUrlDev}/serie/filter`;

export const useSeries = () => {

    const {isLoading, sendRequest} = useHttpClient();
    const { token } = useContext(AuthContext);
    const history = useHistory();

    const [foundSeries, setFoundSeries] = useState(null);
    const [filteredSeries, setSetFilteredSeries] = useState([]);
    const [filteredSeriesByImage, setfilteredSeriesByImage] = useState([]);

    const createSeries = async (seriesValues, redirectToListSeries = true) => {
        await sendRequest(
            `${CREATE_SERIES_URL}`,
            'POST',
            JSON.stringify(seriesValues),
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then(() => {
                if(redirectToListSeries) {
                    history.push(`/series/list`)
                }
            });
    }

    const updateSeries = async (seriesId, seriesValues, redirectToListSeries = true) => {
        await sendRequest(
            `${UPDATE_SERIES_URL}/${seriesId}`,
            'PUT',
            JSON.stringify(seriesValues),
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then(() => {
                if(redirectToListSeries) {
                    history.push(`/series/list`)
                }
            });
    }

    const getSeriesById = async (seriesId) => {
        await sendRequest(
            `${FETCH_SERIES_BY_ID_URL}/${seriesId}`,
            'GET',
            null,
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then((data) => {
                setFoundSeries(data);
            });
    }

    const filterSeries = async (role, userIdProfile, imageId) => {
        const query = generateFilterMoodboardRoleQuery(role, userIdProfile);
        if(query) {
            await sendRequest(
                `${BASE_FILTER_SERIES_URL}?${query}&imageId=${imageId ? imageId : ''}`,
                'GET',
                null,
                {'Content-Type': 'application/json', 'cookies': token}
            ).then((data) => {
                if (imageId) {
                    setfilteredSeriesByImage(data);
                } else {
                    setSetFilteredSeries(data);
                }
            });
        }
    }

    const filterSeriesByPhotographerIdAndAgencyId = async (photographerId, agencyId) => {
        await sendRequest(
            `${BASE_FILTER_SERIES_URL}?photographerId=${photographerId}&agencyId=${agencyId}`,
            'GET',
            null,
            {'Content-Type': 'application/json', 'cookies': token}
        ).then((data) => {
            setSetFilteredSeries(data);
        });
    }

    const generateFilterMoodboardRoleQuery = (role, userIdProfile) => {
        let query = '';
        if (role === roleEnum.PHOTOGRAPHER) {
            query = `photographerId=${userIdProfile}`;
        }

        if (role === roleEnum.AGENCY) {
            query = `agencyId=${userIdProfile}`;
        }

        return query;
    }

    return {
        createSeries,
        updateSeries,
        foundSeries,
        isLoading,
        getSeriesById,
        filteredSeries,
        filterSeries,
        filterSeriesByPhotographerIdAndAgencyId,
        filteredSeriesByImage,
    }
} 