import React from 'react';
import {NavLink} from 'react-router-dom';
import PrimaryBtn from '../../../../Buttons/Template/PrimaryBtn';

const DefaultLinks = () => {
    const getFrom = () => {
        return encodeURIComponent(`${window.location.pathname}${window.location.search}`);
    }

    return (
        <>
            <li>
                <NavLink className="links" to="/explore">
                    Explore
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/login">
                    Sign in
                </NavLink>
            </li>
            <li>
                <NavLink to={`/register?from=${getFrom()}`}>
                    <PrimaryBtn className="color-white btn-default-height">
                        Register
                    </PrimaryBtn>
                </NavLink>
            </li>
        </>
    )
};
export default DefaultLinks;
