import React, {useEffect} from 'react';
import './NoImagesGrid.scss';
import CardReqPhotog from '../Cards/CardReqPhotog/CardReqPhotog';
import CardPhotography from '../Cards/CardPhotography/CardPhotography';
import {reviewTypeEnum} from '../../../assets/statics/review-type.enum';

const NoImagesGrid = (props) => {
    const isEmpty = (!props.data || props.data.length === 0);

    useEffect(() => {
        if (props.retrieveData) {
            props.retrieveData();
        }
    }, []);

    return (
        <section className={`no-image-grid ${props.className ? props.className : ''}`} style={props.style}>
            <div className={!isEmpty ? `grids-content` : 'center-content'}>
                {isEmpty &&
                <div className="nodisplay">No data to display</div>
                }
                {props.mode === reviewTypeEnum.AGENCY_PHOTOGRAPHERS && props.data.map((item, k) => (
                    <CardReqPhotog key={k}
                                   photographerId={item.photographer}
                                   requestData={item}
                                   parentAction={props.parentAction}
                                   status={props.status}
                                   request
                                   onClickDetails={props.onClickDetails}
                                   backgroundImage={item.profileImage}
                    />
                ))}
                {props.mode === reviewTypeEnum.ADMIN_MEDIA && props.data.map((item, k) => (
                    <CardReqPhotog key={k}
                                   item={item}
                                   parentAction={props.parentAction}
                                   status={props.status}
                                   request
                                   onClickDetails={props.onClickDetails}
                                   backgroundImage={item.profileImage}
                    />
                ))}
                {(props.mode === reviewTypeEnum.ADMIN_PHOTOGRAPHERS
                    || props.mode === reviewTypeEnum.AGENCIES) && props.data.map((item, k) => (
                    <CardReqPhotog key={k}
                                   item={item}
                                   parentAction={props.parentAction}
                                   status={props.status}
                                   request
                                   onClickDetails={props.onClickDetails}
                                   backgroundImage={item.profileImage}
                    />
                ))}
                {props.mode === 'photos' && props.data.map((item, k) => (
                    <CardPhotography key={k}
                                     item={item}
                                     parentAction={props.parentAction}
                                     status={props.status}
                                     request
                                     image={item.fileName}
                                     onClickDetails={props.onClickDetails}
                    />
                ))}
            </div>
        </section>
    );
}
export default NoImagesGrid;
