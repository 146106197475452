import React, {useState} from "react";
import Banderole from "../../Shared/Banderole/Banderole";
import Header from "../../Shared/Header/Header";

const AgencyPhotographers = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);

    const parentId = props.parentId ? props.parentId : 'agency-photographers-banderole';

    const defaultTitle = props.title ? props.title : 'Photographers';
    const photographers = [
        {title: 'Andrea Bruce / NOOR', place: 'Lyon, France', link: ''},
        {title: 'Violet Soto', place: 'Lyon, France', link: ''},
        {title: 'Mario Stephens', place: 'Lyon, France', link: ''},
        {title: 'Martha Dennis', place: 'Lyon, France', link: ''},
        {title: 'Violet Soto', place: 'Lyon, France', link: ''},
        {title: 'Andrea Bruce / NOOR', place: 'Lyon, France', link: ''},
        {title: 'Martha Dennis', place: 'Lyon, France', link: ''},
    ];
    return (
        <section className="boxed-layout page-section" id={parentId}>
            <Header title={defaultTitle} titleType={props.titleType} parentId={parentId}
                    setControls={{setLeftNav, setRightNav}} showControls={{leftNav, rightNav}}/>
            <Banderole cards={photographers} type="photographers" parentId={parentId}
                       photographersIds={props.photographersIds}/>
        </section>
    )
};

export default AgencyPhotographers;
