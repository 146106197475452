import { useState } from 'react';
import { statusEnum } from '../../assets/statics/reviews-step.enum';
import { config } from '../../environments';
import { useHttpClient } from '../http-hook';

const FILTER_IMAGE_URL = `${config.baseUrlDev}/images/filter`;
const GET_IMAGE_BY_ID_URL = `${config.baseUrlDev}/images`;

export const useImages = () => {
    const { sendRequest, isLoading } = useHttpClient();

    const [filteredImages, setFilteredImages] = useState([]);
    const [foundImageById, setFoundImageById] = useState(null);


    const filterAcceptedImages = async (userIdProfile, ageencyId) => {
        await sendRequest(
            `${FILTER_IMAGE_URL}?agencyId=${ageencyId}&photographerId=${userIdProfile}&status=${statusEnum.ACCEPTED}`,
            'GET'
        ).then((data) => setFilteredImages(data.map((img) => img._id)))
    }

    const getImageById = async (imageId) => {
        await sendRequest(
            `${GET_IMAGE_BY_ID_URL}/${imageId}`,
            'GET'
        ).then((data) => setFoundImageById(data))
    }

    return {filterAcceptedImages, filteredImages, isLoading, getImageById, foundImageById };

}