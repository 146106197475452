import React from "react";
import "./Titlend.scss";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../../Shared/Forms/validators-final-form';

const TitlenDesc = (props) => {
    const formTitle = 'Edit';

    const onSubmit = (formValues) => {
        formValues.title = formValues?.title.slice(0, 12);
        if (formValues.title.length < 6) {
            const missing = 6 - formValues.title.length;
            for (let i = 0; i < missing; i++) {
                formValues.title += '-';
            }
        }
        if (formValues.price === "0" || !(+formValues.price)) {
            formValues.price = "10";
        }
        props.parentAction(formValues);
    }

    return (
        <div className="box-titlend">
            <AuthentHeader title={formTitle}/>
            <Form initialValues={props.originalData}
                  onSubmit={onSubmit}
                  render={({submitError, handleSubmit, submitting}) => (
                      <form onSubmit={handleSubmit} className="login-form">
                          <Field name="title" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="title">Title</label>
                                      <input {...input} type="text"
                                             id="title"
                                             className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                             placeholder="user@pixt.com"
                                             minLength="7"
                                      />
                                  </div>
                              )}
                          </Field>
                          <Field name="price" type="number" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="price">Price</label>
                                      <input {...input} type="number"
                                             id="price"
                                             className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                             placeholder="10"
                                      />
                                      {!props.originalData?.price &&
                                      <em style={{color: "red"}}>Price is required to sale picture</em>
                                      }
                                  </div>
                              )}
                          </Field>
                          <Field name="description" type="textarea">
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="description">Description</label>
                                      <textarea {...input}
                                                id="description"
                                                className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                      />
                                  </div>
                              )}
                          </Field>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                              style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                          >
                                              Save
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )}
            />
        </div>
    );
};

export default TitlenDesc;
