import React from 'react';
import './SmallCard.scss';
import Img, { BackgroundImg } from 'react-cloudimage-responsive';
import { cloudimageConfig } from '../../../../assets/statics/cloud-image';
import { useHistory } from 'react-router-dom';

const SmallCard = (props) => {

    const {
        title,
        pins = 0,
        mainImage,
        secondaryImage,
        tertiaryImage,
        moodboardId
    } = props;

    const history = useHistory();

    return (
        <div className={`box-banderole-card small-banderole-card ${props.type}-card`}
             onClick={() => (props.type === 'exhibitions' && moodboardId) ? props.parentAction ? props.parentAction(moodboardId) : history.push(`/moodboard/details/${moodboardId}`) : null}
        >
            <div className={`box-background ${props.customClass ?? ''}`}>
                <Img className="background-image" onClick={() => props.imageOnClick(mainImage)}
                     src={cloudimageConfig.baseURL + mainImage} alt=""/>
                {props.customClass === 'triple-box' &&
                <div className="box-double">
                    {secondaryImage &&
                    <BackgroundImg className="box-double-item" src={cloudimageConfig.baseURL + secondaryImage}/>
                    }
                    {tertiaryImage &&
                    <BackgroundImg className="box-double-item" src={cloudimageConfig.baseURL + tertiaryImage}/>
                    }
                </div>
                }
            </div>
            {props.customClass === 'triple-box' &&
            <div className="box-banderole-content">
                <h6 className="phantom-bold" style={{marginBottom: "0"}}>{title}</h6>
                <p className="typo-size-small">{pins} pins</p>
            </div>
            }
        </div>
    );
};
export default SmallCard;
