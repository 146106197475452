import React, { useEffect, useState } from 'react';
import './OtherSeries.css';
import PicturesGrid from '../../../Shared/Pictures/PicturesGrid/PicturesGrid';
import { config } from '../../../../environments';
import { useHttpClient } from '../../../../hooks/http-hook';

const OtherSeries = ({ photographerId }) => {
    const { sendRequest } = useHttpClient();
    const seriesUrl = `${config.baseUrlDev}/serie/filter?photographerId=${photographerId}`;
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (photographerId) {
            sendRequest(seriesUrl, 'GET')
                .then((res) => {
                    const list = [];
                    res.map((s) => s.images.map((i) => list.push(i)));
                    setImages(list);
                })
                .catch((e) => console.warn({ e }));
        }
    }, [photographerId]);

    useEffect(() => console.log({ images }), [images]);

    const fakeData = {
        title: 'Other series',
        data: [
            { main: 'bg-other1.jpg' },
            { main: 'bg-other2.jpg' },
            { main: 'bg-other3.jpg' },
            { main: 'bg-other4.jpg' },
            { main: 'bg-other5.jpg' },
            { main: 'bg-other6.jpg' },
            { main: 'bg-other7.jpg' },
            { main: 'bg-other8.jpg' },
            { main: 'bg-other9.jpg' },
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };
    return <PicturesGrid title={fakeData.title}
                         data={images.length === 0 ? shuffleArray(fakeData.data) : shuffleArray(images)}
    />;
};

export default OtherSeries;
