import React, {useContext, useEffect, useState} from 'react';
import './CardPhotography.scss';
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import PrimaryBtn from '../../Buttons/Template/PrimaryBtn';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';
import {AuthContext} from '../../../../context/auth-context';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';
import {Link} from 'react-router-dom';
import {config} from '../../../../environments';
import {useHttpClient} from '../../../../hooks/http-hook';

const CardPhotography = (props) => {
    const {sendRequest} = useHttpClient();
    const {userType} = useContext(AuthContext);
    const [photographer, setPhotographer] = useState();

    useEffect(() => {
        if (!photographer) {
            if (props.author) {
                setPhotographer(props.author);
            } else if (props.item?.photographer && !props.author) {
                getPhotographer(props.item?.photographer);
            }
        }
    }, []);

    const getPhotographer = async (photographerId) => {
        return await sendRequest(
            `${config.baseUrlDev}/photographer/${photographerId}`,
        )
            .then((res) => setPhotographer(res))
            .catch((e) => console.warn({e}));
    }

    return (
        <BackgroundImg src={cloudimageConfig.baseURL
        + (props.image?.fileName ? props.image.fileName : props.image)}
                       width={props.imageW ? props.imageW : null}
                       className={`photography-card ${props.className ? props.className : ''}`}
        >
            <div className="card-content">
                {props.onClickDetails &&
                <PrimaryBtn className="photography-view-details"
                            parentAction={() => props.onClickDetails(props.item)}
                >
                    View details
                </PrimaryBtn>
                }
                <div className="photography-element-container">
                    <div className="photography-card-credits">
                        <p className="credit-label">Credit</p>
                        <Link to={`/photographer/details?photographerId=${photographer?._id}`}>
                            <p className="credit-value">
                                {photographer
                                    ? `${photographer.userId.firstName} ${photographer.userId.lastName}`
                                    : 'Andrea Bruce'
                                }
                            </p>
                        </Link>
                    </div>
                    {userType === roleEnum.AGENCY &&
                    <div className="photography-card-actions">
                        {props.status !== statusEnum.ACCEPTED &&
                        <PrimaryBtn parentAction={() => props.parentAction
                            ? props.parentAction(props.item?.requestId ? props.item.requestId : props.item._id, statusEnum.ACCEPTED)
                            : null
                        }>
                            Accept
                        </PrimaryBtn>
                        }
                        {props.status !== statusEnum.DECLINED &&
                        <TertiaryBtn parentAction={() => props.parentAction
                            ? props.parentAction(props.item?.requestId ? props.item.requestId : props.item._id, statusEnum.DECLINED)
                            : null
                        }>
                            Decline
                        </TertiaryBtn>
                        }
                    </div>
                    }
                </div>
            </div>
        </BackgroundImg>
    );
}
export default CardPhotography;
