import React, {useContext, useEffect, useState} from 'react';
import './PurchasesHistory.scss';
import {AuthContext} from '../../../context/auth-context';
import {useHttpClient} from '../../../hooks/http-hook';
import {config} from '../../../environments';
import PaymentIntentCard from './PaymentIntentCard/PaymentIntentCard';

// Material UI Styles
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        flexFlow: "row wrap",
    },
});

const PurchasesHistory = () => {
    const {token, customerId} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();

    const classes = useStyles();

    const [checkouts, setCheckouts] = useState([]);

    const checkoutsUrl = `${config.baseUrlDev}/stripe/checkout/customer`;

    const getRequest = async (url, callback) => {
        await sendRequest(url, 'GET', null, {cookies: token})
            .then((getData) => callback(getData))
            .catch((e) => console.warn({e}));
    }

    const retrievePurchases = async () => {
        await getRequest(`${checkoutsUrl}/${customerId}`, async (checkoutsList) => {
            setCheckouts(checkoutsList);
        });
    }

    useEffect(() => {
        if (customerId) {
            retrievePurchases();
        }
    }, [customerId]);

    return (
        <section className="page-section boxed-layout section-purchases-grid">
            <div className={`purchase-cards-container ${classes.root}`}>
                {checkouts.map((checkout, k) => (
                    <PaymentIntentCard key={k}
                                       checkout={checkout}
                    />
                ))}
                {checkouts.length === 0 &&
                    <p>No checkout.</p>
                }
            </div>
        </section>
    )
};
export default PurchasesHistory;
