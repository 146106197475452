import React, {useRef} from "react";
import "./SettingPassword.scss";
import {Field, Form, FormSpy} from 'react-final-form';
import {checkPassword, required} from '../../Forms/validators-final-form';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import { render } from "@testing-library/react";
import Toaster from "../../Toaster/Toaster";

const SettingPassword = ({parentAction}) => {
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const onSubmit = (formValues) => {
        parentAction(formValues, () =>  render(<Toaster success toastMsg="Password successfuly updated"/>));
    }

    return (
        <section className="personal-infos-container">
            <h4>Password</h4>
            <Form
                onSubmit={onSubmit}
                render={({form, submitError, handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="personal-infos-form">
                        <Field name="currentPassword" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="currentPassword">Current password</label>
                                    <input {...input} type="password"
                                           id="currentPassword"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Current password"
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name="password" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="password">New password</label>
                                    <input {...input} type="password"
                                           ref={passwordRef}
                                           id="password"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Password"
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name="confirmPassword"
                               validate={() => checkPassword(passwordRef.current.value, confirmPasswordRef.current.value)}
                        >
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="confirmPassword">Confirm password</label>
                                    <input {...input} type="password"
                                           ref={confirmPasswordRef}
                                           id="confirmPassword"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Confirm password"
                                    />
                                    {meta.error && meta.touched &&
                                    <div className="error-msg-container">
                                        <span className="error-msg">{meta.error}</span>
                                    </div>
                                    }
                                </div>
                            )}
                        </Field>
                        <div className="field-group btn-container">
                            <TertiaryBtn style={{padding: "0.5rem 1rem"}}
                                         parentAction={() => form.reset()}
                            >
                                Cancel
                            </TertiaryBtn>
                            <FormSpy subscription={{invalid: true}}>
                                {props => {
                                    const isInvalid = submitting || props.invalid;
                                    return (
                                        <button
                                            type="submit"
                                            disabled={isInvalid}
                                            className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                            style={{padding: "0.5rem 1rem"}}
                                        >
                                            Save
                                        </button>
                                    )
                                }}
                            </FormSpy>
                        </div>
                        {submitError &&
                        <div>
                            <div className="authent-error">{submitError}</div>
                        </div>
                        }
                    </form>
                )}
            />
        </section>
    );
};

export default SettingPassword;
