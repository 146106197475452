/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { VALIDATOR_MINLENGTH } from "../../../../../validators/validators";
import InputReg from "../../../../Shared/Forms/Input/InputReg";
import Label from "../../../../Shared/Forms/Input/Label/Label";
import BtnClassic from "../../../../Shared/Buttons/BtnClassic/BtnClassic";
import InputDropdown from "../../../../Shared/Forms/InputDropdown/InputDropdown";
import "./SeriesData.scss";
import {Field, Form, FormSpy} from 'react-final-form';
import { EditContext } from "../../context/EditContext";
import { AuthContext } from "../../../../../context/auth-context";
import { useSeries } from '../../../../../hooks/series/useSeries';
import { useImages } from '../../../../../hooks/images/useImage';
import Toaster from '../../../../Shared/Toaster/Toaster';
import { render } from '@testing-library/react';


const SeriesData = ({imageId, id1, id2, handleBtnClick, type}) => {

  const serieRef = useRef();
  const { handleDropdownChange, handleChange, formValues } = useContext(EditContext);
  const {userIdProfile, userType} = useContext(AuthContext)
  const {
    filterSeriesByPhotographerIdAndAgencyId,
    filteredSeries,
    updateSeries,
    getSeriesById,
    foundSeries,
    createSeries,
    filterSeries,
    filteredSeriesByImage
  } = useSeries();
 
  const {getImageById, foundImageById} = useImages()
  const[selectedSeries, setSelectedSeries] = useState(foundSeries);

  
  const handleSelectSeries = (series) => {
    setSelectedSeries(series);
    
    
  }

  useEffect(async() => {
    if(imageId) {
      await getImageById(imageId);
    }
  },[imageId])

  useEffect(async() => {
    if(filteredSeries) {
      console.log(filteredSeries);
    }
  },[filteredSeries])

 

  useEffect(async () => {
    if(formValues[id1]) {
      await getSeriesById(formValues[id1])
    }
  },[formValues[id1]])

  useEffect(async() => {
    if(foundImageById && foundImageById?.data?.agency?._id) {
      await filterSeriesByPhotographerIdAndAgencyId(userIdProfile, foundImageById?.data?.agency?._id);
      await filterSeries(userType, userIdProfile, imageId);
    }
  },[foundImageById])

  const handleSaveButtonClick = async () => {
    await updateSeries(selectedSeries._id, {images: [...selectedSeries.images, imageId]}, false)
      .then(() => {
        render(<Toaster success toastMsg="Updated successfuly !"/>)
      }).catch((e) => {
        console.warn({e})
        render(<Toaster error toastMsg="An error occured !"/>)
      })
    await filterSeries(userType, userIdProfile, imageId);
    
  }

  const handleCreateSeries = async () => {
   if(foundImageById?.data?.agency?._id) {
    await createSeries({title: formValues[id2], agencyId: foundImageById?.data?.agency?._id}, false);
    await filterSeriesByPhotographerIdAndAgencyId(userIdProfile, foundImageById?.data?.agency?._id);
    handleChange({current: {value: '', id:'newSerie'}});
   }
  }
  
  
  return (
    <>
      <div className="box-series-edit">
        <h3>Series</h3>

        <Label>Select from existing</Label>
        <InputDropdown
          id={id1}
          validators={[VALIDATOR_MINLENGTH(1)]}
          margin="8px 0 0 0"
        
          data={filteredSeries}
          parentAction={handleDropdownChange}
          onSelectSeries={handleSelectSeries}
          value={formValues[id1]}
        
          placeholder={selectedSeries?.title && "default"}
          selectedSeries={selectedSeries}
          
        />



        <div className="or-box-line">
          <div>OR</div>
          <div className="line-or" />
        </div>
       
        <Label>Create new Series</Label> 
        <InputReg
          id={id2}
          validators={[VALIDATOR_MINLENGTH(1)]}
          margin="8px 0 0 0"
          parentRef={serieRef}
          parentAction={() => handleChange(serieRef)}
          type="text"
          disabled={!foundImageById?.data?.agency?._id}
          agency={foundImageById?.data?.agency?._id}
          handleCreateSeries={handleCreateSeries}
          value={formValues[id2]}
        /> 
         <button 
            type="submit" 
            disabled={!selectedSeries} 
            className={`button secondary ${!selectedSeries ? "disabled" : ""}`}
            onClick={handleSaveButtonClick}
            style={{
              width: "100%",
              textAlign: "center",
              padding: "0.5rem 0",
              marginTop:"1rem"
            }}
          >
            Save
          </button>
        <div className="btns-row-up">
          <BtnClassic
            width="127px"
            height="36px"
            bg="#f3f5f7"
            color="#000"
            position="relative"
            margin="20px 0px"
            left="0"
            onClick={() => handleBtnClick(type, false)}
          >
            Prev
          </BtnClassic>
          <BtnClassic
            width="127px"
            height="36px"
            bg="#FFE942"
            color="#000"
            position="relative"
            margin="20px 0px"
            left="183px"
            justifySelf="flex-end"
            onClick={() => handleBtnClick(type, true)}
          >
            Next
          </BtnClassic>
        </div>
      </div>
    </>
  );
};

export default SeriesData;
