import React from 'react';
import './AdminStats.scss';
import Header from "../../Header/Header";

const AdminStats = () => {
    const fakeData = [
        {title: 'Pending Photographers', count: 345, link: ''},
        {title: 'Pending Agencies', count: 22, link: ''},
        {title: 'Pending Media users', count: 654, link: ''},
    ]

    return (
        <section className="boxed-layout page-section admin-stats-section">
            <Header titleType={3} title="Demands" fontSize="42px"/>
            <div className="admin-stats-content gl-col">
                {fakeData.map((stat) => (
                    <div className="admin-stats-bloc gl-col-4" style={{margin: "0 auto"}}>
                        <div className="admin-stats-data">
                            <h4 className="stats-counter">{stat.count}</h4>
                            <p className="stats-label">{stat.title}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
export default AdminStats;
