import React from 'react';
import './TagAll.scss';
import Tag from './Tag/Tag';
import {BsArrowRight} from 'react-icons/bs';

const TagAll = () => {
    const fakeData = {
        data: [
            {label: 'Tag', link: ''},
            {label: 'Other', link: ''},
            {label: 'Other long tag', link: ''},
            {label: 'Tag', link: ''},
            {label: 'Tag', link: ''},
            {label: 'Othertag', link: ''},
            {label: 'Tag', link: ''},
            {label: 'Othertag', link: ''},
            {label: 'Tag', link: ''},
            {label: 'Other long tag', link: ''},
            {label: 'Tag', link: ''},
            {label: 'Tag', link: ''},
        ],
    }
    return (
        <div className="tag-line">
            {fakeData.data &&
            fakeData.data.map((tag, k) => <Tag className="tag-item" key={k}>{tag.label}</Tag>)
            }
            <BsArrowRight className="arrow-tag"/>
        </div>
    )
}

export default TagAll
