import React from 'react'
import MostBoxs from '../../../Shared/Statistics/MostBoxs/MostBoxs'
import Stats from '../../../Shared/Statistics/Stats/Stats'
import './Statistics.css'

const Statistics = () => {
    return (
        <>
            <div className="section-statistics-agency boxed-layout gl-col">
                <Stats
                    title1="Certificates"
                    value1={1259}
                    titlebox1="Pending reviews"
                    valuebox1={9}
                    titlebox2="Pending Demands"
                    valuebox2={3}
                    title3="Moodboards"
                    value3={22}
                />

                <MostBoxs titleh2="liked" number1={1242} title1="Number of likes" credits="Credit"
                          valuecredits="Andrea Bruce."/>
                <MostBoxs titleh2="viewed" number1={11765} title1="Number of views" credits="Credit"
                          valuecredits="Henrietta Greer"/>
                <MostBoxs titleh2="purchased" number1={976} title1="Number of purchases" credits="Credit"
                          valuecredits="Duane Reynolds"/>

            </div>
        </>
    )
}

export default Statistics
