import React from 'react';
import './PicturesGridRegular.scss';
import Header from "../../Header/Header";
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import {useHistory} from 'react-router-dom';

const PicturesGridRegular = (props) => {
    const history = useHistory();

    const handleImageLoad = (event) => {
        const img = event.target;
        const findParentGridItem = (el) => {
            const parent = el.parentElement;
            if (!parent) {
                return;
            }
            return (parent?.classList.contains('grid-item') && !parent?.classList.contains('double'))
                ? parent.className += (img.naturalWidth > img.naturalHeight) ? ' double' : ''
                : findParentGridItem(parent);
        }
        findParentGridItem(img);
        img.style.display = 'none';
    };

    return (
        <section className="page-section boxed-layout">
            {props.title &&
            <Header titleType={2} title={props.title}/>
            }
            <div className="regular-grid-content">
                {props.data.map((picture, k) => {
                    const image = (
                        <img src={picture.fileName
                            ? `https://${cloudimageConfig.token}${cloudimageConfig.url}/${cloudimageConfig.baseURL}${picture.fileName}`
                            : picture.main}
                             onLoad={handleImageLoad} alt=""
                             style={{opacity: "0"}}
                        />
                    )
                    return picture.fileName
                        ? (<BackgroundImg className="grid-item" src={cloudimageConfig.baseURL + picture.fileName}
                                          style={{cursor: picture?._id ? "pointer" : "initial"}}
                                          onClick={() => picture?._id ? history.push(`/image/details/${picture?._id}`) : null}
                                          key={k}>
                            {image}
                        </BackgroundImg>)
                        : <div className="grid-item" style={{backgroundImage: `url(${picture.main})`}}
                               key={k}>{image}</div>
                })}
            </div>
        </section>
    )
};
export default PicturesGridRegular;
