import React from 'react';
import './ExploreSearchBar.scss';
import {AiFillCaretDown as Caret, AiFillCaretRight as RightCaret} from 'react-icons/ai';
import InputTerms from './InputTerms/InputTerms';
import InputLicence from './InputLicence/InputLicence';
import InputColor from './InputColor/InputColor';
import InputLayout from './InputLayout/InputLayout';

const ExploreSearchBar = ({searchValues, showMore, setShowMore, updateSearchValues, submitSearch}) => {
    const onSubmit = (e) => {
        e.preventDefault();
        submitSearch();
    }

    return (
        <form className="container-exp-search" onSubmit={onSubmit}>
            <InputTerms terms={searchValues.terms} updateSearchValues={updateSearchValues}/>
            <InputLicence urlLicences={searchValues.licences} updateSearchValues={updateSearchValues}/>
            <InputColor/>
            <InputLayout urlLayout={searchValues.layout}  updateSearchValues={updateSearchValues}/>
            <div className="search-exp-more search-bar-item"
                 onClick={() => setShowMore(!showMore)}
            >
                More filters
                {showMore ? (
                    <Caret className="search-caret"/>
                ) : (
                    <RightCaret className="search-caret"/>
                )}
            </div>
            <button type="submit" className="button secondary search-exp-submit">
                Search
            </button>
        </form>
    );
};
export default ExploreSearchBar;
