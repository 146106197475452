import React, { useEffect } from "react";
import "./MicroPayment.css";
import { SiLetsencrypt } from "react-icons/si";

const MicroPayment = () => {
  return (
    <>
      <div className="section-micro-payment">
        <div className="container-micro-left">
          <div className="micro-payment-grey">
            <div className="content-purchase">
              <h2>
                Purchase <br /> licence
              </h2>
              <div>
                <SiLetsencrypt className="icon-encrypt" />
              </div>
            </div>
          </div>
          <div className="btns-micro">
            <div className="btn-micro1">Purchase Now</div>
            <div className="btn-micro2">View Licences</div>
          </div>
        </div>
        <div className="container-micro-right">
          <div className="micro-payment-grey">
            <div className="line-purchase"></div>
            <div className="paragraph-purchase">
              Looks like you're here for improper use of images or content.{" "}
              <br />
              <br />
              Don't panic, however, you can easily remedy this problem by
              helping to protect the work of creatives by purchasing a license
              that corresponds to your use of the image.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MicroPayment;
