import React, {useContext, useEffect, useState} from 'react';
import './AgencySelect.scss';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../Forms/validators-final-form';
import {useHttpClient} from '../../../../../hooks/http-hook';
import {AuthContext} from '../../../../../context/auth-context';
import {config} from '../../../../../environments';
import Toaster from '../../../Toaster/Toaster';
import { render } from '@testing-library/react';

const AgencySelect = () => {
    const [agencies, setAgencies] = useState([]);
    const [res, setRes] = useState();
    const [selected, setSelected] = useState('');
    const {sendRequest} = useHttpClient();
    const submitEndpoint = `${config.baseUrlDev}/agency/accepted/all`;
    const requestEndpoint = `${config.baseUrlDev}/join-agency-request/add`;
    const {userIdProfile, token} = useContext(AuthContext);

    useEffect(() => {
        if (res) {
            setTimeout(() => setRes(), 6000);
        }
    }, [res]);

    const retrieveAgencies = async () => {
        return await sendRequest(submitEndpoint, 'GET', null, {'Content-Type': 'application/json', cookies: token})
            .then((data) => {
                setAgencies(data.map((agency) => {
                    return {name: agency.companyName, id: agency._id};
                }))
            })
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg="Unexpected error during fetch of datas"/>)
            });
    }

    const sendRequestAdd = async (formValues) => {
        return await sendRequest(
            requestEndpoint,
            'POST',
            JSON.stringify({...formValues, photographer: userIdProfile}),
            {"Content-Type": "application/json"}
        )
            .then((data) => {
                setRes(data)
                render(<Toaster success toastMsg="Request send, wait for agency's validation !"/>)
            })
            .catch((e) => {
                setRes();
                render(<Toaster error toastMsg="Unexpected error !"/>)
                return e;
            });
    }

    const onSubmit = (formValues) => {
        setSelected(agencies.find((a) => a.id === formValues.agency));
        sendRequestAdd(formValues);
    }

    useEffect(() => {
        retrieveAgencies();
    }, []);

    return (
        <div className="agency-form-container">
            <h4>Join agencies</h4>
            {res
                ? <p>Your request was successfully sent to <strong>{selected.name}</strong> !</p>
                : <Form
                    onSubmit={onSubmit}
                    render={({submitError, handleSubmit, submitting}) => (
                        <form onSubmit={handleSubmit} className="agency-form">
                            <Field name="agency" validate={required}>
                                {({input, meta}) => (
                                    <div className="field-group">
                                        <label>Select an agency</label>
                                        <select {...input} type="select"
                                                className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                        >
                                            <option/>
                                            {agencies.map((agency, k) => <option value={agency.id}
                                                                                 key={k}>{agency.name}</option>)}
                                        </select>
                                    </div>
                                )}
                            </Field>
                            <div className="field-group">
                                <FormSpy subscription={{pristine: true}}>
                                    {props => {
                                        const isInvalid = submitting || props.invalid;
                                        return (
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                                style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                            >
                                                Send a join request
                                            </button>
                                        )
                                    }}
                                </FormSpy>
                            </div>
                            {submitError &&
                            <div>
                                <div className="authent-error">{submitError}</div>
                            </div>
                            }
                        </form>
                    )}
                />
            }
        </div>
    )
}
export default AgencySelect;
