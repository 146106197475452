import { useReducer } from "react";

const initialState = {
  titlend: true,
  credits: false,
  metadata: false,
  series: false,
  special: false,
  keyword: false,
  agency: false,
  market: false,
  exhi: false,
  colors: false,

  iptc: true,
  exif: false,

  iptcActive: true,
  exifActive: false,
  
  titlendValid: false,
  creditsValid: false,
  metadataValid: false,
  seriesValid: false,
  specialValid: false,
  keywordValid: false,
  agencyValid: false,
  marketValid: false,
  exhiValid: false,
  colorsValid: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TITLEND":
      return {
        titlend: true,
      };

    case "SET_CREDITS":
      return {
        credits: true,
      };

    case "SET_METADATA":
      return {
        metadata: true,
      };

    case "SET_SERIES":
      return {
        series: true,
      };

    case "SET_SPECIAL":
      return {
        special: true,
      };

    case "SET_KEYWORD":
      return {
        keyword: true,
      };

    case "SET_AGENCY":
      return {
        agency: true,
      };

    case "SET_MARKET":
      return {
        market: true,
      };

    case "SET_EXHI":
      return {
        exhi: true,
      };

    case "SET_COLORS":
      return {
        colors: true,
      };

    case "TO_IPTC":
      return { iptc: true, exif: false, iptcActive: true, exifActive: false };

    case "TO_EXIF":
      return { iptc: false, exif: true, iptcActive: false, exifActive: true };

    case "VALID_TITLEND":
      return { titlendValid: true, credits: true };

    case "VALID_CREDITS":
      return { creditsValid: true, titlendValid: true, metadata: true };
  }
};

export const useEditReducer = () => {
  return useReducer(reducer, initialState);
};
