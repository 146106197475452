import React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../../../Shared/Forms/validators-final-form';

const AddMetadataForm = ({parentAction}) => {
    const onSubmit = (formValues) => {
        parentAction(formValues);
    }

    return (
        <Form onSubmit={onSubmit}
              render={({submitError, handleSubmit, submitting}) => {
                  return (
                      <form onSubmit={handleSubmit} className="metadata-form">
                          <Field name="meta-title" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="meta-title">Metadata title</label>
                                      <input {...input}
                                             type="text"
                                             id="meta-title"
                                             className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                      />
                                  </div>
                              )}
                          </Field>
                          <Field name="meta-value" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="meta-value">Metadata value</label>
                                      <input {...input}
                                             type="text"
                                             id="meta-value"
                                             className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                      />
                                  </div>
                              )}
                          </Field>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button primary ${isInvalid ? 'disabled' : ''}`}
                                              style={{
                                                  width: "100%",
                                                  textAlign: "center",
                                                  padding: "0.5rem 0"
                                              }}
                                          >
                                              Add
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )
              }}
        />
    );
};
export default AddMetadataForm;
