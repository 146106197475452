import React from "react";
import "./BtnClassic.css";

const BtnClassic = (props) => {
  return (
    <>
      <div
        className={`btn-classic1 ${props.className}`}
        onClick={props.onClick}
        style={{
          background: props.bg,
          color: props.color,
          width: props.width,
          borderRadius: props.radius,
          height: props.height,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin,
          position: props.position,
          justifySelf: props.justifySelf

        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default BtnClassic;
