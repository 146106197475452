import React from "react";

const PopularParagraph = ({photographer}) => {
  return (
    <>
      <div className="popular-paragraph">
        <p>&copy; {photographer?.userId?.firstName} {photographer?.userId?.lastName}.</p>
        <p>
          I focus a lot on helping the first time or inexperienced traveler head
          out prepared and confident in themselves. Starting out as a old
          traveler can be intimidating. How do you jump into the gigantic travel
          fray and survive? How can you learn to love delays and long lines?
        </p>
        <p>
          What exactly does that mean? It means being confident in what you are
          doing, where you are going and how you are getting there. basically,
          it means being comfortable with yourself when you leave home.
        </p>
      </div>
    </>
  );
};

export default PopularParagraph;
