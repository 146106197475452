import React from 'react';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import PurchasesDetails from '../../components/Purchases/PurchasesDetails/PurchasesDetails';

const PagePurchase = ({match}) => {
    const purchaseId = match?.params?.purchaseId;
    const prefix = 'cs_test_';

    return (
        <PageLayout>
            <section className="page-section">
                <header className="page-header boxed-layout" style={{paddingTop: "100px"}}>
                    <h1>My<br/>Purchase</h1>
                </header>
                <PurchasesDetails checkoutId={`${prefix}${purchaseId}`}/>
            </section>
        </PageLayout>
    )
};
export default PagePurchase;
