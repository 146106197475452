import React, {useContext, useRef, useState} from "react";
import "./FormUploadImage.scss";
import {useHistory, withRouter} from "react-router-dom";
import {AuthContext} from "../../../context/auth-context";
import {useHttpClient} from "../../../hooks/http-hook";
import {UploadContext} from "../../../context/UploadContext";
import {config} from '../../../environments';
import UploadForm from '../PhotographerUpload/UploadForm/UploadForm';
import UploadResults from '../PhotographerUpload/UploadResults/UploadResults';
import Toaster from "../../Shared/Toaster/Toaster";
import { render } from "@testing-library/react";

const FormUploadImage = () => {
    const {handleChange, uploaded, setUploaded} = useContext(UploadContext);
    const {sendRequest} = useHttpClient();
    const {userIdProfile, token} = useContext(AuthContext);
    const [error, setError] = useState();
    const baseurl = `${config.baseUrlDev}/images/s3-upload/${userIdProfile}`;
    const pseudoInput = useRef();
    const title = useRef();
    const credit = useRef();
    const history = useHistory();

    const [submitting, setSubmitting] = useState(false);

    const submitForm = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setError();
        const data = new FormData(e.target);
        data.append('title', title.current.value);
        data.append('credit', credit.current.value);
        const file = data.get('myImage');
        if (!file || !file.name) {
            this.addToast('A file is required.');
            return;
        }
        return await sendRequest(
            baseurl,
            "POST",
            data,
            {'cookies': token}
        )
            .then((data) => {
                data.map((item) => setUploaded(item));
                render(<Toaster success toastMsg="Upload successfuly !"/>)
            })
            .catch((e) => {
                render(<Toaster error toastMsg="An error occured !"/>)
                setError(e)
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <section className="page-section boxed-layout section-upload" style={{minHeight: "60vh"}}>
            <header className="section-header">
                <h1>Upload your work !</h1>
            </header>
            <div className="results-section-content">
                <UploadForm title={title}
                            credit={credit}
                            history={history}
                            pseudoInput={pseudoInput}
                            handleChange={handleChange}
                            submitForm={submitForm}
                            error={error}
                            submitting={submitting}
                />
                {uploaded.length > 0 &&
                <UploadResults results={uploaded}/>
                }
            </div>
        </section>
    );
};

export default withRouter(FormUploadImage);
