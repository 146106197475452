import React, {useContext, useState} from 'react';
import './LoginForm.scss';

import AuthentHeader from '../AuthentHeader/AuthentHeader';
import PrimaryBtn from '../../Shared/Buttons/Template/PrimaryBtn';
import {btnEnum, endpointEnum, formEnum, formTitle, subEnum} from '../../../assets/statics/authent-signin.enum';
import {required} from '../../Shared/Forms/validators-final-form';
import {Field, Form, FormSpy} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {useHttpClient} from '../../../hooks/http-hook';
import {useHistory, useLocation} from 'react-router-dom';
import {AuthContext} from '../../../context/auth-context';
import TertiaryBtn from '../../Shared/Buttons/Template/TertiaryBtn';
import Toaster from '../../Shared/Toaster/Toaster';
import { render } from '@testing-library/react';

const LoginForm = () => {
    const history = useHistory();
    const {sendRequest} = useHttpClient();
    const search = useLocation().search;
    const toRedirect = new URLSearchParams(search).get('from');

    const [formStep, setFormStep] = useState(formEnum.CONNECTION);
    const [formSubtitle, setFormSubtitle] = useState(subEnum.CONNECTION);
    const [btnLabel, setBtnLabel] = useState(btnEnum.CONNECTION)
    const [submitEndpoint, setSubmitEndpoint] = useState(endpointEnum.CONNECTION);

    const auth = useContext(AuthContext);

    const toggleForm = () => {
        if (formStep === formEnum.CONNECTION) {
            setFormStep(formEnum.RESET);
            setFormSubtitle(subEnum.RESET);
            setBtnLabel(btnEnum.RESET);
            setSubmitEndpoint(endpointEnum.RESET);
        } else {
            setFormStep(formEnum.CONNECTION);
            setFormSubtitle(subEnum.CONNECTION);
            setBtnLabel(btnEnum.CONNECTION);
            setSubmitEndpoint(endpointEnum.CONNECTION);
        }
    }

    const onSubmit = async (formValues) => {
        const body = {
            email: formValues.email,
        };
        if (formValues.password) {
            body.password = formValues.password
        }
        return await sendRequest(
            submitEndpoint,
            "POST",
            JSON.stringify(body),
            {
                "Content-Type": "application/json",
            }
        )
            .then((res) => {
                auth.login(res.accessToken);
                render(<Toaster success toastMsg="Logged in successfuly !"/>);
                return (toRedirect)
                    ? history.push(decodeURI(toRedirect))
                    : history.push('/explore');
            })
            .catch(() => {
                return {
                    [FORM_ERROR]: formStep === formEnum.CONNECTION ? <Toaster error toastMsg="Bad credentials !"/> : <Toaster error toastMsg="Unexpected error !"/>
                    };
            });
    };

    return (
        <div className="login-form-container">
            <AuthentHeader title={formTitle} subtitle={formSubtitle}/>
            <Form
                onSubmit={onSubmit}
                render={({submitError, handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="login-form">
                        <Field name="email" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label>Email</label>
                                    <input {...input} type="text"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="user@pixt.com"/>
                                </div>
                            )}
                        </Field>
                        {formStep === formEnum.CONNECTION && <Field name="password" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label>Password</label>
                                    <input {...input} type="password"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"/>
                                    <span className="forgot-password-anchor"
                                          onClick={toggleForm}
                                    >
                                        Forgot password ?
                                    </span>
                                </div>
                            )}
                        </Field>}
                        {submitError &&
                        <div>
                            <div className="authent-error">{submitError}</div>
                        </div>
                        }
                        <div className="field-group">
                            <FormSpy subscription={{pristine: true}}>
                                {props => {
                                    const isInvalid = submitting || props.pristine;
                                    return (
                                        <button
                                            type="submit"
                                            disabled={isInvalid}
                                            className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                            style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                        >
                                            {btnLabel}
                                        </button>
                                    )
                                }}
                            </FormSpy>
                        </div>
                    </form>
                )}
            />
            <div>
                <hr style={{margin: '2rem 6rem'}}/>
                {formStep === formEnum.RESET &&
                <div className="field-group">
                    <PrimaryBtn parentAction={toggleForm}
                                className="btn-full-width color-white"
                                style={{padding: "0.5rem 0"}}
                    >
                        Back to sign in
                    </PrimaryBtn>
                </div>
                }
                <div className="field-group">
                    <TertiaryBtn parentAction={() => history.push('/register')}
                                 className="btn-full-width wired"
                                 style={{padding: "0.5rem 0"}}
                    >
                        Create account
                    </TertiaryBtn>
                </div>
            </div>
        </div>
    )
};
export default LoginForm;
