/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import PageLayout from "../../../components/Shared/PageLayout/PageLayout";

import { useHistory } from "react-router";
import { AuthContext } from "../../../context/auth-context";
import { useStory } from "../../../hooks/stories/useStory";

import { Link } from "react-router-dom";

import SmallBanderole from "../../../components/Shared/Banderole/Template/SmallBanderole/SmallBanderole";
import TertiaryBtn from "../../../components/Shared/Buttons/Template/TertiaryBtn";
import { FiPlus } from "react-icons/fi";

import { roleEnum } from "../../../assets/statics/authent-signup.enum";

export function StoriesList() {
  const history = useHistory();
  const { userIdProfile, userType } = useContext(AuthContext);

  const { filterStories, filteredStories } = useStory();

  const shuffleArray = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
  };

  const parentAction = (storyId) => history.push(`/story/edit/${storyId}`);

  useEffect(async () => {
    if (userIdProfile) {
      await filterStories(userType, userIdProfile);
    }
  }, [userIdProfile, userType]);

  return (
    <PageLayout>
      <section className="page-section boxed-layout series-list-section">
        <header className="mb-list-header">
          <h1>My Stories</h1>
          {userType === roleEnum.PHOTOGRAPHER && (
            <Link to="/story/add">
              <TertiaryBtn className="create-btn">
                <FiPlus className="plus-add" />
                Create a new story
              </TertiaryBtn>
            </Link>
          )}
        </header>
      </section>
      <SmallBanderole
        noControls
        title="Stories"
        data={shuffleArray(filteredStories)}
        containerId="photographer-stories"
        type="exhibitions"
        className={"moodboards-list"}
        parentAction={parentAction}
      />
    </PageLayout>
  );
}
