import React from "react";
import "./Checkout.css";
import BtnBack from "../../Shared/Buttons/BtnBack/BtnBack";
import Label from "../../Shared/Forms/Input/Label/Label";
import InputReg from "../../Shared/Forms/Input/InputReg";
import BtnClassic from "../../Shared/Buttons/BtnClassic/BtnClassic";

const Checkout = () => {
  return (
    <>
      <div className="checkout-container-page">
        <div className="checkout-left">
          <BtnBack
            width="150px"
            right="70px"
            position="absolute"
            top="80px"
            left="50px"
          >
            <strong>PIXT</strong>
          </BtnBack>
          <div className="petit-carre">
            <Label>Andrea bruce</Label>
            <h2 className="pricing-this">$1,000</h2>
            <div>Qty 1</div>
          </div>
          <div className="carre-images-left"></div>
        </div>
        <div className="checkout-right">
          <div className="checkout-contact">Contact information</div>

          <div className="box-input-reg">
            <Label>Email</Label>
            <InputReg type="email" className="checkout-input-that" />
          </div>
          <div className="box-input-reg">
            <input
              className="checkout-input-that"
              placeholder="1234 1234 1234 1234"
            />
            <div className="checkthis-out">
              <input
                type="text"
                className="checkout-input-that-one"
                placeholder="MM / YY"
              />
              <input
                type="text"
                className="checkout-input-that-deux"
                placeholder="CVC"
              />
            </div>
          </div>
          <div className="box-input-reg">
            <Label>Name on card</Label>
            <InputReg type="text" className="checkout-input-that" />
          </div>
          <div className="box-input-reg">
            <Label>Country or region</Label>
            <InputReg type="text" className="checkout-input-that" />
          </div>
          <div className="petit-check-reg">
            <InputReg type="checkbox" />
            <Label className="point-blank">
              Save card for future transactions
            </Label>
          </div>
          <div className="box-next-reg">
            <BtnClassic className="btn-checkout-regular">Pay $1,000</BtnClassic>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
