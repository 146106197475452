import React from "react";
import Paragraphs from "./Paragraphs/Paragraphs";
import './Whatispixt.css'
import {GiPositionMarker} from 'react-icons/gi'
import Image1 from "../../../assets/images/bg-test.jpg"
import Image2 from "../../../assets/images/bg-test2.jpg"
import Image3 from "../../../assets/images/bg-test3.jpg"
import Image4 from "../../../assets/images/bg-about1.jpg"
import Image5 from "../../../assets/images/bg-about2.jpg"
import Image6 from "../../../assets/images/bg-about3.jpg"
import Image7 from "../../../assets/images/bg-about5.jpg"

const Whatispixt = () => {
  return (
    <>
      <div className="section-whatis">
        <div className="container-whatis-left">
          <div className="box-whatis-title">
            <h2>What is Pix.T ?</h2>
          </div>
          <div className="box-whatis-paragraphs">
            <Paragraphs />
          </div>
        </div>
        <div className="container-whatis-right">
            <div className="whatis-rectangle1" style={{backgroundImage: `url(${Image5})`}}></div>
            <div className="whatis-rectangle2" style={{backgroundImage: `url(${Image4})`}}>
                <h3>Series 1</h3>
            </div>
            <div className="whatis-rectangle3">
                <div className="box-bg-rec3" style={{backgroundImage: `url(${Image6})`}}></div>
                <div className="content-rec3-name">
                    <h3>Andrea Bruce / NOOR</h3>
                </div>
                <div className="content-rec3-position">
                    <div className="pos-circle">
                        <GiPositionMarker/>
                    </div>
                    <h3>Currently in Paris, France</h3>
                </div>

            </div>
            <div className="whatis-rectangle4" >
                <div className="rectangle4-top" style={{backgroundImage: `url(${Image3})`}}></div>
                <div className="rectangle4-bottom">
                    <div className="rectangle4-bottom-left" style={{backgroundImage: `url(${Image1})`}}></div>
                    <div className="rectangle4-bottom-right" style={{backgroundImage: `url(${Image2})`}}></div>
                </div>
                <h3>My Moodboard</h3>
                <h4>122 pins</h4>
            </div>
            <div className="whatis-rectangle5" style={{backgroundImage: `url(${Image7})`}}>
                <h3>Series 1</h3>
            </div>
        </div>
      </div>
    </>
  );
};

export default Whatispixt;
