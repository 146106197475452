import React, {useContext} from 'react';
import './BigCard.scss';
import {BackgroundImg} from "react-cloudimage-responsive";
import {cloudimageConfig} from "../../../../assets/statics/cloud-image";
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../../../../context/auth-context';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';

const BigCard = (props) => {
    const history = useHistory();
    const {userType} = useContext(AuthContext);

    const handleClick = () => {
        if (!props.isSerie) {
            return (props.type === 'edit-story' && userType === roleEnum.PHOTOGRAPHER)
                ? history.push(`/story/edit/${props.id}`)
                : history.push(`/story/details/${props.id}`);
        } else {
            history.push(`/serie/details/${props.id}`);
        }
    }

    return (
        <div className="box-banderole-card big-card-item" onClick={() => props.id ? handleClick() : null}>
            <BackgroundImg src={cloudimageConfig.baseURL + props.bg}>
                <p className="big-card-title" style={{color: props.color}}>{props.title}</p>
            </BackgroundImg>
        </div>
    );
};
export default BigCard;
