import React, {useState} from 'react';
import ExploreAll from '../../components/ExplorePage/ExploreAll';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import ExpBoard from '../../components/ExplorePage/ExpBoard/ExpBoard';
import AgencyPhotographers from '../../components/AgencyPage/AgencyPhotographers/AgencyPhotographers';

const Explore = () => {
    const [searchResults, setSearchResults] = useState({
        agencies: [],
        images: [],
        moodboards: [],
        photographers: [],
        series: []
    });

    const noItem = (arr) => {
        return arr.length === 0;
    }

    const isEmpty = () => {
        return noItem(searchResults.agencies) && noItem(searchResults.images) && noItem(searchResults.moodboards) && noItem(searchResults.photographers) && noItem(searchResults.series);
    }

    return (
        <PageLayout>
            <ExploreAll setSearchResults={setSearchResults}/>
            <ExpBoard images={searchResults.images}/>
        </PageLayout>
    );
}

export default Explore;
