import React from "react";
import "./CircleLoc.css";
import { IoLocationOutline } from "react-icons/io5";

const CircleLoc = (props) => {
  return (
    <>
      <div
        className={`circle-loc ${props.className}`}
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundColor: props.bg,
          padding: props.padding,
          height: props.height,
          width: props.width,
          margin: props.margin,
          flexDirection: props.direction,
          alignItems: props.align,
          justifyContent: props.justify,
          overflow: props.overflow,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          border: props.border,
          position: props.position,
          zIndex: props.zIndex,
        }}
      >
        {props.children ? (
          props.children
        ) : (
          <IoLocationOutline className="icon-loc" />
        )}
      </div>
    </>
  );
};

export default CircleLoc;
