export const errorCodeEnum = {
    NOT_FOUND: 404,
    NOT_AUTHORIZED: 401,
};

export const errorTitleEnum = {
    NOT_FOUND: 'Not found',
    NOT_AUTHORIZED: 'Unauthorized',
};

export const errorMessageEnum = {
    NOT_FOUND: `The page you are looking for doesn't exist!`,
    NOT_AUTHORIZED: `You don't have access rights to this page!`,
};
