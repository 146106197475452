import React from "react";
import Edit from "../../Buttons/Edit/Edit";
import ContainerDirection from "../../Containers/ContainerRow/ContainerDirection";
import "./CardEdit.css";

const CardEdit = (props) => {
  return (
    <>
      <div
        className="card-edits"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin,
        }}
        onClick={props.onClickImage}
      >
          <ContainerDirection direction="column" width="248px" height="79px" bg="#fff" position="absolute" bottom="5px" > 

            <h4 style={{color: props.color}}>{props.title}</h4>
            <h5>{props.subtitle}</h5>
            <h6>{props.price}</h6>
            <Edit position="absolute" top="5px" right="10px" />
          </ContainerDirection>
      </div>
    </>
  );
};

export default CardEdit;