import React from 'react';

const TertiaryBtn = (props) => {
    return (
        <div className={`button tertiary btn-default-height ${props.className ?? ''}`}
             onClick={props.parentAction}
             style={props.style}
        >
            {props.children}
        </div>
    )
};
export default TertiaryBtn;
