import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import SimpleImageGrid from '../../../Shared/SimpleImageGrid/SimpleImageGrid';
import PrimaryBtn from '../../../Shared/Buttons/Template/PrimaryBtn';

const ImagesSelectionDialog = ({isOpen, handleClose, photographerAgencyImages, selectedImages, toggleImage}) => {
    return (
        <Dialog open={isOpen}
                onClose={handleClose}
                fullWidth="xl"
                maxWidth="xl"
                scroll="paper"
        >
            <DialogTitle id="simple-dialog-title" className="text-center">Select images</DialogTitle>
            <DialogContent dividers>
                <SimpleImageGrid images={photographerAgencyImages}
                                 mode="edit"
                                 selectedImages={selectedImages}
                                 toggleImage={toggleImage}
                />
            </DialogContent>
            <DialogActions style={{justifyContent: "center"}}>
                <PrimaryBtn parentAction={handleClose}>
                    Done
                </PrimaryBtn>
            </DialogActions>
        </Dialog>
    )
}
export default ImagesSelectionDialog;
