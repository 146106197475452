import React from "react";
import "./ProfileBtn.css";

const ProfileBtn = (props) => {
  return (
    <>
      <div
        className={`btn-profile1 ${props.className}`}
        style={{
          background: props.bg,
          color: props.color,
          width: props.width,
          height: props.height,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin
        }}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    </>
  );
};

export default ProfileBtn;
