import React from 'react';
import AdminStats from "../../components/Shared/Statistics/AdminStats/AdminStats";
import AdminPhotographers from "../../components/AdminDashboard/AdminPhotographers/AdminPhotographers";
import AdminMediaUsers from "../../components/AdminDashboard/AdminMediaUsers/AdminMediaUsers";
import AdminAgencies from "../../components/AdminDashboard/AdminAgencies/AdminAgencies";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const AdminDashboard = () => {
    return (
        <PageLayout>
            <main>
                <AdminStats/>
                <AdminPhotographers titleType={3}/>
                <AdminAgencies titleType={3}/>
                <AdminMediaUsers titleType={3}/>
            </main>
        </PageLayout>
    )
}
export default AdminDashboard;
