export default function useReadableAddress() {
    const addressTypeEnum = {
        FULL: 'full',
        SHORT: 'short',
    }

    const getAddress = (userAddress, type) => {
        if (!userAddress) {
            return null;
        }
        if (typeof userAddress === 'string') {
            return userAddress;
        } else {
            let readableAddress = '';
            if (type === addressTypeEnum.FULL) {
                readableAddress += getAddressElement(readableAddress, 'streetNumber', userAddress);
                readableAddress += getAddressElement(readableAddress, 'street', userAddress);
                readableAddress += getAddressElement(readableAddress, 'postalCode', userAddress);
            }
            readableAddress += getAddressElement(readableAddress, 'city', userAddress);
            readableAddress += getAddressElement(readableAddress, 'country', userAddress);
            return readableAddress;
        }
    }

    const getAddressElement = (str, element, add) => {
        return (add[element]) ? (str === '' ? add[element] : `, ${add[element]}`) : '';
    }

    return {getAddress, addressTypeEnum};
}
