import React, {useEffect, useState} from "react";
import Bio from "../../components/PhotographerPage/Bio/Bio";
import BioParagraphs from "../../components/PhotographerPage/Bio/BioParagraphs";
import MostAll from "../../components/PhotographerPage/Most/MostAll";
import MostPopular from "../../components/PhotographerPage/Popular/MostPopular";
import Gallery from "../../components/PhotographerPage/Gallery/Gallery";
import Series from "../../components/PhotographerPage/Series/Series";
import Exhibitions from "../../components/PhotographerPage/Exhibitions/Exhibitions";
import ProfilAgency from "../../components/AgencyPage/ProfilAgency/ProfilAgency";
import {useHttpClient} from '../../hooks/http-hook';
import {config} from '../../environments';
import {Redirect, useLocation} from "react-router-dom";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const PhotographerPage = () => {
    const search = useLocation().search;

    const urlPhotographerId = new URLSearchParams(search).get('photographerId');
    const userId = new URLSearchParams(search).get('userId');

    const [photographer, setPhotographer] = useState();
    const [photographerId, setPhotographerId] = useState();
    const [contactUser, setContactUser] = useState();
    const {sendRequest} = useHttpClient();
    const photographerEndpoint = `${config.baseUrlDev}/photographer/no-control/${photographerId}`;

    const pUserEndpoint = `${config.baseUrlDev}/photographer/user/${userId}`;

    const retrievePhotographerData = async () => {
        return await sendRequest(photographerEndpoint, 'GET')
            .then(async (res) => {
                setPhotographer(res);
                setContactUser(res.userId)
            })
            .catch((e) => setContactUser(null));
    }

    useEffect(() => {
        if (urlPhotographerId) {
            setPhotographerId(urlPhotographerId);
        }
    }, [urlPhotographerId]);

    useEffect(() => {
        if (photographerId) {
            retrievePhotographerData();
        }
    }, [photographerId]);

    useEffect(() => {
        if (userId) {
            sendRequest(pUserEndpoint, 'GET').then((res) => {
                setPhotographer(res);
                setPhotographerId(res._id);
                setContactUser(res.userId)
            })

        }
    }, [userId]);

    return contactUser !== null
        ? (
            <PageLayout>
                <ProfilAgency photographer={photographer} contactUser={contactUser}/>
                <Bio title="Bio">
                    <BioParagraphs description={photographer?.userId?.description}/>
                </Bio>
                <MostPopular photographer={photographer}/>
                <MostAll/>
                {photographerId &&
                <>
                    <Series photographerId={photographerId} titleType={2}/>
                    <Exhibitions type="photographer" itemId={photographerId} titleType={2}/>
                    <Gallery photographer={{...photographer, photographerId}}/>
                </>
                }
            </PageLayout>
        )
        : <Redirect to="/not-found"/>;
};
export default PhotographerPage;
