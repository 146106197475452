import React from 'react';
import {AiFillCaretDown as Caret} from 'react-icons/ai';

const FramingFilter = () => {
    return (
        <div>
            <div className="btn-framing">
                <p className="fr-subtitle">Framing</p>
                <span className="close-up-filt typo-size-smaller">Close up</span>
                <Caret className="framing-caret"/>
            </div>
        </div>
    );
};
export default FramingFilter;
