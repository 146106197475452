import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import Tag from '../../ExplorePage/TagAll/Tag/Tag';
import {IoIosAdd} from 'react-icons/io';

const TagsInput = ({tagsList = [], updateTagsList}) => {
    const [showInput, setShowInput] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    const resetInputTag = () => {
        setCurrentValue('');
        setShowInput(false);
    }

    const handleInputChange = (e) => {
        setCurrentValue(e.target.value);
    }

    const handleRemoveTag = (index) => {
        const tmp = [...tagsList];
        tmp.splice(index, 1);
        updateTagsList(tmp);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleAddTag();
        } else if (e.keyCode === 27) {
            resetInputTag();
        }
    }

    const handleAddTag = () => {
        if (currentValue.trim() !== '') {
            const tmp = [...tagsList];
            tmp.push(currentValue.trim());
            updateTagsList(tmp);
            resetInputTag();
        } else {
            setCurrentValue('');
        }
    }

    return (
        <Box className="display-flex">
            {tagsList.map((tag, k) => <Tag key={k} removeAction={() => handleRemoveTag(k)}>{tag}</Tag>)}
            {showInput
                ? <span className="btn-tag secondary-background">
                <input type="text"
                       style={{border: "none", background: "none"}}
                       placeholder="Custom tag"
                       onKeyDown={handleKeyDown}
                       onChange={handleInputChange}
                       value={currentValue}
                />
                <IoIosAdd onClick={handleAddTag} style={{fontSize: "25px"}}/>
            </span>
                : <span className="btn-tag secondary-background"
                        onClick={() => setShowInput(true)}
                >
                    <IoIosAdd style={{fontSize: "25px"}}/>
                    Add
                </span>
            }
        </Box>
    );
};
export default TagsInput;
