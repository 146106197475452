const EDIT_PHOTO_ENUM = {
    SET_TITLEND: {key: 'titlend', title: 'Title and Description', next: 'SET_CREDITS'},
    SET_CREDITS: {key: 'credits', title: 'Credits', next: 'SET_METADATA', prev: 'SET_TITLEND'},
    SET_METADATA: {key: 'metadata', title: 'Metadata', next: 'SET_SPECIAL', prev: 'SET_CREDITS'},
    SET_SERIES: {key: 'series', title: 'Series', next: 'SET_SPECIAL', prev: 'SET_METADATA'},
    SET_SPECIAL: {key: 'special', title: 'Special instructions', next: 'SET_KEYWORD', prev: 'SET_METADATA'},
    SET_KEYWORD: {key: 'keywords', title: 'Keywords', next: 'SET_AGENCY', prev: 'SET_SPECIAL'},
    SET_AGENCY: {key: 'agency', title: 'Agency', next: 'SET_MARKET', prev: 'SET_KEYWORD'},
    SET_MARKET: {key: 'market', title: 'Market type', next: 'SET_COLORS', prev: 'SET_AGENCY'},
    /*SET_EXHI: {key: 'exhi', title: 'Exhibition page', next: 'SET_COLORS', prev: 'SET_MARKET'},*/
    SET_COLORS: {key: 'colors', title: 'Main colors', prev: 'SET_MARKET'},
    /* TO_IPTC: {key: 'iptc'},
    TO_EXIF: {key: 'exif'},
    VALID_TITLEND: {key: 'titlendValid'},
    VALID_CREDITS: {key: 'creditsValid'} */
}
export default EDIT_PHOTO_ENUM;
