import React from 'react';
import './ExpBoard.css';
import PicturesGrid from "../../Shared/Pictures/PicturesGrid/PicturesGrid";

const ExpBoard = ({images}) => {
    const fakeData = {
        data: [
            {main: 'bg-about1.jpg'},
            {main: 'bg-about2.jpg'},
            {main: 'bg-about3.jpg'},
            {main: 'bg-about4.jpg'},
            {main: 'bg-about5.jpg'},
            {main: 'bg-about6.jpg'},
            {main: 'bg-about7.jpg'},
            {main: 'bg-about8.jpg'},
            {main: 'bg-about9.jpg'},
            {main: 'bg-about10.jpg'},
            {main: 'bg-explore1.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-exploreall.jpg'},
            {main: 'bg-exhi03.jpg'},
            {main: 'bg-exhi04.jpg'},
            {main: 'bg-test.jpg'},
            {main: 'bg-exhi6.jpg'},
            {main: 'bg-stories2.jpg'},
            {main: 'bg-stories4.jpg'},
            {main: 'bg-exhi5.jpg'},
        ]
    };
    return <PicturesGrid title={fakeData.title} data={images}/>;
};
export default ExpBoard;
