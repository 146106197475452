import React, {useContext, useState} from 'react';
import './agency-reviews.scss';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import MenuReviews from '../../components/AgencyAction/AgencyReviews/MenuReviews/MenuReviews';
import {statusEnum} from '../../assets/statics/reviews-step.enum';
import NoImagesGrid from '../../components/Shared/NoImagesGrid/NoImagesGrid';
import SidebarPhotoReview from '../../components/PhotographerAction/PhotographerReviews/PhotographerSidebar/PhotographerSidebar';
import {config} from '../../environments';
import {AuthContext} from '../../context/auth-context';
import {useHttpClient} from '../../hooks/http-hook';
import Toaster from '../../components/Shared/Toaster/Toaster';
import { render } from '@testing-library/react';

const AgencyReviews = () => {
    const {userIdProfile} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();
    const {token} = useContext(AuthContext);

    const retrieveMediaUrl = `${config.baseUrlDev}/images/filter`;

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState();
    const [view, setView] = useState(statusEnum.ACCEPTED);
    const [accepted, setAccepted] = useState([]);
    const [pending, setPending] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [series, setSeries] = useState([]);

    const detailsHandleClick = (item) => {
        setModalImage(item);
        setModal(true);
    }

    const updateRequestStatus = async (requestId, status) => {
        setModal(false);
        const s = status === statusEnum.ACCEPTED ? 'accept' : 'decline';
        const updateImageEndpoint = `${config.baseUrlDev}/images/${requestId}/${s}`;
        await sendRequest(
            updateImageEndpoint,
            'POST',
            null,
            {cookies: token}
        )
            .then(() => {
                reloadAllImages()
                if(s === 'accept') render(<Toaster success toastMsg={`request accepted !`}/>)
                if(s === 'decline') render(<Toaster success toastMsg={`request declined !`}/>)
                
            })
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg={`Something went wrong !`}/>)
            });
    }

    const reloadAllImages = () => {
        retrieveAccepted();
        retrieveDeclined();
        retrievePending();
    }

    const retrieveAccepted = async () => {
        await sendRequest(
            `${retrieveMediaUrl}?agencyId=${userIdProfile}&status=${statusEnum.ACCEPTED}&priceId=false`,
            'GET'
        )
            .then((data) => setAccepted(data))
            .catch((error) => console.warn({error}));
    }

    const retrieveDeclined = async () => {
        await sendRequest(
            `${retrieveMediaUrl}?agencyId=${userIdProfile}&status=${statusEnum.DECLINED}&priceId=false`,
            'GET',
        )
            .then((data) => setDeclined(data))
            .catch((error) => console.warn({error}));
    }

    const retrievePending = async () => {
        await sendRequest(
            `${retrieveMediaUrl}?agencyId=${userIdProfile}&status=${statusEnum.PENDING}&priceId=false`,
            'GET',
        )
            .then((data) => setPending(data))
            .catch((error) => console.warn({error}));
    }

    return (
        <PageLayout>
            <div className='photo-reviews-container'>
                <MenuReviews view={view} setView={setView}/>
                {view === statusEnum.ACCEPTED &&
                <NoImagesGrid className="page-section photos-review"
                              data={accepted}
                              setData={setAccepted}
                              retrieveData={retrieveAccepted}
                              status={statusEnum.ACCEPTED}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                              parentAction={updateRequestStatus}
                />
                }
                {view === statusEnum.DECLINED &&
                <NoImagesGrid className="page-section photos-review"
                              data={declined}
                              setData={setDeclined}
                              retrieveData={retrieveDeclined}
                              status={statusEnum.DECLINED}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                              parentAction={updateRequestStatus}
                />
                }
                {view === statusEnum.PENDING &&
                <NoImagesGrid className="page-section photos-review"
                              data={pending}
                              setData={setPending}
                              retrieveData={retrievePending}
                              status={statusEnum.PENDING}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                              parentAction={updateRequestStatus}
                />
                }
                {view === 'series' &&
                <NoImagesGrid className="page-section photos-review"
                              data={series}
                              setData={setSeries}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                              parentAction={updateRequestStatus}
                />
                }
            </div>
            {modal &&
            <SidebarPhotoReview parentAction={updateRequestStatus} image={modalImage} setModal={setModal}/>
            }
        </PageLayout>
    )
}

export default AgencyReviews
