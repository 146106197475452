import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./navbar.scss";
import {AuthContext} from "../../../../context/auth-context";
import Pixt from "../../../../assets/images/Logo-Small.png";

// Aos
import "aos/dist/aos.css";
import Aos from "aos";

// Icons
import {ShopContext} from "../../../../context/shopContext";
import {BiShoppingBag} from "react-icons/bi";
import {GiHamburgerMenu} from "react-icons/gi";
import {GrClose} from "react-icons/gr";


import {roleEnum} from '../../../../assets/statics/authent-signup.enum';

// Links
import DefaultLinks from './Links/DefaultLinks/DefaultLinks';
import AgencyLinks from './Links/AgencyLinks/AgencyLinks';
import PhotographerLinks from './Links/PhotographerLinks/PhotographerLinks';
import UserLinks from './Links/UserLinks/UserLinks';
import AdminLinks from './Links/AdminLinks/AdminLinks';

const Navbars = () => {
    const auth = useContext(AuthContext);
    const {opencart} = useContext(ShopContext);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        Aos.init({duration: 2000});
    }, []);

    const [dropDown, setDropDown] = useState(false);

    const dropdownHandler = () => {
        setDropDown(!dropDown);
    }

    return (
        <nav data-aos="fade-out"
             className={`navbar ${
                 window.location.pathname === "/login" ||
                 window.location.pathname === "/register"
                     ? 'nav-transparent'
                     : ''
             }`}
        >
            <div className="navbar-content boxed-layout">
                <div className="logo-pixt"
                     onClick={() => setIsOpen(false)}
                >
                    <Link to="/">
                        <img src={Pixt} alt="pixt"/>
                    </Link>
                </div>
                <div className="typo-size-tertiary burger-btn" style={{display: "flex", alignItems: "center"}}
                     onClick={() => setIsOpen(!isOpen)}>
                    {isOpen
                        ? <GrClose className="close-resp"/>
                        : <GiHamburgerMenu className="open-resp"/>
                    }
                </div>
                <ul className={auth.userType !== roleEnum.ADMIN ? `box-links agency ${isOpen ? 'open-mobile-menu' : ''}` : `box-links ${isOpen ? 'open-mobile-menu' : ''}`}>
                    {!auth.isLoggedIn && (
                        <DefaultLinks/>
                    )}

                    {auth.userType === roleEnum.AGENCY && (
                        <AgencyLinks dropDown={dropDown}
                                     dropdownHandler={dropdownHandler}
                        />
                    )}

                    {auth.userType === roleEnum.PHOTOGRAPHER && (
                        <PhotographerLinks dropDown={dropDown}
                                           dropdownHandler={dropdownHandler}
                        />
                    )}

                    {(auth.userType === roleEnum.USER || auth.userType === roleEnum.MEDIA_USER) && (
                        <UserLinks dropDown={dropDown}
                                   dropdownHandler={dropdownHandler}
                        />
                    )}

                    {auth.userType === roleEnum.ADMIN && (
                        <AdminLinks dropDown={dropDown}
                                    dropdownHandler={dropdownHandler}
                        />
                    )}
                    <div className="box-cart" onClick={opencart}>
                        <BiShoppingBag className="bag"/>
                    </div>
                </ul>
            </div>
        </nav>
    );
};

export default Navbars;
