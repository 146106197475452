import React from "react";
import "./BtnTag.css";

const BtnTag = (props) => {
  return (
    <>
      <div
        className={`btn-tag1 ${props.className}`}
        onClick={props.onClick}
        style={{
          background: props.bg,
          color: props.color,
          width: props.width,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin,
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default BtnTag;
