import React from 'react';
import ImagesSelectionDialog from '../ImagesSelectionDialog/ImagesSelectionDialog';
import SimpleImageGrid from '../../../Shared/SimpleImageGrid/SimpleImageGrid';

const ImagesManagement = ({isOpen, handleClose, selectedImages, toggleImage, photographerAgencyImages}) => {
    return (
        <>
            <ImagesSelectionDialog handleClose={handleClose}
                                   isOpen={isOpen}
                                   selectedImages={selectedImages}
                                   toggleImage={toggleImage}
                                   photographerAgencyImages={photographerAgencyImages}
            />
            <SimpleImageGrid images={selectedImages}
                             mode="show"
                             selectedImages={selectedImages}
                             toggleImage={toggleImage}
            />
        </>
    )
}
export default ImagesManagement;
