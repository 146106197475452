import React, {useContext, useEffect, useState} from 'react';
import PicturesGrid from "../../Shared/Pictures/PicturesGrid/PicturesGrid";
import {config} from '../../../environments';
import {useHttpClient} from '../../../hooks/http-hook';
import {AuthContext} from '../../../context/auth-context';
import {statusEnum} from '../../../assets/statics/reviews-step.enum';

const Gallery = ({photographer}) => {
    const {sendRequest} = useHttpClient();
    const {token} = useContext(AuthContext);

    const [images, setImages] = useState([]);

    const retrieveAllImages = async () => {
        if (photographer) {
            const url = `${config.baseUrlDev}/images/filter?status=${statusEnum.ACCEPTED}&photographerId=${photographer.photographerId}`
            await sendRequest(
                url,
                'GET',
                null,
                {cookies: token}
            )
                .then((data) => setImages(shuffleArray(data)))
                .catch((e) => console.warn({e}));
        }
    }

    useEffect(() => retrieveAllImages(), []);

    const fakeData = {
        title: 'Gallery',
        data: [
            {main: 'bg-test.jpg'},
            {main: 'bg-test2.jpg'},
            {main: 'bg-explore1.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore3.jpg'},
            {main: 'bg-explore4.jpg'},
            {main: 'bg-explore5.jpg'},
            {main: 'bg-explore6.jpg'},
            {main: 'bg-explore7.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore3.jpg'},
            {main: 'bg-explore6.jpg'},
            {main: 'bg-test2.jpg'},
            {main: 'bg-test.jpg'},
            {main: 'bg-test2.jpg'},
            {main: 'bg-explore1.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore3.jpg'},
            {main: 'bg-explore4.jpg'},
            {main: 'bg-explore5.jpg'},
            {main: 'bg-explore6.jpg'},
            {main: 'bg-explore7.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore3.jpg'},
            {main: 'bg-explore6.jpg'},
            {main: 'bg-test2.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore4.jpg'},
            {main: 'bg-test.jpg'}
        ]
    };
    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };
    return <PicturesGrid title={fakeData.title} data={images.length > 0 ? images : shuffleArray(fakeData.data)}/>;
};
export default Gallery;
