import React, {useContext, useEffect, useState} from 'react';
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../../assets/statics/cloud-image';
import pin from '../../../../../assets/images/pin.png';
import PrimaryBtn from '../../../Buttons/Template/PrimaryBtn';
import {FaThumbtack} from 'react-icons/fa';
import AddToMoodboard from '../../../PinDropdowns/AddToMoodboard/AddToMoodboard';
import {Link} from 'react-router-dom';
import SecondaryBtn from '../../../Buttons/Template/SecondaryBtn';
import {useHttpClient} from '../../../../../hooks/http-hook';
import {config} from '../../../../../environments';
import {AuthContext} from '../../../../../context/auth-context';
import {roleEnum} from '../../../../../assets/statics/authent-signup.enum';
import TertiaryBtn from '../../../Buttons/Template/TertiaryBtn';

const PicturesGridItem = (props) => {
    const {
        k,
        showPin,
        currentPin,
        picture,
        handleImageOnClick,
        displayMode,
        handleAddCart,
    } = props;

    const {userIdProfile, userType} = useContext(AuthContext);
    const [photographer, setPhotographer] = useState();

    const {sendRequest} = useHttpClient();

    useEffect(async () => {
        if (!photographer) {
            if (picture?.photographer?._id) {
                await sendRequest(`${config.baseUrlDev}/photographer/no-control/${picture?.photographer?._id}`, 'GET')
                    .then((res) => setPhotographer(res))
                    .catch((e) => console.warn({e}));
            } else if (picture?.photographer) {
                await sendRequest(`${config.baseUrlDev}/photographer/no-control/${picture?.photographer}`, 'GET')
                    .then((res) => setPhotographer(res))
                    .catch((e) => console.warn({e}));
            }
        }
    }, [picture]);

    return (
        <BackgroundImg
            className="grid-item"
            src={cloudimageConfig.baseURL + (picture.main ? picture.main : picture.fileName)}
            onClick={(event) => handleImageOnClick(event, picture.main ? picture.main : picture.fileName)}
        >
            {displayMode !== 'edition' &&
            <>
                <div className="btns-top">
                    <div className="pin-container">
                        <img className="pin" src={pin} alt="pin-pixt"/>
                    </div>
                    <div className="btns-container">
                        <div className="tag primary" style={{color: "#ffe942", height: "36px"}}>
                            &copy; Licence
                        </div>
                        {(userType === roleEnum.PHOTOGRAPHER && userIdProfile === picture?.photographer) &&
                        <>
                            <PrimaryBtn>
                                <FaThumbtack onClick={() => showPin(k)}/>
                            </PrimaryBtn>
                            {currentPin === `num${k}` &&
                            <AddToMoodboard k={k}
                                            agencyId={picture?.agency?._id}
                                            picture={picture}
                            />
                            }
                        </>
                        }
                    </div>
                </div>
                <div className="btns-bottom">
                    <div className="agency-credits">
                        <div className="agency">
                            <p className="credit-label typo-size-smaller">Agency</p>
                            <Link
                                to={`/agency/details/${picture?.agency ? picture?.agency?._id : '605b6e1977600ef820c6781f'}`}
                                style={{textDecoration: "none"}}
                            >
                                <p className="credit-value typo-size-small">{picture?.agency?.companyName ? picture?.agency?.companyName : 'Studio 550'}</p>
                            </Link>
                        </div>
                        <div className="credits">
                            <p className="credit-label typo-size-smaller">Credits</p>
                            <Link
                                to={`/photographer/details?photographerId=${photographer ? photographer?._id : '605c65c242af9d16facefb6b'}`}
                                style={{textDecoration: "none"}}
                            >
                                <p className="credit-value typo-size-small">
                                    {picture?.fullName
                                        ? picture?.fullName
                                        : photographer?.userId
                                            ? `${photographer?.userId?.firstName} ${photographer?.userId?.lastName}`
                                            : 'Andrea Bruce'}
                                </p>
                            </Link>
                        </div>
                    </div>
                    <div className="btns-container">
                        {picture?._id &&
                        <a href={`/image/details/${picture?._id}`}>
                            <TertiaryBtn className="btn-default-width btn-default-height">
                                View details
                            </TertiaryBtn>
                        </a>
                        }
                        <SecondaryBtn className="btn-default-width btn-default-height"
                                      style={{marginTop: "0.5rem"}}
                                      parentAction={() => {
                                          return (picture?._id && picture?.priceId)
                                              ? handleAddCart(
                                                  picture._id,
                                                  picture.priceId,
                                              )
                                              : null;
                                      }}>
                            Add to cart
                        </SecondaryBtn>
                    </div>
                </div>
            </>
            }
        </BackgroundImg>
    );
};
export default PicturesGridItem;
