import React, {useEffect, useState} from 'react';
import './PhotographersCard.scss';
import {IoLocationOutline} from 'react-icons/io5';
import {AiOutlinePlusCircle as PlusIcon} from 'react-icons/ai';
import {config} from '../../../../environments';
import {useHttpClient} from '../../../../hooks/http-hook';
import {useHistory} from 'react-router-dom';
import Img from 'react-cloudimage-responsive';
import useReadableAddress from '../../../../hooks/useReadableAddress';

const PhotographersCard = (props) => {
    const {sendRequest} = useHttpClient();
    const [photographer, setPhotographer] = useState();
    const history = useHistory();
    const {getAddress, addressTypeEnum} = useReadableAddress();

    useEffect(() => {
            if (props.id) {
                retrievePhotographerData();
            }
        }, [props.id]
    )

    const retrieveData = async (endpoint, callback) => {
        return await sendRequest(
            endpoint, 'GET', null, {'Content-Type': 'application/json'}
        )
            .then((data) => callback(data))
            .catch((e) => console.warn({e}));
    }

    const retrievePhotographerData = async () => {
        const photographerEndpoint = `${config.baseUrlDev}/photographer/no-control/${props.id}`;
        retrieveData(photographerEndpoint, setPhotographer);
    }

    return (
        props.id && photographer
            ? (
                <div className="box-banderole-card photographers-card">
                    {photographer?.userId?.avatarFileUrl
                        ? <Img className="image-photo" width={272} height={272}
                               src={photographer?.userId?.avatarFileUrl}
                               style={{width: "272px", height: "272px"}}
                        />
                        : <div style={{width: "272px", height: "272px", minHeight: "272px", background: "#f3f5f7"}}/>
                    }
                    <div className="content-photographer">
                        <p className="phantom-bold" style={{marginBottom: "0"}}>
                            {photographer
                                ? `${photographer?.userId?.firstName} ${photographer?.userId?.lastName}`
                                : props.title
                            }
                        </p>
                        {photographer &&
                        <p>/ {photographer?.companyName}</p>
                        }
                        {(photographer?.userId?.address || props.address) &&
                        <div className="content-localisation">
                            <div className="icon-localisation"><IoLocationOutline/></div>
                            <p className="typo-size-small" style={{marginBottom: "0", marginLeft: "0.5rem"}}>
                                Currently in<br/>{photographer ? getAddress(photographer?.userId?.address, addressTypeEnum.SHORT) : getAddress(props.address, addressTypeEnum.SHORT)}
                            </p>
                        </div>
                        }
                        <div className="more-plus"
                             onClick={() => history.push(`/photographer/details?photographerId=${photographer._id}`)}>
                            <p style={{marginBottom: "0"}}>More</p>
                            <PlusIcon className="icon-plus"/>
                        </div>
                    </div>
                </div>
            )
            : <div/>
    )
};
export default PhotographersCard;
