import React, {useContext, useEffect, useState} from "react";
import "./GetPreparedRight.scss";
import ImageInfo from '../../ImageInfo/ImageInfo';
import ContactBtn from '../../../../Shared/Buttons/ContactBtn/ContactBtn';
import PrimaryBtn from '../../../../Shared/Buttons/Template/PrimaryBtn';
import {ShopContext} from '../../../../../context/shopContext';
import {AuthContext} from '../../../../../context/auth-context';
import TertiaryBtn from '../../../../Shared/Buttons/Template/TertiaryBtn';
import {findLicenceLabel} from '../../../../../assets/statics/search-params.enum';

const GetPreparedRight = ({image, photographer}) => {
    const {addItemToCheckout, opencart} = useContext(ShopContext);
    const {token} = useContext(AuthContext);

    const [metadata, setMetadata] = useState({});

    const handleAddCart = (id, priceId) => {
        addItemToCheckout(id, priceId, token);
        opencart();
    };

    useEffect(() => {
        if (image?.EXIFmetadata && !Array.isArray(image?.EXIFmetadata)) {
            setMetadata(image.EXIFmetadata);
        }
    }, [image]);

    return (
        <div className="get-prepare-right double-item gl-col-6">
            <div className="container-gtprepr">
                <div className="box-gtprepr">
                    <div className="btn-container">
                        <ContactBtn email={photographer?.email ? photographer?.email : photographer?.userId?.email}
                        >
                            <TertiaryBtn className="btn-full-width wired">
                                Contact Me
                            </TertiaryBtn>
                        </ContactBtn>
                        <PrimaryBtn className="btn-full-width"
                                    parentAction={() => {
                                        return (image?._id && image?.priceId)
                                            ? handleAddCart(
                                                image._id,
                                                image.priceId,
                                            )
                                            : null;
                                    }}
                        >
                            Add to cart
                        </PrimaryBtn>
                    </div>
                    <div className="details">
                        <ImageInfo className="credits-data" label="Credit" value={image?.credit}
                                   link={image?.photographer}
                        />
                        <ImageInfo className="credits-data" label="Date" value="12-06-2021"/>
                        <ImageInfo className="credits-data" label="Photo Agency"
                                   agencyId={image?.agency?._id}
                                   value={image?.agency?.companyName ? image?.agency?.companyName : '5D Studio'}
                        />
                        <ImageInfo className="credits-data" label="Description" value={image?.description}/>

                        <ImageInfo className="credits-data"
                                   label="Licence type"
                                   value={image?.licence ? findLicenceLabel(image?.licence) : 'Editorial, Art Market and Corporate'}
                        />

                        <div className="box-exifs credits-data">
                            <h6>Exifs</h6>
                            <div className="exifs-container">
                                {metadata && Object.keys(metadata).length > 0 && Object.keys(metadata)
                                    .map((key, k) => {
                                        const mdK = metadata[key];
                                        return (mdK && typeof mdK !== 'string' && !Array.isArray(mdK))
                                            ? Object.keys(mdK).map((label, k) => (
                                                <ImageInfo key={k}
                                                           secondary
                                                           label={label}
                                                           value={mdK[label]}
                                                           className="exifs-data"
                                                />
                                            ))
                                            : <ImageInfo key={k}
                                                         secondary
                                                         label={key}
                                                         value={Array.isArray(mdK) ? mdK.join(', ') : mdK}
                                                         className="exifs-data"
                                            />;
                                    })
                                }
                                {metadata && Object.keys(metadata).length === 0 &&
                                <p>No metadata</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetPreparedRight;
