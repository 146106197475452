import React, {useEffect, useState} from 'react';
import {AiFillCaretDown as Caret, AiFillCaretRight as RightCaret} from 'react-icons/ai';
import {findLicenceLabel, searchParamsEnum} from '../../../../assets/statics/search-params.enum';

const InputLicence = ({urlLicences = [], updateSearchValues}) => {
    const [showDd, setShowDd] = useState(false);
    const [licence, setLicence] = useState(urlLicences);

    const handleLicenceVal = (event) => {
        const v = event.target.getAttribute('data-value');
        const l = [...licence];
        if (l.includes(v)) {
            l.splice(l.indexOf(v), 1);
        } else {
            l.push(v);
        }
        setLicence(l);
    };

    useEffect(() => {
        updateSearchValues({licences: licence});
    }, [licence]);

    useEffect(() => {
        if (licence !== urlLicences) {
            setLicence(urlLicences);
        }
    }, [urlLicences]);

    return (
        <div className="search-exp-licence search-custom-dropdown search-bar-item"
             onClick={() => setShowDd(!showDd)}
        >
            <span className="search-input-data">Licence</span>
            {licence.map((l) => findLicenceLabel(l)).join(', ')}
            {showDd ? (
                <Caret className="search-caret"/>
            ) : (
                <RightCaret className="search-caret"/>
            )}
            {showDd && (
                <div className="search-dropdown">
                    {Object.keys(searchParamsEnum).map((kw, k) => (
                        <span key={k} onClick={handleLicenceVal} data-value={searchParamsEnum[kw].key}>
                            {searchParamsEnum[kw].label}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};
export default InputLicence;
