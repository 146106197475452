import React from 'react'
import './MostBoxs.css'


const MostBoxs = (props) => {
    return (
        <>
            <div className="box-mostbox gl-col-3">
                <h5 className="grifitol-bold"
                    style={{height: "75px", display: "flex", alignItems: "flex-end"}}
                >
                    Most {props.titleh2}
                </h5>
                <div className="box-most-values">
                    <div className="number-mostbox">{props.number1}</div>
                    <div className="title-mostbox-stat">{props.title1}</div>
                    <div className="credits-title-stat">{props.credits}</div>
                    <div className="credits-value-stat">{props.valuecredits}</div>
                </div>
            </div>
        </>
    )
}

export default MostBoxs
