import React from 'react'
import Footer from '../../components/Shared/PageLayout/Footer/Footer'
import MicroPayment1 from '../../components/CheckoutAction/MicroPayment1/MicroPayment1'
import Navbars from '../../components/Shared/PageLayout/Navbar/navbar'

const MicroPaymentPage1 = () => {
    return (
        <>
            <Navbars />
            <MicroPayment1 />
            <Footer/>
        </>
    )
}

export default MicroPaymentPage1
