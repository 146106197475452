import React from 'react'
import './Container500.css'

const Container500 = (props) => {
    return (
        <>
            <div className="container500">
                {props.children}
            </div>
        </>
    )
}

export default Container500
