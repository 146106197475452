import React, { useEffect } from "react";
import "./Toaster.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cleanup } from "@testing-library/react";

const Toaster = (props) => {
  const notifyErr = () => toast.error(props.toastMsg, {toastId: props.id, className: props.classNameToast, autoClose: 2700});
  const notifyInfo = () => toast.info(props.toastMsg, {toastId: props.id, className: props.classNameToast, autoClose: 2700});
  const notifyWarn = () => toast.warn(props.toastMsg, {toastId: props.id, className: props.classNameToast, autoClose: 2700});
  const notifySuccess = () => toast.success(props.toastMsg, {toastId: props.id, className: props.classNameToast, autoClose: 2700});
  const notifyDark = () => toast.dark(props.toastMsg, {toastId: props.id, className: props.classNameToast, autoClose: 2700});

  useEffect(() => {
      if(props.error) notifyErr()
      if(props.info) notifyInfo()
      if(props.warn) notifyWarn()
      if(props.success) notifySuccess()
      if(props.dark) notifyDark()

      const timer = setTimeout(() => {
        cleanup()
      }, 3500);
      return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div>
        <ToastContainer limit={3}  />
      </div>
    </>
  );
};

export default Toaster;
