import React, {useContext, useEffect, useState} from 'react';
import Header from "../../Shared/Header/Header";
import Banderole from "../../Shared/Banderole/Banderole";
import {AuthContext} from '../../../context/auth-context';
import {config} from '../../../environments';
import {useHttpClient} from '../../../hooks/http-hook';
import { render } from "@testing-library/react";
import Toaster from '../../Shared/Toaster/Toaster';

const AdminMediaUsers = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);
    const {token} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();
    const parentId = "admin-media-users-banderole";
    const [mediaUsers, setMediaUsers] = useState([]);

    const retrieveAllMedia = async () => {
        const url = `${config.baseUrlDev}/media/all`;
        await sendRequest(url, 'GET', null, {'Content-Type': 'application/json', cookies: token})
            .then((res) => setMediaUsers(res))
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg="Unexpected error !"/>)
            });
    }

    const defaultTitle = 'Latest Media users';

    useEffect(() => {
        if (token) {
            retrieveAllMedia()
        }
    }, [token]);

    return (
        <section className="boxed-layout page-section" id={parentId}>
            <Header title={defaultTitle} titleType={props.titleType} parentId={parentId}
                    setControls={{setLeftNav, setRightNav}} showControls={{leftNav, rightNav}} />
            <Banderole type="media-users" medias={mediaUsers} parentId={parentId} />
        </section>
    )
}
export default AdminMediaUsers;
