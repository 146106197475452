import React, {useEffect, useState} from 'react';
import './InputTerms.scss';
import {AiOutlineSearch as Search} from 'react-icons/ai';
import Tag from '../../TagAll/Tag/Tag';

const InputTerms = ({terms, updateSearchValues}) => {
    const [currentValue, setCurrentValue] = useState('');

    const handleInputChange = (e) => {
        setCurrentValue(e.target.value);
    }

    const handleRemoveTerm = (index) => {
        const t = [...terms];
        t.splice(index, 1);
        updateSearchValues({terms: t});
    }

    const addTerm = () => {
        if (currentValue.trim() !== '') {
            const t = [...terms];
            t.push(currentValue.trim());
            updateSearchValues({terms: t});
            setCurrentValue('');
        }
    }

    const handleKeyDown = (e) => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            addTerm();
        }
        if (keyCode === 8 && currentValue === '') {
            const t = [...terms];
            t.pop();
            updateSearchValues({terms: t});
        }
    }

    useEffect(() => {
        if (currentValue.slice(-1) === ' ') {
            addTerm();
        }
    }, [currentValue]);

    return (
        <div className="search-exp-term search-bar-item">
            <label htmlFor='search-input'>
                <Search style={{margin: "0 0.5rem"}}/>
            </label>
            {terms.length !== 0 &&
            <div className="display-flex" style={{maxWidth: "100px", overflowX: "scroll"}}>
                {terms.map((t, k) => (
                    <Tag style={{minWidth: "max-content"}} key={k} removeAction={() => handleRemoveTerm(k)}>
                        {t}
                    </Tag>
                ))}
            </div>
            }
            <input id="search-input"
                   type="text"
                   className="search-input"
                   placeholder="Term"
                   value={currentValue}
                   onKeyDown={handleKeyDown}
                   onChange={handleInputChange}
            />
        </div>
    );
}
;
export default InputTerms;
