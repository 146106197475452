import React from "react";
import "./Order.css";

import Label from "../../Shared/Forms/Input/Label/Label";
import BtnClassic from "../../Shared/Buttons/BtnClassic/BtnClassic";
import ContainerDirection from "../../Shared/Containers/ContainerRow/ContainerDirection";

const Order = () => {
  return (
    <>
      <div className="box-cont">
        <div className="box-l">
          <div className="box-list">Purchase</div>
          <div className="box-liste">Details</div>
          <ContainerDirection
            width="799px"
            height="450px"
            border="1px solid #9BA5B1"
          >
            <ContainerDirection
              width="50%"
              height="100%"
              direction="column"
              bg="white"
              padding="20px 0 10px 20px"
            >
              <div className="box-order"></div>
              <BtnClassic width="100%" bg="#f3f5f7" color="#575F68">
                View Licence
              </BtnClassic>
            </ContainerDirection>
            <ContainerDirection
              width="50%"
              height="100%"
              direction="column"
              padding="20px"
            >
              <h3>Price</h3>
              <div className="value-order">$1000</div>
              <br />
              <h3>Credit</h3>
              <div className="value-order">Andrea Bruce.</div>
              <br />
              <h3>Exifs</h3>
              <div className="box-indent">
                <ContainerDirection
                  width="100%"
                  height="30px"
                  align="center"
                  justify="center"
                  className="type-im"
                >
                  <h3>Type</h3>
                  <h4>image/jpeg</h4>
                </ContainerDirection>
                <ContainerDirection
                  width="100%"
                  height="30px"
                  align="center"
                  justify="center"
                  className="type-i"
                >
                  <h3>Size</h3>
                  <h4>11.56MB</h4>
                </ContainerDirection>
                <ContainerDirection
                  width="100%"
                  height="30px"
                  align="center"
                  justify="center"
                  className="type-imo"
                >
                  <h3>Dimensions</h3>
                  <h4>5041x9060</h4>
                </ContainerDirection>
                <ContainerDirection
                  width="100%"
                  height="30px"
                  align="center"
                  justify="center"
                  className="type-ime"
                >
                  <h3>DPI</h3>
                  <h4>300</h4>
                </ContainerDirection>
                <ContainerDirection
                  width="100%"
                  height="30px"
                  align="center"
                  justify="center"
                  className="type-imp"
                >
                  <h3>Camera</h3>
                  <h4>Canon 5D</h4>
                </ContainerDirection>
              </div>
            </ContainerDirection>
          </ContainerDirection>
        </div>

        <div className="box-r">
          <div className="box-create">
            <Label className="label-term">Subtotal</Label>
            <Label className="label-t"> $1000</Label>
            <BtnClassic className="btncl" width="350px">
              Purchase
            </BtnClassic>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
