import React, {useContext, useEffect, useState} from 'react';
import './Image.scss';
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import {AuthContext} from '../../../../context/auth-context';
import {AiFillCloseCircle} from 'react-icons/ai';

const Image = ({imageId, className, parentAction, mode, isPhotographer}) => {
    const {token} = useContext(AuthContext);
    const [img, setImg] = useState();
    const {sendRequest} = useHttpClient();
    const imgUrl = `${config.baseUrlDev}/images`;

    useEffect(async () => {
        if (imageId) {
            await sendRequest(`${imgUrl}/${imageId}`, 'GET', null, {cookies: token})
                .then((res) => setImg(res.data))
                .catch((e) => console.warn({e}));
        }
    }, [imageId])

    return (
        <BackgroundImg className={`simple-image ${isPhotographer ? mode : ''} ${className ? className : ''}`}
                       src={cloudimageConfig.baseURL + img?.fileName}
                       onClick={() => parentAction ? parentAction(img._id) : null}
        >
            {(mode === 'show' && isPhotographer) &&
            <AiFillCloseCircle style={{fontSize: "3rem", color: "#ffe942"}}
                               onClick={() => parentAction ? parentAction(img._id) : null}
            />
            }
        </BackgroundImg>
    );
};
export default Image;
