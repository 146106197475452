import React, {useState} from "react";
import "./ProfilAgency.scss";
import {BsBoxArrowUpRight} from "react-icons/bs";
import {IoLocationOutline} from "react-icons/io5";
import {BiShareAlt} from "react-icons/bi";
import ContactBtn from '../../Shared/Buttons/ContactBtn/ContactBtn';
import TertiaryBtn from '../../Shared/Buttons/Template/TertiaryBtn';
import PrimaryBtn from '../../Shared/Buttons/Template/PrimaryBtn';
import useReadableAddress from '../../../hooks/useReadableAddress';

const ProfilAgency = ({agency, contactUser, photographer}) => {
    const [showMenu, setShowMenu] = useState(false);
    const {getAddress, addressTypeEnum} = useReadableAddress();

    return (
        <section className="section-profil-agency page-section boxed-layout">
            <div className="side-container container-left">
                <h1 className="typo-size-bigger">
                    {agency && agency?.companyName}
                    {(contactUser && !agency) && `${contactUser?.firstName} ${contactUser?.lastName}`}
                </h1>
                <div className="data-box-container">
                    <div className="data-box">
                        <h6 className="typo-size-smaller">Place</h6>
                        <div className="info-btn">
                            <IoLocationOutline/>
                            {contactUser?.address ? getAddress(contactUser?.address, addressTypeEnum.SHORT) : '1781 Udaosu Heights'}
                        </div>
                    </div>

                    <div className="data-box">
                        <h6 className="typo-size-smaller">Website</h6>
                        <div className="info-btn third">
                            {photographer?.companyName ? photographer?.companyName : '5D Studio'}
                        </div>
                    </div>
                </div>

                <div className="action-container">
                    <ContactBtn email={contactUser?.email ? contactUser?.email : photographer?.email}>
                        <PrimaryBtn>
                            Contact us
                        </PrimaryBtn>
                    </ContactBtn>

                    <TertiaryBtn className="wired" parentAction={() => setShowMenu(!showMenu)}>
                        Follow {agency ? 'Us' : 'Me'}
                        <BiShareAlt style={{marginLeft: "8px"}}/>
                        {showMenu && (
                            <div className="drop-downshare">
                                <div> Instagram</div>
                                <div> Medium</div>
                                <div> Facebook</div>
                                <div> Twitter</div>
                            </div>
                        )}
                    </TertiaryBtn>
                </div>
            </div>
            <div className="side-container container-right vertical-content-centered">
                <div className="profil-grey-agency"
                     style={!agency ? {backgroundImage: `url(${contactUser?.avatarFileUrl})`} : {}}>
                    {agency &&
                    <div className="logo-agency"
                         style={{backgroundImage: `url(${contactUser?.avatarFileUrl})`}}
                    />
                    }
                </div>
            </div>
        </section>
    );
};
export default ProfilAgency;
