import React, {useContext, useEffect, useRef, useState} from "react";
import ContainerDirection from "../Containers/ContainerRow/ContainerDirection";
import LineBlack from "../Line/LineBlack";
import PersonalInfos from "./PersonalInfos/PersonalInfos";
import "./ProfileSettings.scss";
import SettingContact from "./SettingContact/SettingContact";
import SettingPassword from "./SettingPassword/SettingPassword";
import AgencySetting from "./AgencySetting/AgencySetting";
import {useHttpClient} from "../../../hooks/http-hook";
import {AuthContext} from "../../../context/auth-context";
import {config} from "../../../environments";
import {roleEnum} from "../../../assets/statics/authent-signup.enum";
import BioDescription from "./BioDescription/BioDescription";
import LocationSetting from "./LocationSetting/LocationSetting";
import AvatarSetting from "./AvatarSetting/AvatarSetting";
import Toaster from "../Toaster/Toaster";
import {render} from "@testing-library/react";

const ProfileSettings = () => {
    const enumMenu = {
        PERSONAL_INFOS: "Personal Infos",
        LOCATION: "Location",
        PASSWORD: "Password",
        CONTACT: "Contact",
        AGENCY: "Agency",
    };

    const {sendRequest} = useHttpClient();
    const {
        token,
        user,
        userId,
        userType,
        updatePersonalInfos,
        retrievePersonalInfos,
    } = useContext(AuthContext);
    const [agency, setAgency] = useState(false);
    const [currentStep, setCurrentStep] = useState(enumMenu.PERSONAL_INFOS);

    const bioDescriptionRef = useRef();

    const [ownedAgency, setOwnedAgency] = useState();

    const fetchOwnedAgency = async () => {
        const url = `${config.baseUrlDev}/agency/user/${userId}`;
        return await sendRequest(url, "GET", null, {
            "Content-Type": "application/json",
        })
            .then((data) => setOwnedAgency(data))
            .catch((e) => {
                console.warn({e});
                render(<Toaster warn toastMsg="No agency affiliate !"/>);
            });
    };

    useEffect(() => {
        if (userType === roleEnum.AGENCY && userId) {
            fetchOwnedAgency();
        }
    }, [userType]);

    const updateUserInfos = async (body, callback, type, errorCallback) => {
        const headers = {cookies: token};
        let requestBody = body;
        if (type !== "avatar") {
            if (bioDescriptionRef.current) {
                body.description = bioDescriptionRef.current.value;
            }
            headers["Content-Type"] = "application/json";
            requestBody = JSON.stringify(requestBody);
        }

        updatePersonalInfos(requestBody, headers, callback, (error) => {
            retrievePersonalInfos();
            render(<Toaster error toastMsg="An error occured !"/>);
            return errorCallback ? errorCallback(error) : null;
        });
    };

    useEffect(() => {
        if (userId) {
            retrievePersonalInfos();
        }
    }, [userId]);

    return (
        <ContainerDirection width="100%" height="80vh" padding="80px 50px">
            <div className="settings-left" style={{paddingTop: "20px"}}>
                <h5>Profile Settings</h5>
                <ul>
                    {Object.keys(enumMenu).map((item, k) => (
                        (enumMenu[item] === enumMenu.AGENCY && (userType === roleEnum.USER || userType === roleEnum.MEDIA_USER))
                            ? <></>
                            : (
                                <li className={currentStep === enumMenu[item] ? `is-active` : ''}
                                    key={k}
                                    onClick={() => setCurrentStep(enumMenu[item])}
                                >
                                    {enumMenu[item]}
                                </li>
                            )
                    ))}
                </ul>
            </div>
            <div className="settings-middle">
                {(currentStep === enumMenu.PERSONAL_INFOS) &&
                <PersonalInfos firstName={user?.firstName}
                               lastName={user?.lastName}
                               job={user?.job}
                               parentAction={updateUserInfos}
                />}
                {(currentStep === enumMenu.LOCATION) &&
                <LocationSetting parentAction={updateUserInfos}
                                 address={user?.address}
                />}
                {(currentStep === enumMenu.PASSWORD) &&
                <SettingPassword parentAction={updateUserInfos}/>
                }
                {(currentStep === enumMenu.CONTACT) &&
                <SettingContact email={user?.email}
                                phoneNumber={user?.phoneNumber}
                                updateUserInfos={updateUserInfos}
                                parentAction={updateUserInfos}
                />}
                {(currentStep === enumMenu.AGENCY) &&
                <AgencySetting modal={agency} setModal={setAgency}/>
                }
            </div>
            <div className="settings-right">
                <LineBlack/>
                <div className="box-set">
                    <div className="right-box-content">
                        <div className="avatar-container">
                            <AvatarSetting token={token}
                                           userId={userId}
                                           userType={userType}
                                           user={user}
                                           parentAction={updateUserInfos}
                            />
                        </div>
                        {(userType === roleEnum.AGENCY || userType === roleEnum.PHOTOGRAPHER) &&
                        <div className="bottom-box-set">
                            {userType === roleEnum.AGENCY
                                ? <h4>{ownedAgency?.companyName}</h4>
                                : <h4>{user?.firstName} {user?.lastName}</h4>
                            }
                            <h5>
                                {userType === roleEnum.AGENCY
                                    ? "Description"
                                    : userType === roleEnum.PHOTOGRAPHER
                                        ? "Bio"
                                        : ""
                                }
                            </h5>
                            {(userType === roleEnum.AGENCY || userType === roleEnum.PHOTOGRAPHER) && (
                                <BioDescription description={user?.description}
                                                parentAction={updateUserInfos}
                                                parentRef={bioDescriptionRef}
                                />
                            )}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </ContainerDirection>
    );
};
export default ProfileSettings;
