import React, {useEffect, useState} from 'react';
import './PurchaseConfirmation.scss';
import Img from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';
import {useHistory} from 'react-router-dom';
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import {statusSubtitle, statusTitle, stripePaymentStatus} from '../../../../assets/statics/checkout-verification.enum';
import SecondaryBtn from '../../../Shared/Buttons/Template/SecondaryBtn';

const PurchaseConfirmation = ({parentAction}) => {
    const {sendRequest} = useHttpClient();
    const history = useHistory();

    const [isSuccess, setIsSuccess] = useState();
    const [title, setTitle] = useState(statusTitle.PENDING);
    const [subtitle, setSubtitle] = useState(statusSubtitle.PENDING);
    const [checkoutData, setCheckoutData] = useState();

    const getRequest = async (url, callback) => {
        await sendRequest(url, 'GET')
            .then((getData) => callback(getData))
            .catch((e) => console.warn({e}));
    }

    const resetCheckoutData = () => {
        localStorage.removeItem('pixt_checkout_data');
        localStorage.removeItem('pixt_checkout_status');
    }

    const handleSuccess = async () => {
        localStorage.setItem('pixt_checkout_status', stripePaymentStatus.PAID);
        setTitle(statusTitle.PAID);
        setSubtitle(statusSubtitle.PAID);
    }

    const handleFail = () => {
        localStorage.setItem('pixt_checkout_status', stripePaymentStatus.UNPAID);
        setTitle(statusTitle.UNPAID);
        setSubtitle(statusSubtitle.UNPAID);
    }

    useEffect(async () => {
        localStorage.removeItem('pixt_checkout_line_items');

        const checkoutData = localStorage.getItem('pixt_checkout_data');

        if (!checkoutData) {
            resetCheckoutData();
            return history.push('/');
        }

        try {
            const dataParsed = JSON.parse(checkoutData);
            localStorage.removeItem('pixt_checkout_line_items');
            setCheckoutData(dataParsed);
        } catch (e) {
            resetCheckoutData();
            return history.push('/');
        }
    }, []);

    useEffect(() => {
        if (checkoutData?.id) {
            getRequest(`${config.baseUrlDev}/stripe/checkout/${checkoutData.id}`, (resData) => {
                if (resData?.payment_status === stripePaymentStatus.PAID) {
                    setTimeout(() => {
                        setIsSuccess(true);
                    }, 2000);
                    parentAction(Object.keys(resData.metadata).map((md) => resData.metadata[md]))
                } else {
                    handleFail();
                }
            });
        }
    }, [checkoutData]);

    useEffect(() => {
        if (isSuccess) {
            handleSuccess();
        }
    }, [isSuccess]);

    return (
        <section className="page-section purchase-confirmation-container boxed-layout vertical-content-centered text-center">
            <h2 style={{fontSize: "70px", textAlign: "center", maxWidth: "60%", margin: "0 auto"}}>
                {title}
            </h2>
            <Img src={cloudimageConfig.baseURL + 'padlock.png'} className="padlock-illustration"/>
            <h3 style={{fontSize: "50px", textAlign: "center", maxWidth: "60%", margin: "0 auto"}}
                className="tertiary-color"
            >
                {subtitle}
            </h3>
            {isSuccess === false &&
            <SecondaryBtn parentAction={() => history.push('/')} style={{margin: "2rem auto 0"}}>
                Go to homepage
            </SecondaryBtn>
            }
        </section>
    );
};
export default PurchaseConfirmation;
