import React, { Component, createContext } from "react";

const EditContext = createContext();

class PhotoEditProvider extends Component {
  state = {
    titlend: false,
    credits: false,
    metadata: false,
    series: false,
    special: false,
    keyword: false,
    agency: false,
    market: false,
    exhi: false,
    colors: false,
    iptc: true,
    exif: false,
    iptcActive: true,
    exifActive: false,
    titlendValid: false,
    creditsValid: false,
    metadataValid: false,
    seriesValid: false,
    specialValid: false,
    keywordValid: false,
    agencyValid: false,
    marketValid: false,
    exhiValid: false,
    colorsValid: false,
    currentActive: "SET_TITLEND",
    formValues: {
      title: "",
      price: "0",
      description: "",
      fullName: "",
      iptc: {
        headshot: "",
        halfLength: "",
        fullLength: "",
        profile: "",
        rearView: "",
        single: "",
        couple: "",
        two: "",
        group: "",
        generalView: "",
        panoramicView: "",
      },
      exif: {
        ISOSpeed: "",
        shutterSpeed: "",
        aperture: "",
        whiteBalance: "",
        cameraModel: "",
        date: "",
        geoLocation: "",
        lensUsed: "",
        lensFocalLength: "",
      },
      serie: "",
      newSerie: "",
      instructions: [],
      oldAgency: "",
      agency: "",
      keywords: [],
      marketType: "",
      newMarketType: "",
    },
  };

  setCurrentActive = (element) => {
    this.setState({ currentActive: element });
  };

  setEditState = (element, value) => {
    switch (element) {
      case "TO_IPTC":
        this.setState({
          iptc: true,
          exif: false,
          iptcActive: true,
          exifActive: false,
        });
        break;
      case "TO_EXIF":
        this.setState({
          iptc: false,
          exif: true,
          iptcActive: false,
          exifActive: true,
        });
        break;
      default:
        const newState = {};
        newState[element] = value;
        this.setState(newState);
        break;
    }
  };

  formModify = (val) => {
    this.setState({ formValues: { ...this.state.formValues, ...val } });
  };

  handleIptcChange = (ref) => {
    const newVal = {};
    newVal[ref.current.id] = ref.current.value;
    const fV = { ...this.state.formValues };
    fV.iptc = { ...fV.iptc, ...newVal };
    this.setState({ formValues: { ...fV } });
  };

  handleExifChange = (ref) => {
    const newVal = {};
    newVal[ref.current.id] = ref.current.value;
    const fV = { ...this.state.formValues };
    fV.exif = { ...fV.exif, ...newVal };
    this.setState({ formValues: { ...fV } });
  };

  handleInstuctionsChange = (value) => {
    if (value.trim() !== "") {
      const fV = { ...this.state.formValues };
      fV.instructions.push(value);
      this.setState({ formValues: { ...fV } });
    }
  };

  handleKeywordsChange = (value) => {
    if (value.trim() !== "") {
      const fV = { ...this.state.formValues };
      fV.keywords.push(value);
      this.setState({ formValues: { ...fV } });
    }
  };

  removeInstruction = (index) => {
    const fV = { ...this.state.formValues };
    fV.instructions.splice(index, 1);
    this.setState({ formValues: { ...fV } });
  };

  removeKeyword = (index) => {
    const fV = { ...this.state.formValues };
    fV.keywords.splice(index, 1);
    this.setState({ formValues: { ...fV } });
  };

  handleChange = (ref) => {
    const newVal = {};
    newVal[ref.current.id] = ref.current.value;
    this.formModify({ ...newVal });
  };

  handleDropdownChange = (ref, value) => {
    const newVal = {};
    newVal[ref] = value;
    this.formModify({ ...newVal });
  };

  render() {
    return (
      <EditContext.Provider
        value={{
          ...this.state,
          setEditState: this.setEditState,
          setCurrentActive: this.setCurrentActive,
          editState: this.state,
          formModify: this.formModify,
          formValues: this.state.formValues,
          handleChange: this.handleChange,
          removeKeyword: this.removeKeyword,
          handleIptcChange: this.handleIptcChange,
          handleExifChange: this.handleExifChange,
          removeInstruction: this.removeInstruction,
          handleDropdownChange: this.handleDropdownChange,
          handleKeywordsChange: this.handleKeywordsChange,
          handleInstuctionsChange: this.handleInstuctionsChange,
        }}
      >
        {this.props.children}
      </EditContext.Provider>
    );
  }
}

export { EditContext };
export default PhotoEditProvider;
