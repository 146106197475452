import React, {useEffect, useState} from "react";
import "./SpecInstructions.scss";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import TertiaryBtn from '../../../../Shared/Buttons/Template/TertiaryBtn';
import {AiOutlineClose} from 'react-icons/ai';

const SpecInstructions = (props) => {
    const formTitle = 'Specials instructions';

    const [instructions, setInstructions] = useState([]);

    const updateInstruction = (event) => {
        const value = event.target.value;
        const index = +event.target.getAttribute('data-instruction');
        const tmp = [...instructions];
        tmp[index] = value;
        setInstructions(tmp);
    }

    const removeInstruction = (index) => {
        const tmp = [...instructions];
        tmp.splice(index, 1);
        setInstructions(tmp);
    }

    useEffect(() => {
        if (props.originalData?.instructions) {
            setInstructions(props.originalData.instructions);
        }
    }, [props]);

    const onSubmit = () => {
        const inputs = (document.getElementsByClassName('special-instruction-field'));
        const values = [];
        for (let input of inputs) {
            if (input.value.trim() !== '') {
                values.push(input.value.trim());
            }
        }
        const data = {instructions: values}
        props.parentAction(data);
    }

    return (
        <div className="box-credits-edit">
            <AuthentHeader title={formTitle}/>
            <Form initialValues={props.originalData?.instructions}
                  onSubmit={onSubmit}
                  render={({submitError, handleSubmit, submitting}) => (
                      <form onSubmit={handleSubmit} className="instructions-form">
                          {instructions.map((si, k) => (
                              <Field name={`instruction-${k}`} key={k}>
                                  {({input, meta}) => (
                                      <div className="field-group si-group" style={{display: "flex"}}>
                                          <input {...input} type="text"
                                                 data-instruction={k}
                                                 value={instructions[k]}
                                                 onChange={updateInstruction}
                                                 className={`special-instruction-field input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                          />
                                          <AiOutlineClose className="rm-instruction-icon"
                                                          onClick={() => removeInstruction(k)}
                                          />
                                      </div>
                                  )}
                              </Field>
                          ))}
                          <TertiaryBtn parentAction={() => setInstructions([...instructions, ''])}>
                              Add an instruction
                          </TertiaryBtn>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                              style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                          >
                                              Save
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )}
            />
        </div>
    );
};

export default SpecInstructions;
