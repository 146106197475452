import React, {useContext, useEffect, useState} from 'react';
import SmallBanderole from "../../Banderole/Template/SmallBanderole/SmallBanderole";
import {useHttpClient} from '../../../../hooks/http-hook';
import {AuthContext} from '../../../../context/auth-context';
import {config} from '../../../../environments';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';

const MyImages = (props) => {
    const {sendRequest} = useHttpClient();
    const {token, userIdProfile} = useContext(AuthContext);

    const [images, setImages] = useState([]);
    const title = 'My Images';

    const retrieveAllImages = async () => {
        const url = `${config.baseUrlDev}/images/filter?status=${statusEnum.ACCEPTED}&photographerId=${userIdProfile}&priceId=false`
        await sendRequest(url, 'GET', null, {cookies: token})
            .then((data) => setImages(data.slice(0, 20)))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (userIdProfile && token) {
            retrieveAllImages();
        }
    }, [userIdProfile]);

    const fakeData = {
        title: 'My Images',
        data: [
            { main: 'bg-about1.jpg' },
            { main: 'bg-myimg1.jpg' },
            { main: 'bg-myimg2.jpg' },
            { main: 'bg-same3.jpg' },
            { main: 'bg-myimg3.jpg' },
            { main: 'bg-myimg4.jpg' },
            { main: 'bg-myimg5.jpg' },
        ]
    };

    const shuffleArray = (arr) => {
        return arr.sort(() => Math.random() - 0.5);
    };

    return (
        <SmallBanderole title={title} data={images.length > 0 ? images : shuffleArray(fakeData.data)}
                        titleType={props.titleType}
                        type="images" containerId="my-images" />
    );
};
export default MyImages;
