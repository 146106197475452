import React from 'react';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import PurchasesHistory from '../../components/Purchases/PurchasesHistory/PurchasesHistory';

const PurchasesPage = () => {
    return (
        <PageLayout>
            <section className="page-section section-header boxed-layout"
                     style={{paddingTop: "100px"}}
            >
                <header className="section-header">
                    <h1>My<br/>Purchases</h1>
                </header>
            </section>
            <PurchasesHistory/>
        </PageLayout>
    )
}
export default PurchasesPage;
