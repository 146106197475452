import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./pdftypepage.css";
// import { ImQrcode } from "react-icons/im";
import Pixt from "../../../assets/images/12.JPG";
import Pixt5 from "../../../assets/images/qrcode.png";
import Pixt3 from "../../../assets/images/13.JPG";
import Pixt2 from "../../../assets/images/logo_pixt.png";
import {config} from "../../../environments";
import {useHttpClient} from "../../../hooks/http-hook";
import {AuthContext} from "../../../context/auth-context";

import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
// import { BackgroundImg } from "react-cloudimage-responsive";

const Pdftypepage = ({imageId}) => {
    const [meta1, setMeta1] = useState();
    const [bufferImg, setBufferImg] = useState();
    const [user2, setUser2] = useState();
    // const [agency3, setAgency3] = useState();
    const {sendRequest} = useHttpClient();
    const {
        token,
        userId,
    } = useContext(AuthContext);
    const baseUrl = `${config.baseUrlDev}/images`;
    const baseUrlUser = `${config.baseUrlDev}/user`;
    const baseUrlImage = `${config.baseUrlDev}/images/bufferImage`;

    const printDocument = () => {
        const input = document.getElementById("mywholepage");
        html2canvas(input, {
            useCORS: true,
            allowTaint: true,
        }).then(async (canvas) => {
            const imgData = canvas.toDataURL("image/jpeg");
            const pdf = new jsPDF({
                orientation: "portrait",
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight + 89);
            await pdf.save("certificat.pdf", {returnPromise: true});
            setTimeout(() => window.close(), 300);
        });
    };

    const retrieveImageData = async () => {
        await sendRequest(`${baseUrl}/${imageId}`, "GET", null, {
            cookies: token,
        })
            .then((imgData) => {
                setMeta1(imgData);

            })
            .catch((error) => console.warn({error}));
    };

    const getImage = async () => {
        await sendRequest(`${baseUrlImage}/${imageId}`, "GET", null, {
            cookies: token,
        })
            .then((bufferImage) => {
                const arrayBufferToBase64 = buffer => {
                    let binary = '';
                    let bytes = new Uint8Array(buffer);
                    let len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    return window.btoa(binary);
                };
                setBufferImg(arrayBufferToBase64(bufferImage.imgData.data));
            })
            .catch((error) => console.warn({error}));
    };

    useEffect(() => {
        if (bufferImg) {
            printDocument();
        }
    }, [bufferImg]);

    const retrieveUserInfo = async () => {
        await sendRequest(`${baseUrlUser}/${userId}`, "GET", null, {
            cookies: token,
        })
            .then((UserData) => {
                setUser2([UserData]);
            })
            .catch((error) => console.warn({error}));
    };

    useEffect(() => {
        if (imageId && userId) {
            retrieveImageData();
            retrieveUserInfo();
            getImage();
        }
    }, [imageId, userId]);

    const photographerurl = `${config.baseUrlDev}/photographer/details?photographerId=${meta1?.data?.photographer}`;
    const createdateit = `${meta1?.data?.createdAt}`;
    const createrealdate = createdateit.substring(0, 10);

    return (
        <>
            <div id="mywholepage" className="mywholepage" media="print">
                <div className="leftpageside1">
                    <Link to="/">
                        <img src={Pixt3} alt="pixt" className="leftpageside67"/>
                    </Link>
                </div>

                <div className="entouragediv"></div>
                <div className="entouragediv2"></div>
                <div className="entouragediv3"></div>
                <div className="thisfirstnamediv2">
                    {user2?.[0]?.lastName}
                    <br/>
                    {user2?.[0]?.firstName}
                </div>
                <div className="rightpageside1">
                    <Link to="/">
                        <img src={Pixt2} alt="pixt" className="thismylogo2"/>
                    </Link>
                </div>
                <div className="leftpageside2">
                    <div className="halfleftside">
                        <h5>TITLE</h5>
                        <h5>CREDIT</h5>
                        <h5>ORIGINAL FILENAME</h5>
                        <br/>
                        <h5>DATE CREATED</h5>
                        <br/>
                        <h5>CITY</h5>
                        <br/>
                        <h5>COUNTRY</h5>
                    </div>
                    <div className="halfrightside">
                        <h5>{meta1?.data?.title ? meta1?.data?.title : "-"}</h5>
                        <h5>{meta1?.data?.credit ? meta1?.data?.credit : "-"}</h5>
                        <h5>{meta1?.data?.fileName ? meta1?.data?.fileName : "-"}</h5>
                        <br/>
                        <h5>{meta1?.data?.createdAt ? createrealdate : "-"}</h5>
                        <br/>
                        <h5>
                            {user2?.[0]?.address?.city ? user2?.[0]?.address?.city : "-"}
                        </h5>
                        <br/>
                        <h5>
                            {user2?.[0]?.address?.country
                                ? user2?.[0]?.address?.country
                                : "-"}
                        </h5>
                    </div>
                    <div className="rightpageside4">
                        <Link to="/">
                            <img src={Pixt5} alt="pixt" className="putthismylogo"/>
                        </Link>
                        {/* <ImQrcode className="thismyqrcode"></ImQrcode> */}

                        <div className="blockchaindiv">Blockchain Certification</div>
                    </div>
                </div>
                <div className="rightpageside2">
                    <div className="halfleftsideleft">
                        <h5>AGENCY</h5> <br/>
                        <h5>ADRESS</h5>
                    </div>
                    <div className="halfrightside2">
                        <h5>
                            {meta1?.data?.agency?.companyName
                                ? meta1?.data?.agency?.companyName
                                : "-"}
                        </h5>
                        <br/>
                        <br/>
                        <h5>
                            {user2?.[0]?.address?.city
                                ? user2?.[0]?.address?.city + "   "
                                : "-"}
                            {user2?.[0]?.address?.country
                                ? user2?.[0]?.address?.country
                                : "-"}
                        </h5>
                    </div>
                </div>
                <div className="thetwosides">
                    <div className="halfleftside">
                        <h5>CURRENT OWNER</h5>
                        <br/> <br/>
                        <h5>BLOCKCHAIN URL</h5>
                        <br/> <br/>
                        <h5>BLOCKCHAIN ADDRESS</h5>
                        <br/> <br/>
                        <h5>UPLOAD DATE</h5>
                    </div>
                    <div className="halfrightside3">
                        <h5>
                            {user2?.[0]?.lastName + "  "}

                            {user2?.[0]?.firstName}
                        </h5>
                        <br/> <br/>
                        <h5>
                            {meta1?.data?.blockchainSignature?.data?.seed
                                ? meta1?.data?.blockchainSignature?.data?.seed
                                : "-"}
                        </h5>
                        <br/> <br/>
                        <h5>
                            {meta1?.data?.blockchainSignature?.data?.seed
                                ? meta1?.data?.blockchainSignature?.data?.seed
                                : "-"}
                        </h5>
                        <br/>
                        <br/>
                        <h5>{meta1?.data?.createdAt ? createrealdate : "-"}</h5>
                    </div>
                </div>
                <div className="leftsideimagej">
                    <img className="purchased-grid-itemj"
                         src={`data:image/jpeg;base64 ,${bufferImg}`}
                         alt="photography"
                    />
                </div>

                <div className="descriptimageon">
                    {meta1?.data?.description ? meta1?.data?.description : "-"}
                </div>
                <div className="descriptmyimageon">
                    <h5>DESCRIPTION</h5>
                </div>
                <div className="rightpageside3">
                    <div className="halfleftside">
                        <div>
                            <h5>SERIES</h5>
                            <br/>
                            <h5>SPECIAL INSTRUCTIONS</h5>
                            <br/>
                            <h5>CAMERA</h5>
                            <br/>
                            <h5>DIMENSIONS</h5>
                            <br/>
                            <h5>KEYWORDS</h5>
                            <br/>
                            <h5>PHOTOGRAPH URL</h5>
                        </div>
                    </div>
                    <div className="halfrightside4">
                        <h5>
                            {meta1?.data?.agency?.series ? meta1?.data?.agency?.series : "-"}
                        </h5>
                        <br/>

                        <h5>
                            {meta1?.data?.instructions?.data
                                ? meta1?.data?.instructions?.data
                                : "-"}
                        </h5>

                        <br/>
                        <h5>
                            {meta1?.data?.EXIFmetadata?.ifd0?.Model
                                ? meta1?.data?.EXIFmetadata?.ifd0?.Model
                                : "-"}
                        </h5>
                        <br/>
                        <h5>
                            {meta1?.data?.EXIFmetadata?.ifd0?.XResolution
                                ? meta1?.data?.EXIFmetadata?.ifd0?.XResolution
                                : "-"}{" "}
                            x
                            {meta1?.data?.EXIFmetadata?.ifd0?.YResolution
                                ? meta1?.data?.EXIFmetadata?.ifd0?.YResolution
                                : "-"}
                        </h5>
                        <br/>
                        <h5>
                            {meta1?.data?.keywords?.data ? meta1?.data?.keywords?.data : "-"}
                        </h5>
                        <br/>
                        <h5>{photographerurl ? photographerurl : "-"}</h5>
                    </div>
                </div>
                <div className="thisfirstnamediv">
                    Copyright Pix.T
                    <br/>
                    All rights reserved
                </div>

                <div className="leftpageside3">
                    <Link to="/">
                        <img src={Pixt} alt="pixt" className="thismylogo"/>
                    </Link>
                    {/* <div className="confirmedverificationdiv">
            Confirmed Verification by Pix.T | 2021
          </div> */}
                </div>
            </div>
        </>
    );
};

export default Pdftypepage;
