import React from "react";
import "./OverUpload.scss";
import { FiUpload as Upload } from "react-icons/fi";
const OverUpload = () => {
  return (
    <>
      <div className="section-over-upload">
        <div className="middle-ov-up">
          <div className="circle-ov-up">
            <Upload className="image-upload-div" />
          </div>
          <h3>Upload</h3>
        </div>
      </div>
    </>
  );
};

export default OverUpload;
