import React, {useContext} from 'react';
import './Series.scss';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import {FiPlus} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../../../context/auth-context';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';

export const Series = () => {
    const {userType} = useContext(AuthContext);

    return (
        <section className="page-section boxed-layout series-list-section">
            <header className="mb-list-header">
                <h1>My Series</h1>
                {userType === roleEnum.PHOTOGRAPHER &&
                <Link to="/series/add">
                    <TertiaryBtn className="create-btn">
                        <FiPlus className="plus-add"/>
                        Create a new series
                    </TertiaryBtn>
                </Link>
                }
            </header>
        </section>
    );
};

