import React from 'react'
import './AboutTitle.css'

const SectionAboutTitle = (props) => {
    return (
        <>
            <div className="section-about-title">
                {props.children}
            </div>
        </>
    )
}

export default SectionAboutTitle
