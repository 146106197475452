import React from "react";
import { BsChevronDoubleLeft } from "react-icons/bs";
import RightSideEdit from "../RightSideEdit/RightSideEdit";
import "./CertifMobile.scss";

const CertifMobile = ({ originalData, formValues, parentAction }) => {
  return (
    <>
      <div className="mobile-certif">
        <div className="certif-pos">
          <RightSideEdit image={originalData} data={formValues} />
        </div>

        <BsChevronDoubleLeft className="back-chevron" onClick={parentAction}/>
      </div>
    </>
  );
};

export default CertifMobile;
