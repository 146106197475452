import React, {useReducer} from "react";
import { useSeries } from '../../../../hooks/series/useSeries';
import {validate} from "../../../../validators/validators";
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import "./InputReg.scss";

const inputReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE":
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators),
            };
        case "TOUCH": {
            return {
                ...state,
                isTouched: true,
            };
        }
        default:
            return state;
    }
};

const InputReg = (props) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || "",
        isTouched: false,
        isValid: props.initialValid || false,
    });

    const touchHandler = () => {
        dispatch({
            type: "TOUCH",
        });
    };

    

    return (
        <div>
            <div>
                <input
                    onChange={props.parentAction}
                    ref={props.parentRef}
                    style={{
                        margin: props.margin,
                        height: props.height,
                        width: props.width,
                    }}
                    type={props.type}
                    className={props.className ? props.className : "input-reg"}
                    value={props.value}
                    id={props.id}
                    validators={props.validators}
                    data-error={props.errorText}
                    onBlur={touchHandler}
                    disabled={props.disabled}
                    onInput={props.onInput}
                    checked={props.type === "checkbox" ? props.checked : undefined}
                    maxLength={props.type === "telephone" ? "15" : undefined}
                    pattern={props.type === "telephone" ? "[0-9]" : undefined}
                />
                 {
                     props.value && 
                        <TertiaryBtn parentAction={props.handleCreateSeries} style={{ marginTop:"1rem"}}>
                            Create Series
                        </TertiaryBtn>
                 }
            </div>
        </div>
    );
};

export default InputReg;
