import React, {useEffect, useState} from 'react';
import './Illustration.scss';

import { cloudimageConfig } from '../../../assets/statics/cloud-image';
import { BackgroundImg } from 'react-cloudimage-responsive';

const Illustration = (props) => {
    const [bgItem, setBgItem] = useState({ color: '#333', main: 'bg-explore3.jpg', author: 'Andrea Bruce' });

    const selectIllustration = () => {
        const index = Math.floor(Math.random() * fakeData.length);
        setBgItem(fakeData[index]);
    };

    useEffect(() => {
        if (selectIllustration) {
            selectIllustration();
        }
    }, [])

    const fakeData = [
        { color: '#F45615', main: 'bg-explore3.jpg', author: 'Andrea Bruce' },
        { color: '#F0AC78', main: 'bg-test.jpg', author: 'Martha Dennis' },
        { color: '#F2CDAE', main: 'bg-explore2.jpg', author: 'Violet Soto' },
        { color: '#5CD5FB', main: 'bg-explore4.jpg', author: 'Mario Stephens' },
        { color: '#F53853', main: 'bg-test3.jpg', author: 'Henrietta Greer' },
        { color: '#DB9F5A', main: 'bg-stories1.jpg', author: 'Duane Reynolds' },
        { color: '#DA2B05', main: 'bg-about6.jpg', author: 'Andrea Bruce' },
        { color: '#D4D4D4', main: 'bg-exhi5.jpg', author: 'Violet Soto' },
    ]

    return (
        <div className="illustation-authent">
            <div className="color-background" style={{backgroundColor: bgItem.color}} />
            <div className="illustation-container">
                <BackgroundImg className="authent-image" src={cloudimageConfig.baseURL + bgItem.main}
                style={{backgroundSize: "cover", backgroundPosition: "50%"}}/>
                By {bgItem.author}
            </div>
            <h1 className="header-container" dangerouslySetInnerHTML={{ __html: props.title }} />
        </div>
    )
}
export default Illustration;
