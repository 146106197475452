export const stripePaymentStatus = {
    PAID: 'paid',
    UNPAID: 'unpaid',
};

export const statusTitle = {
    PENDING: 'We are validating your payment...',
    PAID: 'Purchase validated with success',
    UNPAID: 'Your purchase has failed'
}

export const statusSubtitle = {
    PENDING: 'This operation will not take a long time',
    PAID: 'Generating blockchain encryption key to certify your ownership...',
    UNPAID: 'Please, verify your payment information and try again'
}
