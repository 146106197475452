import React, {useEffect, useState} from 'react';
import './PurchasesDetails.scss';
import {useHttpClient} from '../../../hooks/http-hook';
import {config} from '../../../environments';
import PurchasedImageItem from './PurchasedImageItem/PurchasedImageItem';
import ImageSection from '../../CheckoutAction/CheckoutResult/ImageSection/ImageSection';
import {Redirect} from 'react-router-dom';

const PurchasesDetails = ({checkoutId}) => {
    const {sendRequest} = useHttpClient();
    const baseUrl = `${config.baseUrlDev}/stripe/checkout`;
    const piUrl = `${config.baseUrlDev}/stripe/payment-intent`;

    const [pi, setPi] = useState();
    const [imagesIds, setImagesIds] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState();

    const retrieveData = async () => {
        sendRequest(`${baseUrl}/${checkoutId}`, 'GET')
            .then(async (checkout) => {
                await sendRequest(`${piUrl}/${checkout.payment_intent}`, 'GET')
                    .then((piData) => setPi({...piData, checkout}))
                    .catch((error) => setPi(null));
            })
            .catch((error) => setPi(null));
    }

    const handleImageClick = (imageId) => {
        if (imageId === modalImage) {
            setModal(false);
            setModalImage();
        } else {
            setModalImage(imageId);
            setModal(true);
        }
    }

    useEffect(() => {
        if (checkoutId) {
            retrieveData()
        }
    }, [checkoutId])

    useEffect(() => {
        if (pi?.checkout?.metadata && Object.keys(pi?.checkout?.metadata)) {
            setImagesIds(Object.keys(pi?.checkout?.metadata).map((key) => pi?.checkout?.metadata[key]));
        }
    }, [pi])

    return pi === null || !checkoutId
        ? <Redirect to="/not-found"/>
        : <div className="boxed-layout" style={{marginTop: "50px"}}>
            <div>
                <h4>Purchase information</h4>
                <p>Order n°: {pi?.checkout?.id.replace('cs_test_', '')}</p>
                <p>Total
                    paid: {pi?.currency} {pi?.amount / 100},{pi?.amount.toString().substring(pi?.amount.toString().length - 2)}</p>
            </div>
            <div>
                <h4>Associated assets</h4>
                <div className="purchased-image-grid">
                    {imagesIds.map((imgId) => {
                        return (
                            <PurchasedImageItem parentAction={handleImageClick} imageId={imgId}/>
                        )
                    })}
                </div>
            </div>
            {modal &&
            <ImageSection imageId={modalImage}/>
            }
        </div>;
};
export default PurchasesDetails;
