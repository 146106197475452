import React from 'react';
import './AuthentHeader.scss';

const AuthentHeader = (props) => {
    return (
        <header className="authent-header">
            {props.title && <h4 className="authent-title">{props.title}</h4>}
            {props.subtitle && <p className="authent-subtitle">{props.subtitle}</p>}
        </header>
    )
}
export default AuthentHeader;
