import React, {useEffect, useState} from 'react';
import AgencyParagraphs from '../../components/AgencyPage/AboutAgency/AgencyParagraphs';
import AgencyPhotographers from '../../components/AgencyPage/AgencyPhotographers/AgencyPhotographers';
import ProfilAgency from '../../components/AgencyPage/ProfilAgency/ProfilAgency';
import Bio from '../../components/PhotographerPage/Bio/Bio';
import AgencyGallery from "../../components/AgencyPage/AgencyGallery/AgencyGallery";
import AgencyExhibitions from "../../components/AgencyPage/AgencyExhibitions/AgencyExhibitions";
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {useHttpClient} from '../../hooks/http-hook';
import {config} from '../../environments';
import {Redirect} from 'react-router-dom';

const AgencyPage = ({match}) => {
    const agencyId = match?.params?.agencyId;
    const [agency, setAgency] = useState();
    const [contactUser, setContactUser] = useState();
    const {sendRequest} = useHttpClient();
    const agencyEndpoint = `${config.baseUrlDev}/agency/${agencyId}`;
    const contactUserEndpoint = `${config.baseUrlDev}/user`;

    const retrieveAgencyData = async () => {
        await sendRequest(
            agencyEndpoint, 'GET'
        )
            .then((res) => {
                setAgency(res);
                retrieveContactUserData(res?.userId)
            })
            .catch((e) => setAgency(null));
    }

    const retrieveContactUserData = async (contactUserId) => {
        await sendRequest(
            `${contactUserEndpoint}/${contactUserId}`, 'GET'
        )
            .then((res) => setContactUser(res))
            .catch((e) => console.warn({e}));
    }

    useEffect(() => {
        if (agencyId) {
            retrieveAgencyData();
        }
    }, [agencyId]);

    return agency === null && !agencyId
        ? <Redirect to="/not-found"/>
        : (
            <PageLayout>
                <ProfilAgency agency={agency} contactUser={contactUser}/>
                <Bio title="About us">
                    <AgencyParagraphs description={contactUser?.description}/>
                </Bio>
                <AgencyPhotographers titleType={2} photographersIds={agency?.photographers}/>
                <AgencyExhibitions agencyId={agency?._id} titleType={2}/>
                <AgencyGallery agencyId={agencyId}/>
            </PageLayout>
        )
};
export default AgencyPage
