import React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {required, requiredCheckbox, requiredPhone} from '../../../Shared/Forms/validators-final-form';
import {formEnum, roleEnum} from '../../../../assets/statics/authent-signup.enum';

const PersonalForm = ({registrationBody, submitStep, roles, btnLabel, formStep, errorHandler}) => {
    const getErrors = () => {
        try {
            const d = JSON.parse(errorHandler);
            if (d.errors && typeof d.errors !== 'string') {
                return Object.keys(d.errors).map((item) => item).join(', ')
            }
        } catch (e) {
            return 'Error'
        }
    }

    return (
        <Form
            initialValues={registrationBody}
            onSubmit={submitStep}
            render={({handleSubmit, submitting}) => (
                <form onSubmit={handleSubmit} className="registration-form">
                    <Field name="firstName" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>Firstname</label>
                                <input {...input} type="text"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                       placeholder="Firstname"/>
                            </div>
                        )}
                    </Field>
                    <Field name="lastName" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>Lastname</label>
                                <input {...input} type="text"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                       placeholder="Lastname"/>
                            </div>
                        )}
                    </Field>
                    {(roles !== roleEnum.USER) && (
                        <Field name="jobTitle" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label>Job title</label>
                                    <input {...input} type="text"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Photographer"/>
                                </div>
                            )}
                        </Field>
                    )}
                    {roles === roleEnum.USER && (
                        <>
                            <Field name="email" validate={required}>
                                {({input, meta}) => (
                                    <div className="field-group">
                                        <label>Email</label>
                                        <input {...input} type="text"
                                               className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                               placeholder="user@domain.com"/>
                                    </div>
                                )}
                            </Field>
                            <Field name="phoneNumber" validate={requiredPhone}>
                                {({input, meta}) => (
                                    <div className="field-group">
                                        <label>Telephone contact</label>
                                        <input {...input} type="text"
                                               className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                               placeholder="0648756212"
                                               minLength="7"
                                               maxLength="15"
                                        />
                                    </div>
                                )}
                            </Field>
                        </>
                    )}
                    <Field name="password" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>Create password</label>
                                <input {...input} type="password"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                       placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"/>
                            </div>
                        )}
                    </Field>
                    {formStep === formEnum.STEP_END && <Field name="terms" validate={requiredCheckbox} type="checkbox">
                        {({input, meta}) => (
                            <div className="field-group" style={{display: "flex", alignItems: "center"}}>
                                <input {...input} type="checkbox"
                                       className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}/>
                                <label style={{margin: 0}}>&nbsp;I accept terms and conditions</label>
                            </div>
                        )}
                    </Field>}
                    <div className="field-group">
                        {errorHandler &&
                        <div>
                            <div className="authent-error">[{getErrors()}] Unexpected error</div>
                        </div>
                        }
                        <FormSpy subscription={{pristine: true, invalid: true}}>
                            {props => {
                                const isInvalid = submitting || props.invalid;
                                return (
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                        style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                    >
                                        {btnLabel}
                                    </button>
                                )
                            }}
                        </FormSpy>
                    </div>
                </form>
            )}
        />
    )
};
export default PersonalForm;
