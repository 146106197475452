import React, {useState} from "react";
import Navbars from "../../../components/Shared/PageLayout/Navbar/navbar";
import CertificateDownload
    from "../../../components/CheckoutAction/CheckoutResult/CertificateDownload/CertificateDownload";
import PurchaseConfirmation
    from '../../../components/CheckoutAction/CheckoutResult/PurchaseConfirmation/PurchaseConfirmation';

const CertificatedGenerated = () => {
    const [certified, setCertified] = useState(false);
    const [lineItems, setLineItems] = useState([]);

    const handlePaymentSuccess = (lineItems) => {
        setTimeout(() => setCertified(true), 4000);
        setLineItems(lineItems);
    }

    return (
        <>
            <Navbars/>
            {certified
                ? <CertificateDownload lineItems={lineItems}/>
                : <PurchaseConfirmation parentAction={handlePaymentSuccess}/>
            }
        </>
    );
};

export default CertificatedGenerated;
