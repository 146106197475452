import React, {useState} from 'react';
import {AiFillCaretDown as Caret, AiFillCaretRight as RightCaret} from 'react-icons/ai';

const InputColor = () => {
    const [color, setColor] = useState(false);
    const [colorValue, setColorValue] = useState(false);

    const handleColorVal = (event) => {
        setColorValue(event.target.getAttribute('data-value'));
        setColor(!color);
    };

    return (
        <div className="search-exp-color search-custom-dropdown search-bar-item"
             onClick={() => setColor(!color)}
        >
            <span className="search-input-data">Colors</span>
            {colorValue}
            {color ? (
                <Caret className="search-caret"/>
            ) : (
                <RightCaret className="search-caret"/>
            )}
            {color && (
                <div className="search-dropdown">
                    <span onClick={handleColorVal} data-value="Black & White">Black & White</span>
                    <span onClick={handleColorVal} data-value="Black & Yellow">Black & Yellow</span>
                    <span onClick={handleColorVal} data-value="White & Red">White & Red</span>
                </div>
            )}
        </div>
    );
};
export default InputColor;
