import React, {useEffect, useState} from "react";
import "./KeywordsData.scss";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import TertiaryBtn from '../../../../Shared/Buttons/Template/TertiaryBtn';
import {AiOutlineClose} from 'react-icons/ai';

const KeywordsData = (props) => {
    const formTitle = 'Keywords';

    const [keywords, setKeywords] = useState([]);

    const updateKeyword = (event) => {
        const value = event.target.value;
        const index = +event.target.getAttribute('data-keyword');
        const tmp = [...keywords];
        tmp[index] = value;
        setKeywords(tmp);
    }

    const removeKeyword = (index) => {
        const tmp = [...keywords];
        tmp.splice(index, 1);
        setKeywords(tmp);
    }

    useEffect(() => {
        if (props.originalData?.keywords) {
            setKeywords(props.originalData.keywords);
        }
    }, [props]);

    const onSubmit = () => {
        const inputs = (document.getElementsByClassName('special-keyword-field'));
        const values = [];
        for (let input of inputs) {
            if (input.value.trim() !== '') {
                values.push(input.value.trim());
            }
        }
        const data = {keywords: values}
        props.parentAction(data);
    }

    return (
        <div className="box-credits-edit">
            <AuthentHeader title={formTitle}/>
            <Form initialValues={props.originalData?.keywords}
                  onSubmit={onSubmit}
                  render={({submitError, handleSubmit, submitting}) => (
                      <form onSubmit={handleSubmit} className="keywords-form">
                          {keywords.map((kw, k) => (
                              <Field name={`keyword-${k}`} key={k}>
                                  {({input, meta}) => (
                                      <div className="field-group kw-group" style={{display: "flex"}}>
                                          <input {...input} type="text"
                                                 data-keyword={k}
                                                 value={keywords[k]}
                                                 onChange={updateKeyword}
                                                 className={`special-keyword-field input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                          />
                                          <AiOutlineClose className="rm-keyword-icon"
                                                          onClick={() => removeKeyword(k)}
                                          />
                                      </div>
                                  )}
                              </Field>
                          ))}
                          <TertiaryBtn parentAction={() => setKeywords([...keywords, ''])}>
                              Add an keyword
                          </TertiaryBtn>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                              style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                          >
                                              Save
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )}
            />
        </div>
    );
};
export default KeywordsData;
