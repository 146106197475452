import React, {useState} from "react";
import Banderole from '../../Banderole';
import Header from '../../../Header/Header';

const SmallBanderole = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);

    return (
        <section 
            className="boxed-layout page-section"
            id={props.containerId}
        >
            <Header
                title={props.title}
                titleType={props.titleType}
                parentId={props.containerId}
                setControls={{setLeftNav, setRightNav}}
                showControls={!props.noControls ? {leftNav, rightNav} : false}
            />
            <Banderole 
                cards={props.data} 
                type={props.type} 
                parentAction={props.parentAction}
                parentId={props.containerId}
                className={props.className}
            />
        </section>
    );
};
export default SmallBanderole;
