import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { roleEnum } from '../../assets/statics/authent-signup.enum';
import { AuthContext } from '../../context/auth-context';
import { config } from '../../environments';
import { useHttpClient } from '../http-hook';

const CREATE_STORY_URL = `${config.baseUrlDev}/story/add`;
const UPDATE_STORY_URL = `${config.baseUrlDev}/story/update`;
const FETCH_STORY_BY_ID_URL = `${config.baseUrlDev}/story`;
const BASE_FILTER_STORIES_URL = `${config.baseUrlDev}/story/filter`;


export function useStory () {

    const {sendRequest} = useHttpClient();
    const { token } = useContext(AuthContext);
    const history = useHistory();

    const [filteredStories, setSetFilteredStories] = useState([]);
    const [foundStory, setFoundStory] = useState([]);

    async function createStory (seriesValues) {
        await sendRequest(
            `${CREATE_STORY_URL}`,
            'POST',
            JSON.stringify(seriesValues),
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then(() => {
                history.push(`/story/list`)
            });
    }

    async function updateStory (seriesId, seriesValues) {
        await sendRequest(
            `${UPDATE_STORY_URL}/${seriesId}`,
            'PUT',
            JSON.stringify(seriesValues),
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then(() => {
                history.push(`/story/list`)
            });
    }

    async function filterStories (role, userIdProfile) {
        const query = generateFilterStoriesRoleQuery(role, userIdProfile);
        if(query) {
            await sendRequest(
                `${BASE_FILTER_STORIES_URL}?${query}`,
                'GET',
                null,
                {'Content-Type': 'application/json', 'cookies': token}
            ).then((data) => {
                setSetFilteredStories(data);
            });
        }

    }

    async function getStoryById (seriesId) {
        await sendRequest(
            `${FETCH_STORY_BY_ID_URL}/${seriesId}`,
            'GET',
            null,
            {'Content-Type': 'application/json', 'cookies': token}
        )
            .then((data) => {
                setFoundStory(data);
            });
    }

    function generateFilterStoriesRoleQuery (role, userIdProfile){
        let query = '';
        if (role === roleEnum.PHOTOGRAPHER) {
            query = `photographerId=${userIdProfile}`;
        }

        if (role === roleEnum.AGENCY) {
            query = `agencyId=${userIdProfile}`;
        }

        return query;
    }

    return {
        createStory,
        filteredStories,
        filterStories,
        getStoryById,
        foundStory,
        updateStory
    }
} 