import React from 'react';
import './Header.scss';
import BoxNextPrev from "../BoxNextPrev/BoxNextPrev";

const Header = ({titleType, className, title, style, showControls, parentId, setControls, thirdLine, fontSize}) => {
    return (
        <header
            className={`section-header secondary-header ${titleType === 2 ? 'secondary-header' : 'tertiary-header'}`}
            style={style}
        >
            <div className={`title-container ${className ?? ''}`}>
                {titleType === 2 &&
                <h2 className="secondary-title" style={{fontSize}} dangerouslySetInnerHTML={{__html: title}}/>
                }
                {titleType === 3 &&
                <h3 className="tertiary-title" style={{fontSize}} dangerouslySetInnerHTML={{__html: title}}/>
                }
                {titleType === 4 &&
                <h4 className="quaternary-title" style={{fontSize}} dangerouslySetInnerHTML={{__html: title}}/>
                }
                {(titleType === 2 || thirdLine) &&
                <div className="header-line" style={{marginLeft: thirdLine ? "10px" : "initial", fontSize}}/>
                }
            </div>
            <div className="header-controls sub">
                {showControls &&
                <BoxNextPrev parentId={parentId} setControls={setControls}
                             showControls={showControls}/>
                }
            </div>
        </header>
    );
};
export default Header;
