import React, {useContext, useState} from 'react';
import './photographer-reviews.scss';
import PhotographerMenu
    from '../../components/PhotographerAction/PhotographerReviews/PhotographerMenu/PhotographerMenu';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {statusEnum} from '../../assets/statics/reviews-step.enum';
import SidebarPhotoReview
    from '../../components/PhotographerAction/PhotographerReviews/PhotographerSidebar/PhotographerSidebar';
import NoImagesGrid from '../../components/Shared/NoImagesGrid/NoImagesGrid';
import {config} from '../../environments';
import {AuthContext} from '../../context/auth-context';
import {useHttpClient} from '../../hooks/http-hook';

const PhotographerReviews = () => {
    const {userIdProfile, token} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();

    const retrieveMediaUrl = `${config.baseUrlDev}/images/filter?priceRequired=false`;

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState();
    const [view, setView] = useState(statusEnum.ACCEPTED);
    const [accepted, setAccepted] = useState([]);
    const [pending, setPending] = useState([]);
    const [declined, setDeclined] = useState([]);

    const detailsHandleClick = (item) => {
        setModalImage(item);
        setModal(true);
    }

    const retrieveAccepted = async () => {
        await sendRequest(
            `${retrieveMediaUrl}&photographerId=${userIdProfile}&status=${statusEnum.ACCEPTED}`,
            'GET',
            null,
            {cookies: token}
        )
            .then((data) => setAccepted(data))
            .catch((error) => console.warn({error}));
    }

    const retrievePending = async () => {
        await sendRequest(
            `${retrieveMediaUrl}&photographerId=${userIdProfile}&status=${statusEnum.PENDING}`,
            'GET',
            null,
            {cookies: token}
        )
            .then((data) => setPending(data))
            .catch((error) => console.warn({error}));
    }

    const retrieveDeclined = async () => {
        await sendRequest(
            `${retrieveMediaUrl}&photographerId=${userIdProfile}&status=${statusEnum.DECLINED}`,
            'GET',
            null,
            {cookies: token}
        )
            .then((data) => setDeclined(data))
            .catch((error) => console.warn({error}));
    }

    return (
        <PageLayout>
            <div className="photographer-reviews-main">
                <PhotographerMenu view={view} setView={setView}/>
                {view === statusEnum.ACCEPTED &&
                <NoImagesGrid className="page-section photos-review"
                              data={accepted}
                              setData={setAccepted}
                              retrieveData={retrieveAccepted}
                              status={statusEnum.ACCEPTED}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                />
                }
                {view === statusEnum.DECLINED &&
                <NoImagesGrid className="page-section photos-review"
                              data={declined}
                              setData={setDeclined}
                              retrieveData={retrieveDeclined}
                              status={statusEnum.DECLINED}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                />
                }
                {view === statusEnum.PENDING &&
                <NoImagesGrid className="page-section photos-review"
                              data={pending}
                              setData={setPending}
                              retrieveData={retrievePending}
                              status={statusEnum.PENDING}
                              mode='photos'
                              onClickDetails={detailsHandleClick}
                />
                }
                {modal &&
                <SidebarPhotoReview image={modalImage} setModal={setModal}/>
                }
            </div>
        </PageLayout>
    )
}

export default PhotographerReviews
