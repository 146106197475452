import React from 'react';
import SecondaryBtn from '../../../Buttons/Template/SecondaryBtn';
import {AiOutlinePlus} from 'react-icons/ai';

const ViewSelection = ({moodboards = [], handleSelectMood, setCurrentView, addToMbViewEnum}) => {
    return (
        <div>
            <div className="moodtop">
                <p>Add to Moodboard</p>
            </div>
            <div className="moodbox">
                {moodboards?.map((mb) => (
                    <div className="moodlinks" onClick={() => handleSelectMood(mb)}>
                        <h5 className="grifitol-bold">{mb?.title}</h5>
                    </div>
                ))}
                {moodboards?.length === 0 &&
                    <p className="text-center">No moodboards</p>
                }
            </div>
            <SecondaryBtn parentAction={() => setCurrentView(addToMbViewEnum.CREATE)}
                          className="btn-default-height btn-full-width"
                          style={{marginLeft: "0", fontSize: "1em"}}
            >
                <AiOutlinePlus className="plus-mood"/>
                Create new Moodboard
            </SecondaryBtn>
        </div>
    )
};
export default ViewSelection;
