import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const ProtectedRoute = ({component: Component, isAuthorized, ...rest}) => {
    return (
        <Route {...rest} render={
            props => {
                if (isAuthorized) {
                    return <Component {...rest} {...props} />
                } else if (isAuthorized === false) {
                    return <Redirect to={{pathname: '/unauthorized'}}/>
                }
            }
        }/>
    )
}
export default ProtectedRoute;
