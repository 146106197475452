import React from 'react';
import './MenuPhotographers.scss';
import {statusEnum} from '../../../../assets/statics/reviews-step.enum';

const MenuPhotographers = ({view, setView}) => {
    return (
        <nav className="photographers-demands-menu">
            <h4>Manage photographers</h4>
            <ul className="menu-list">
                <li className={`menu-item ${view === statusEnum.ACCEPTED && 'active'}`}
                    onClick={() => setView(statusEnum.ACCEPTED)}
                >
                    Our photographers
                </li>
                <li className={`menu-item ${view === statusEnum.PENDING && 'active'}`}
                    onClick={() => setView(statusEnum.PENDING)}
                >
                    Pending demands
                </li>
                <li className={`menu-item ${view === statusEnum.DECLINED && 'active'}`}
                    onClick={() => setView(statusEnum.DECLINED)}
                >
                    Declined
                </li>
            </ul>
        </nav>
    )
}
export default MenuPhotographers;
