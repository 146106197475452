import React, {useState} from "react";
import Header from "../../../Header/Header";
import Banderole from "../../Banderole";

const BigBanderole = (props) => {
    const [leftNav, setLeftNav] = useState(false);
    const [rightNav, setRightNav] = useState(false);

    return (
        <section className="boxed-layout page-section" id={props.containerId}>
            <Header title={props.title} titleType={props.titleType}
                    setControls={{setLeftNav, setRightNav}} showControls={{leftNav, rightNav}}
                    parentId={props.containerId}/>
            <Banderole cards={props.data}
                       isSerie={props.isSerie}
                       type={props.isFake ? 'big-card' : 'public-story'}
                       className="big-card"
                       parentId={props.containerId}
            />
        </section>
    );
};
export default BigBanderole;
