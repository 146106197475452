import React, {useEffect, useState} from 'react';
import './PaymentIntentCard.scss';
import TertiaryBtn from '../../../Shared/Buttons/Template/TertiaryBtn';
import PrimaryBtn from '../../../Shared/Buttons/Template/PrimaryBtn';

// Material UI Card
import {Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import Moment from 'react-moment';
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import {Link, Redirect, useHistory} from 'react-router-dom';

const PaymentIntentCard = ({checkout}) => {
    const [pi, setPi] = useState();
    const {sendRequest} = useHttpClient();
    const piUrl = `${config.baseUrlDev}/stripe/payment-intent`;

    const retrievePaymentIntent = async () => {
        await sendRequest(
            `${piUrl}/${checkout.payment_intent}`,
            'GET'
        )
            .then((piData) => setPi({...piData, checkout}))
            .catch((error) => console.warn(`Can't retrieve payment intent data`, error));
    }

    useEffect(() => retrievePaymentIntent(), [checkout]);

    return (
        pi?.charges?.data && pi?.charges?.data[0]
            ? <Card className="purchase-card">
                <CardHeader subheader={pi?.checkout?.id.replace('cs_test_', '')}/>
                <CardContent className="purchase-card-content">
                    <div className="content-col">
                        <Typography gutterBottom className="detail-text">
                            <span>Placed:</span>
                            <Moment date={new Date(pi?.created * 1000)} format="YY/MM/DD HH:mm:ss"/>
                        </Typography>
                        <Typography gutterBottom className="detail-text">
                            <span>Nb of items:</span>
                            {pi?.checkout?.metadata
                                ? Object.keys(pi?.checkout?.metadata).length
                                : '-'
                            }
                        </Typography>
                        <Typography gutterBottom className="detail-text">
                            <span>Paid by:</span>
                            {pi?.payment_method_types}
                        </Typography>
                        <Typography gutterBottom className="detail-text">
                            <span>Status:</span>
                            {pi?.status}
                        </Typography>
                        <Typography gutterBottom className="detail-text highlight-text">
                            <span>Order Total</span>
                            <span>
                                    {pi.currency}&nbsp;
                                {pi?.amount / 100},
                                {pi?.amount.toString().substring(pi?.amount.toString().length - 2)}
                                </span>
                        </Typography>
                    </div>
                    <div className="content-col">
                        <Link to={`/purchase/details/${pi?.checkout?.id.replace('cs_test_', '')}`}>
                            <PrimaryBtn className="btn-default-height btn-full-width">
                                View details
                            </PrimaryBtn>
                        </Link>
                        <TertiaryBtn className="btn-default-height wired btn-full-width"
                                     style={{marginTop: "0.5rem"}}
                        >
                            Help
                        </TertiaryBtn>
                    </div>
                </CardContent>
            </Card>
            : <></>
    );
}
export default PaymentIntentCard;
