import React, { useEffect, useState } from 'react';
import GetPreparedAll from '../../components/CheckoutAction/PageDetails/GetPrepared/GetPreparedAll';
import OtherSeries from '../../components/CheckoutAction/PageDetails/OtherSeries/OtherSeries';
import SameSeries from '../../components/CheckoutAction/PageDetails/SameSeries/SameSeries';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import { config } from '../../environments';
import { useHttpClient } from '../../hooks/http-hook';
import { Redirect } from 'react-router-dom';

const Pagedetails = ({ match }) => {
    const imageId = match?.params?.imageId;
    const { sendRequest } = useHttpClient();

    const [image, setImage] = useState();

    const imageEndpoint = `${config.baseUrlDev}/images/${imageId}`;

    const retrieveData = async () => {
        return await sendRequest(
            imageEndpoint, 'GET'
        )
            .then((res) => setImage(res.data))
            .catch((error) => setImage(null));
    };

    useEffect(() => {
        retrieveData();
    }, []);

    return (image === null || !imageId
            ? <Redirect to='/not-found'/>
            : (
                <PageLayout>
                    <GetPreparedAll image={image}/>
                    <SameSeries imageId={image?._id} titleType={2}/>
                    <OtherSeries photographerId={image?.photographer} titleType={2}/>
                </PageLayout>
            )
    );
};

export default Pagedetails;
