import React, {useEffect, useState} from "react";
import "./LocationSetting.scss";
import TertiaryBtn from "../../Buttons/Template/TertiaryBtn";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import Toaster from "../../Toaster/Toaster";
import {render} from "@testing-library/react";
import useReadableAddress from '../../../../hooks/useReadableAddress';

const addressElementEnum = {
    STREET_NUMBER: {key: 'street_number', label: 'streetNumber'},
    STREET: {key: 'route', label: 'street'},
    ADM_AREA: {key: 'administrative_area_level_1', label: 'city'},
    CITY: {key: 'locality', label: 'city'},
    POSTAL_CODE: {key: 'postal_code', label: 'postalCode'},
    COUNTRY: {key: 'country', label: 'country'},
};
const addressElementArray = Object.keys(addressElementEnum).map((k) => addressElementEnum[k]);

function formatAddressData(addressObject = {}) {
    const a = {};
    addressElementArray.map((el) => {
        addressObject.map((aEl) => {
            if (aEl.types.includes(el.key)) {
                a[el.label] = aEl.long_name;
            }
        });
    });
    return a;
}

const LocationSetting = ({parentAction, address}) => {
    const [addressMap, setAddressMap] = useState("");
    const [addressObj, setAddressObj] = useState();
    const {getAddress, addressTypeEnum} = useReadableAddress();

    const onSubmit = (event) => {
        event.preventDefault();
        parentAction({address: addressObj}, () => render(<Toaster success toastMsg="Location successfuly updated"/>));
    };

    const handleChange = (address) => {
        setAddressMap(address);
    };

    const handleSelect = (address) => {
        geocodeByAddress(address).then((res) => {
            setAddressObj(formatAddressData(res[0]?.address_components));
        });
        setAddressMap(address);
    };

    useEffect(() => {
        if (address) {
            setAddressMap(getAddress(address, addressTypeEnum.FULL));
        }
    }, [address]);

    return (
        <section className="personal-infos-container">
            <h4>Location</h4>

            <form onSubmit={onSubmit} className="personal-infos-form">
                <div className="field-group">
                    <label htmlFor="address">Where currently are you?</label>
                    <PlacesAutocomplete
                        value={addressMap}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({
                              getInputProps,
                              getSuggestionItemProps,
                              suggestions,
                              loading,
                          }) => {
                            return (
                                <>
                                    <div className="autocomplete-root">
                                        <input
                                            {...getInputProps({
                                                placeholder: address,
                                                className: `input-reg`,
                                                name: "address",
                                                autoFocus: true,
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active ? "active" : "";
                                                // inline style for demonstration purpose
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className: `suggestion-item ${className}`,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                    </PlacesAutocomplete>
                </div>
                <div className="field-group btn-container">
                    <TertiaryBtn style={{padding: "0.5rem 1rem"}} className="tertiary">
                        Cancel
                    </TertiaryBtn>
                    <button
                        type="submit"
                        className={`button secondary`}
                        style={{padding: "0.5rem 1rem"}}
                    >
                        Save
                    </button>
                </div>
            </form>
        </section>
    );
};

export default LocationSetting;
