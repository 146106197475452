import React, { useContext, useState } from 'react';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout';

import { StoryForm } from '../../../components/PhotographerAction/Story/Form'

import { usePhotographerAgencies } from '../../../hooks/photographer-agencies-hook';
import { useImages } from '../../../hooks/images/useImage';
import { AuthContext } from '../../../context/auth-context';
import { useStory } from '../../../hooks/stories/useStory';


export function AddStoryFormContainer () {

    const {userIdProfile, userType} = useContext(AuthContext);
    
    const {photographerAgencies} = usePhotographerAgencies(userIdProfile);

    const { filterAcceptedImages, filteredImages, isLoading } = useImages();
    
    const { createStory } = useStory();
    
    const [openSelectImagesModal, setOpenSelectImagesModal] = useState(false);
    const [keywords, setKeywords] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);

    async function handleSubmit (formValues) {
        formValues.keywords = keywords;
        formValues.images = selectedImages
        await createStory(formValues);
    }

    async function handleSelectAgency (selectedAgencyId) {
        setSelectedImages([]);
        setSelectedAgency(selectedAgencyId);
        await filterAcceptedImages(userIdProfile, selectedAgencyId);
    }

    function toggleImage (imageId) {
        if (selectedImages.includes(imageId)) {
            const i = selectedImages.indexOf(imageId);
            const sI = [...selectedImages];
            sI.splice(i, 1);
            setSelectedImages(sI);
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }

    function handleClose () {
        setOpenSelectImagesModal(false);
    }

    return(
        <PageLayout>
            <StoryForm
                onSubmit={handleSubmit} 
                photographerAgencies={photographerAgencies}
                userType={userType}
                keywords={keywords}
                updateKeywordsList={setKeywords}
                selectedAgency={selectedAgency}
                onSelectAgency={handleSelectAgency}
                selectedImages={selectedImages}
                isLoading= {isLoading}
                agencyPhotographerImages={filteredImages}
                toggleImage={toggleImage}
                setOpenSelectImagesModal={setOpenSelectImagesModal}
                openSelectImagesModal={openSelectImagesModal}
                handleClose={handleClose}
             />
        </PageLayout>
    )
}