import React from 'react';
import {NavLink} from 'react-router-dom';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import Name from '../../name';

const UserLinks = (props) => {
    return (
        <>
            <li>
                <NavLink className="links" to="/explore">
                    Explore
                </NavLink>
            </li>
            <li>
                <div className="links user">
                    <span onClick={props.dropdownHandler}>
                        <Name placeholder="Client"/>
                    </span>
                    <ProfileMenu dropDown={props.dropDown} dropdownHandler={props.dropdownHandler}/>
                </div>
            </li>
        </>
    )
};
export default UserLinks;
