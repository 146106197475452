import React, {useEffect, useState} from "react";
import "./MarketType.scss";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../../Shared/Forms/validators-final-form';
import {searchParamsEnum} from '../../../../../assets/statics/search-params.enum';

const MarketType = (props) => {
    const formTitle = 'Market Type';

    const [types, setTypes] = useState([]);

    const onSubmit = (formValues) => {
        props.parentAction(formValues);
    }

    useEffect(() => {
        setTypes(Object.keys(searchParamsEnum).map((kw) => searchParamsEnum[kw]));
    }, [searchParamsEnum]);

    return (
        <div className="box-credits-edit">
            <AuthentHeader title={formTitle}/>
            <Form initialValues={props.originalData}
                  onSubmit={onSubmit}
                  render={({submitError, handleSubmit, submitting}) => (
                      <form onSubmit={handleSubmit} className="login-form">
                          <Field name="licence" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="licence">Select from existing</label>
                                      <select {...input} type="select"
                                              id="licence"
                                              className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                      >
                                          <option/>
                                          {types.map((a, k) => <option value={a.key}
                                                                       key={k}>{a.label}</option>)}
                                      </select>
                                  </div>
                              )}
                          </Field>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                              style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                          >
                                              Save
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )}
            />
        </div>
    );
};

export default MarketType;
