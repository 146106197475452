import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { config } from '../environments';

export const usePhotographerAgencies = (photographerId) => {
    const { token } = useContext(AuthContext);

    const [photographerAgencies, setPhotographerAgencies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${config.baseUrlDev}/agency/${photographerId}/all`, { headers: { cookies: token  } })
            .then((response) => {
                setPhotographerAgencies(response.data);
            }).finally(() => {
                setIsLoading(false);
            })
      }, [setIsLoading, setPhotographerAgencies, photographerId, token]);

    return { photographerAgencies, isLoading };
}