import React from "react";
import PicturesGrid from "../../Pictures/PicturesGrid/PicturesGrid";

const MoodBoard = (props) => {
    const fakeData = {
        data: [
            {main: 'bg-test.jpg'},
            {main: 'bg-test2.jpg'},
            {main: 'bg-explore1.jpg'},
            {main: 'bg-explore2.jpg'},
            {main: 'bg-explore3.jpg'},
            {main: 'bg-explore4.jpg'},
            {main: 'bg-explore5.jpg'},
            {main: 'bg-explore6.jpg'},
            {main: 'bg-explore7.jpg'},
            {main: 'bg-explore2.jpg'},
        ],
    };
    return <PicturesGrid displayMode={props.displayMode} data={fakeData.data}/>;
};
export default MoodBoard;
