/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { roleEnum } from '../../../assets/statics/authent-signup.enum';
import { StoryForm } from '../../../components/PhotographerAction/Story/Form';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout';
import { AuthContext } from '../../../context/auth-context';
import { config } from '../../../environments';
import { useImages } from '../../../hooks/images/useImage';
import { usePhotographerAgencies } from '../../../hooks/photographer-agencies-hook';
import { useStory } from '../../../hooks/stories/useStory';
import {useHistory} from 'react-router-dom';
import { useHttpClient } from '../../../hooks/http-hook';


export function EditStoryFormContainer ({match}) {
    const storyId = match?.params?.storyId;
    const getStoryUrl = `${config.baseUrlDev}/story/${storyId}`;
    const {userIdProfile, userType} = useContext(AuthContext);
    const {photographerAgencies} = usePhotographerAgencies(userIdProfile);
    const { filterAcceptedImages, filteredImages, isLoading } = useImages();
    const { updateStory, getStoryById, foundStory } = useStory();
    const history = useHistory();
    const [openSelectImagesModal, setOpenSelectImagesModal] = useState(false);
    const [keywords, setKeywords] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const {sendRequest} = useHttpClient();

    async function handleSubmit (formValues) {
      
        formValues.keywords = keywords;
        formValues.images = selectedImages
        await updateStory(storyId, formValues);
    }

    async function handleSelectAgency (selectedAgencyId) {
        setSelectedImages([]);
        setSelectedAgency(selectedAgencyId);
        await filterAcceptedImages(userIdProfile, selectedAgencyId);
    }

    async function toggleImage (imageId) {
        if (selectedImages.includes(imageId)) {
            const i = selectedImages.indexOf(imageId);
            const sI = [...selectedImages];
            sI.splice(i, 1);
            setSelectedImages(sI);
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }

    function handleClose () {
        setOpenSelectImagesModal(false);
    }

    useEffect(async () => {
        if(storyId) {
            await sendRequest(getStoryUrl, 'GET')
            .then((data) => {

               if(data.photographerId === userIdProfile ){  getStoryById(storyId);
            }else{
                history.push("/not-found");
            }
               

            })
            .catch((e) => history.push('/not-found'));
        
        }
     },[storyId])
 
     useEffect(async () => {
         if(selectedAgency) {
             await filterAcceptedImages(userIdProfile, selectedAgency);
         }
     },[selectedAgency])
 
     useEffect(async () => {
         setKeywords(foundStory?.keywords);
         setSelectedImages(foundStory?.images);
         setSelectedAgency(foundStory?.agencyId);
     },[foundStory])

    return (
        <PageLayout>
            <StoryForm
                initialValues={foundStory}
                onSubmit={handleSubmit} 
                photographerAgencies={photographerAgencies}
                userType={userType}
                keywords={keywords}
                updateKeywordsList={setKeywords}
                selectedAgency={selectedAgency}
                onSelectAgency={handleSelectAgency}
                selectedImages={selectedImages}
                isLoading= {isLoading}
                agencyPhotographerImages={filteredImages}
                toggleImage={toggleImage}
                setOpenSelectImagesModal={setOpenSelectImagesModal}
                openSelectImagesModal={openSelectImagesModal}
                handleClose={handleClose}
            />
        </PageLayout>
    )

}