import React from "react";
import "./Community.css";

const Community = () => {
  return (
    <>
      <div className="section-community">
        <div className="container-community-left">
          <div className="box-join-community">
            <div className="join-community">
              <h2>
                Join our <br /> Community
              </h2>
            </div>
          </div>
          <div className="box-btns-community">
            <div className="btn-join-community">Join Our Community</div>
            <div className="btn-first-community">Be the first to know</div>
          </div>
        </div>
        <div className="container-community-right">
          <div className="community-grey"></div>
          <div className="line-community"></div>
          <div className="box-community-paragraph">
            <div className="content-community-paragraph">
              <p>
                We imagine a future where photographs, illustrations,
                infographics, video content and more will be both secure and
                flexible, open and profitable, widely seen and always traceable.
                They are pillars of a whole new economy that will change how we
                consume stories (and sustain storytellers) in the quest for a
                more fruitful and equitable digital ecosystem. Will you join us?
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
