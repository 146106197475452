import React from "react";
import Pdftypepage from "../components/Purchases/PdfGenerate/pdftypepage";

const PdfTypepage = ({ match }) => {
  return (
    <>
      <Pdftypepage imageId={match?.params?.imageId} />
    </>
  );
};

export default PdfTypepage;
