import {config} from '../../environments';

export const formTitle = 'Register';
export const roleEnum = {
    AGENCY: 'Agency',
    PHOTOGRAPHER: 'Photographer',
    MEDIA_USER: 'Media',
    USER: 'User',
    ADMIN: 'Admin',
}
export const stepEnum = {
    STEP_ROLE: 'role_step',
    STEP_PERSONAL: 'personal_step',
    STEP_COMPANY: 'company_step',
}
export const formEnum = {
    STEP_FIRST: 'first_step',
    STEP_PROGRESS: 'progress_step',
    STEP_END: 'last_step',
    STEP_SUBMIT: 'submit_step',
};
export const subEnum = {
    STEP_FIRST: 'Choose a role',
    STEP_PROGRESS: 'Step',
}
export const endpointEnum = {
    REGISTRATION: `${config.baseUrlDev}/auth/register`
}
