export const searchParamsEnum = {
    EDITORIAL: {key: 'licence_editorial', label: 'Editorial'},
    ART_MARKET: {key: 'licence_art_market', label: 'Art market'},
    CULTURAL: {key: 'licence_cultural', label: 'Cultural'},
    CORPORATE: {key: 'licence_corporate', label: 'Corporate'},
}

export const findLicenceLabel = (keyword) => {
    let l;
    Object.keys(searchParamsEnum).map((k) => {
        if (searchParamsEnum[k].key === keyword) {
            l = searchParamsEnum[k].label;
        }
    });
    return l;
}
