import React, {useEffect, useState} from "react";
import "./InputDropdown.scss";
import {IoMdArrowDropdown} from "react-icons/io";

const InputDropdown = (props) => {
    const [state, setState] = useState(false);
    const [value, setValue] = useState(null);
    const [id, setId] = useState(null);
    const [placeholder, setPlaceholder] = useState(null);
    const [defaultData] = useState([
        {serie: "serie1"},
        {serie: "serie2"},
        {serie: "serie3"},
        {serie: "serie4"},
    ]);

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value])

    const completeDropdown = (value, id) => {
        id ? setId(id) : setValue(value);
        setPlaceholder(value);
        setState(false)
    }

    useEffect(() => {
        if (props.parentAction && value) {
            props.parentAction(props.id, value.trim());
        }
    }, [value])
    useEffect(() => {
        if (props.placeholder) {
            setPlaceholder(props.placeholder);
           
        }
    }, [props.placeholder]) 

    useEffect(() => {
        if(props.selectedSeries) {
            completeDropdown(props.selectedSeries.title, props.selectedSeries._id)
        }
    }, [props.selectedSeries])

    useEffect(() => {
        if (props.parentAction && id) {
            props.parentAction(props.id, id.trim());
        }
    }, [id])
    


    return (
        <div >
            <div ref={props.parentRef}
                 className={`input-dropdown ${props.className && props.className}`}
                 onClick={() => setState(!state)}
                 id={props.id}
                 
                 style={{
                     margin: props.margin,
                     background: props.bg,
                     position: props.position,
                     left: props.left,
                     right: props.right,
                     top: props.top,
                     bottom: props.bottom,
                     height: props.height,
                     width: props.width,
                 }}
            >
                {props.children ? props.children : placeholder}
                <IoMdArrowDropdown className="dropdown-arr"/>
            </div>

            {props.data ? (
                <>
                    {state && (
                        <div className="dropdown-box-datas">
                            {state &&
                            props.data.map((val, k) => {
                                if (props.type !== 'agencies-list') {
                                    return (
                                        <div className="datas-input-dropdown" data-value={val.tile}
                                             onClick={() => props.onSelectSeries(val)} key={k}>
                                            {val.title} 
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="datas-input-dropdown" data-value={val._id}
                                             onClick={() => completeDropdown(val.companyName, val._id)} key={k}>
                                            {val.companyName}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {state && (
                        <div className="dropdown-box-datas">
                            {state &&
                            defaultData.map((val, k) => {
                                return (
                                    <div className="datas-input-dropdown" key={k}>
                                        {val.serie}
                                        <hr/>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default InputDropdown;
