import React, {useContext} from "react";
import "../SettingPassword/SettingPassword.scss";
import {Field, Form, FormSpy} from 'react-final-form';
import {required, requiredPhone} from '../../Forms/validators-final-form';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import {AuthContext} from '../../../../context/auth-context';
import Toaster from "../../Toaster/Toaster";
import { render } from "@testing-library/react";

const SettingContact = ({email, phoneNumber, parentAction}) => {
    const onSubmit = (formValues) => {
        parentAction({currentPassword: formValues.currentPassword, password: formValues.password},() =>  render(<Toaster success toastMsg="Contact infos successfuly updated"/>));
    }
    return (
        <section className="personal-infos-container">
            <h4>Contact</h4>
            <Form
                initialValues={{email, phoneNumber}}
                onSubmit={onSubmit}
                render={({form, submitError, handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="personal-infos-form">
                        <Field name="email" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="email">Email contact</label>
                                    <input {...input} type="text"
                                           id="email"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Email contact"/>
                                </div>
                            )}
                        </Field>
                        <Field name="phoneNumber" validate={requiredPhone}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input {...input} type="text"
                                           id="phoneNumber"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Phone Number"/>
                                </div>
                            )}
                        </Field>
                        <div className="field-group btn-container">
                            <TertiaryBtn style={{padding: "0.5rem 1rem"}}
                                         parentAction={() => form.reset()}
                            >
                                Cancel
                            </TertiaryBtn>
                            <FormSpy subscription={{invalid: true}}>
                                {props => {
                                    const isInvalid = submitting || props.invalid;
                                    return (
                                        <button
                                            type="submit"
                                            disabled={isInvalid}
                                            className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                            style={{padding: "0.5rem 1rem"}}
                                        >
                                            Save
                                        </button>
                                    )
                                }}
                            </FormSpy>
                        </div>
                        {submitError &&
                        <div>
                            <div className="authent-error">{submitError}</div>
                        </div>
                        }
                    </form>
                )}
            />
        </section>
    );
};
export default SettingContact;
