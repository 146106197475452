import React, {useContext} from 'react';
import './SimpleImageGrid.scss';
import {BackgroundImg} from 'react-cloudimage-responsive';
import Image from '../Pictures/Image/Image';
import {AuthContext} from '../../../context/auth-context';
import {roleEnum} from '../../../assets/statics/authent-signup.enum';

const SimpleImageGrid = ({images = [], toggleImage, mode, selectedImages}) => {
    const {userType} = useContext(AuthContext);
    const isPhotographer = userType === roleEnum.PHOTOGRAPHER;

    const isEmpty = images.length === 0;

    return (
        <section className="page-section boxed-layout" style={{marginBottom: "0"}}>
            <div className="simple-images-grid gl-col">
                {images.map((img, k) => (
                    <Image imageId={img}
                           key={k}
                           mode={mode}
                           isPhotographer={isPhotographer}
                           className={`images-grid-item gl-col-3 ${mode === 'edit' ? selectedImages.includes(img) ? 'image-selected' : '' : ''}`}
                           parentAction={isPhotographer ? toggleImage : null}
                    />
                ))}
                {isEmpty &&
                <h4 className="text-center" style={{width: "100%"}}>No
                    image {mode === 'edit' ? `available in this agency` : 'selected'}</h4>
                }
            </div>
        </section>
    );
};
export default SimpleImageGrid;
