import React, {useContext} from 'react';
import Navbars from './Navbar/navbar';
import Footer from './Footer/Footer';
import Cart from './Navbar/Cart/Cart';
import {ShopContext} from '../../../context/shopContext';

const PageLayout = (props) => {
    const {isCartopen} = useContext(ShopContext)
    return (
        <div className="page">
            <Navbars/>
            <div className="page-content" style={props.style}>
                {props.children}
            </div>
            {isCartopen && <Cart/>}
            <Footer/>
        </div>
    )
}
export default PageLayout;
