import React from 'react'
import Statistics from '../../components/Shared/Statistics/Statistics';
import MyImages from '../../components/Shared/Statistics/MyImages/MyImages';
import MySeries from '../../components/Shared/Statistics/MySeries/MySeries';
import MyExhibition from '../../components/Shared/Statistics/MyExhibition/MyExhibition';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const PhotographerDashboard = () => {
    return (
        <PageLayout>
            <Statistics/>
            <MyImages titleType={3}/>
            <MySeries titleType={3}/>
            <MyExhibition titleType={3}/>
        </PageLayout>
    )
};
export default PhotographerDashboard
