import React, {Component, createContext} from "react";

const UploadContext = createContext();

class PhotoUploadProvider extends Component {
    state = {
        formValues: {
            title: "",
            credit: "",
            file: "",
        },
        uploaded: []
    };

    setCurrentActive = (element) => {
        this.setState({currentActive: element});
    };

    setUploaded = (res) => {
        this.setState({uploaded: [...this.state.uploaded, res]});
    }

    formModify = (val) => {
        this.setState({formValues: {...this.state.formValues, ...val}});
    };

    handleChange = (ref) => {
        const newVal = {};
        newVal[ref.current.id] = ref.current.value;
        this.formModify({...newVal});
    };

    render() {
        return (
            <UploadContext.Provider
                value={{
                    ...this.state,
                    formModify: this.formModify,
                    handleChange: this.handleChange,
                    editState: this.state,
                    setUploaded: this.setUploaded,
                    handleDropdownChange: this.handleDropdownChange,
                }}
            >
                {this.props.children}
            </UploadContext.Provider>
        );
    }
}

export {UploadContext};
export default PhotoUploadProvider;
