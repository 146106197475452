import React, { Suspense, useCallback, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import ShopProvider from "./context/shopContext";
// styletron imports
import { DebugEngine, Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import LoadingSpinner from "./shared/LoadingSpinner";

import Testregistertest from "./pages/Testregistertest";

import RegistrationPage from "./pages/Registration/registration";
import Login from "./pages/Login/login";
import useToken from "./hooks/useToken";

import About from "./pages/About/about";
import Explore from "./pages/Explore/explore";
import Pagedetails from "./pages/PageImage/pagedetails";
import PhotographerPage from "./pages/PagePhotographer/photographerpage";
import AgencyPage from "./pages/PageAgency/agencypage";
import MicroPaymentPage1 from "./pages/Micropayment/micropayment1";
import MicroPayment2 from "./pages/Micropayment/micropayment2";
import PhotographerDashboard from "./pages/PhotographerDashboard/photgrapherDashboard";
import AgencyDashboard from "./pages/AgencyDashboard/agency-dashboard";
import AgencyReviews from "./pages/AgencyReviews/agency-reviews";
import ProfileSettingsPage from "./pages/ProfileSettings/profilesettingspage";
import CertificatedGenerated from "./pages/CheckoutResult/CertificateGenerated/CertificatedGenerated";
import Order1 from "./pages/Order/order";
import MoodBoardjscript from "./pages/PageMoodboard/moodboardjscript";
import PhotographerReviews from "./pages/PhotographerReviews/photographer-reviews";
import CheckOut from "./pages/Checkout/CheckOut";
import Mymoodboardlist from "./pages/MoodboardsLists/mymoodboardlist";
import AdminDashboard from "./pages/AdminDashboard/admindashboard";
import PhotogEditUploaded from "./pages/EditUpload/photogEditUploaded";
import AgencyDemandsPhotographers from "./pages/AgencyPhotographers/agency-photographers";
import Formuploadimage from "./pages/PhotographerUpload/formuploadimage";
import AdminDemands from "./pages/AdminDemands/admin-demands";
import NotFound from "./pages/NotFound/NotFound";

// Protected routes
import ProtectedRoute from "./pages/ProtectedRoute/ProtectedRoute";
import { statusEnum } from "./assets/statics/reviews-step.enum";
import { roleEnum } from "./assets/statics/authent-signup.enum";
import ValidatePurchase from "./components/CheckoutAction/ValidatePurchase/ValidatePurchase";
import { errorCodeEnum } from "./assets/statics/error-code.enum";
import ValidateRegistration from "./components/Shared/ValidateRegistration/ValidateRegistration";
import PurchasesPage from "./pages/PurchasesPage/purchases-page";
import PagePurchase from "./pages/PagePurchase/PagePurchase";
import { config } from "./environments";
import { useHttpClient } from "./hooks/http-hook";
import Toaster from "./components/Shared/Toaster/Toaster";
import { AddMoodboardFormContainer } from "./pages/Moodboard/add/FormContainer";
import { EditMoodboardFormContainer } from "./pages/Moodboard/edit/FormContainer";
import { AddSeriesFormContainer } from "./pages/Series/add/FormContainer";
import { EditSeriesFormContainer } from "./pages/Series/edit/FormContainer";
import { SeriesList } from "./pages/Series/list/SeriesList";
import { AddStoryFormContainer } from "./pages/Story/add/FormContainer";
import { StoriesList } from "./pages/Story/list/StoriesList";
import { EditStoryFormContainer } from "./pages/Story/edit/FormContainer";
import PdfTypepage from "./pages/PdfTypepage";
import StoryDetail from "./pages/Story/storyDetails";
import { render } from "@testing-library/react";
import PageSerie from './pages/PageSerie/page-serie';

const Home = React.lazy(() => import("./pages/HomePage/home"));

//instanciating the styletron engine start
const engine = new Styletron();
//styletron debug engine
const debug =
  process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

function App() {
  const {
    token,
    status,

    setToken,
    tokenRole,
    tokenId,
    tokenClear,
    tokenIdProfile,
    tokenCustomerId,
    tokenRemainingTime,
  } = useToken();

  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userType, setUserType] = useState();
  const [userId, setUserId] = useState();
  const [user, setUser] = useState();

  const { sendRequest } = useHttpClient();

  const login = useCallback((loginToken) => {
    try {
      setToken(loginToken);
    } catch (e) {
      logout(e);
    }
  }, []);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
      setUserType(tokenRole);
      setUserId(tokenId);
      setTimeout(
        () => logout(`time out : ${tokenRemainingTime}`),
        tokenRemainingTime
      );
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  // USER INFO
  const infosEndpoint = `${config.baseUrlDev}/user/${userId}`;
  const updateInfosEndpoint = `${config.baseUrlDev}/user/update/${userId}`;

  const retrievePersonalInfos = async () => {
    return await sendRequest(infosEndpoint, "GET", null, {
      "Content-Type": "application/json",
      cookies: token,
    })
      .then((data) => setUser(data))
      .catch((e) => {
        console.warn({ e });
        render(<Toaster error toastMsg="Unexpected error !" />);
      });
  };

  const updatePersonalInfos = async (
    body,
    headers,
    callback,
    errorCallback
  ) => {
    return await sendRequest(updateInfosEndpoint, "PUT", body, headers)
      .then((data) => {
        retrievePersonalInfos();
        return callback ? callback(data) : null;
      })
      .catch((e) => {
        console.warn({ e });
        return errorCallback ? errorCallback(e) : null;
      });
  };

  useEffect(() => {
    if (userId) {
      retrievePersonalInfos();
    }
  }, [userId]);

  const logout = useCallback((e) => {
    if (e) {
      console.warn(e);
    }
    setIsLoggedIn(false);
    setUserType(null);
    setUserId(null);
    tokenClear();
    setToken();
    render(<Toaster info toastMsg="Logged out !" id={"logout"} />);
  }, []);

  const isAcceptedAdmin =
    tokenRole === roleEnum.ADMIN && status === statusEnum.ACCEPTED;
  const isAcceptedAgency =
    tokenRole === roleEnum.AGENCY && status === statusEnum.ACCEPTED;
  const isAcceptedPhotographer =
    tokenRole === roleEnum.PHOTOGRAPHER && status === statusEnum.ACCEPTED;

  const routes = (
    <>
      <Route exact path="/" component={Home} />

      <Route
        exact
        path="/moodboard/details/:moodboardId?"
        component={MoodBoardjscript}
      />
      <ProtectedRoute exact path="/story/details/:storyId?" component={StoryDetail}  isAuthorized={isAcceptedPhotographer || isAcceptedAgency}/>

      <Route exact path="/about" component={About} />

      <Route exact path="/test2" component={Testregistertest} />

      <Route exact path="/register" component={RegistrationPage} />

      <Route
        exact
        path="/validateregistration"
        component={ValidateRegistration}
      />

      <Route exact path="/explore" component={Explore} />

      <Route exact path="/image/details/:imageId?" component={Pagedetails} />

      <Route exact path="/photographer/details" component={PhotographerPage} />

      <Route exact path="/agency/details/:agencyId?" component={AgencyPage} />

      <Route exact path="/login" component={Login} />

      <ProtectedRoute
        exact
        path="/checkout"
        component={CheckOut}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/micropayment2"
        component={MicroPayment2}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/micropayment1"
        component={MicroPaymentPage1}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/order"
        component={Order1}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/checkout/result"
        component={CertificatedGenerated}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/checkout/success"
        component={ValidatePurchase}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/purchases"
        component={PurchasesPage}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/purchase/details/:purchaseId?"
        component={PagePurchase}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/pdf/:imageId?"
        component={PdfTypepage}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/profile/settings"
        component={ProfileSettingsPage}
        isAuthorized={isLoggedIn}
      />

      <ProtectedRoute
        exact
        path="/agency/dashboard"
        component={AgencyDashboard}
        isAuthorized={isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/agency/reviews"
        component={AgencyReviews}
        isAuthorized={isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/agency/photographers"
        component={AgencyDemandsPhotographers}
        isAuthorized={isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/moodboard/list"
        component={Mymoodboardlist}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/moodboard/add"
        component={AddMoodboardFormContainer}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute
        exact
        path="/moodboard/edit/:moodboardId"
        component={EditMoodboardFormContainer}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <Route
        exact
        path="/serie/details/:serieId"
        component={PageSerie}
      />

      <ProtectedRoute
        exact
        path="/series/add"
        component={AddSeriesFormContainer}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute
        exact
        path="/series/edit/:seriesId"
        component={EditSeriesFormContainer}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/series/list"
        component={SeriesList}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/story/add"
        component={AddStoryFormContainer}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute

        exact
        path="/story/edit/:storyId"
        component={EditStoryFormContainer}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/story/list"
        component={StoriesList}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/photographer/dashboard"
        component={PhotographerDashboard}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute
        exact
        path="/photographer/reviews"
        component={PhotographerReviews}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute
        exact
        path="/upload"
        component={Formuploadimage}
        isAuthorized={isAcceptedPhotographer}
      />

      <ProtectedRoute
        exact
        path="/upload/edit/:imageId?"
        component={PhotogEditUploaded}
        isAuthorized={isAcceptedPhotographer || isAcceptedAgency}
      />

      <ProtectedRoute
        exact
        path="/admin/dashboard"
        component={AdminDashboard}
        isAuthorized={isAcceptedAdmin}
      />

      <ProtectedRoute
        exact
        path="/admin/reviews"
        component={AdminDemands}
        isAuthorized={isAcceptedAdmin}
      />

      <Route path="/unauthorized" exact>
        <NotFound error={errorCodeEnum.NOT_AUTHORIZED} />
      </Route>

      <Route path="/not-found" exact>
        <NotFound error={errorCodeEnum.NOT_FOUND} />
      </Route>
    </>
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        userStatus: status,
        userId,
        user,
        userType,
        customerId: tokenCustomerId,
        userIdProfile: tokenIdProfile,
        updatePersonalInfos,
        retrievePersonalInfos,
        token,
      }}
    >
      <Router>
        <ShopProvider>
          <StyletronProvider value={engine} debug={debug} debugAfterHydration>
            <Switch>
              <main>
                <Suspense
                  fallback={
                    <div className="center">
                      <LoadingSpinner />
                    </div>
                  }
                >
                  {routes}
                </Suspense>{" "}
              </main>

              <Redirect to="/" />
            </Switch>
            <Router>
              <Switch />
            </Router>
          </StyletronProvider>
        </ShopProvider>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
