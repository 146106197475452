import React from "react";
import "./MoreFilter.css";
import TimeframeFilter from './TimeframeFilter/TimeframeFilter';
import FramingFilter from './FramingFilter/FramingFilter';


const MoreFilter = ({searchValues, updateSearchValues}) => {
    const updatePeriod = (period) => {
        updateSearchValues({...searchValues, period})
    }

    return (
        <div className="more-filter" style={{marginTop: "1.5rem"}}>
            <p style={{marginBottom: "0"}}>More Filters</p>
            <TimeframeFilter period={searchValues.period}
                             updatePeriod={updatePeriod}
            />
            <FramingFilter/>
        </div>
    );
};

export default MoreFilter;
