/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import BtnBack from "../../Shared/Buttons/BtnBack/BtnBack";
import CreditsData from "./Data/CreditsData/CreditsData";
import MetadataData from "./Data/MetadataData/MetadataData";
import TitlenDesc from "./Data/TitlenDesc/TitlenDesc";
import LinksLeft from "./LinksLeft/LinksLeft";
import {EditContext} from "./context/EditContext";
import "./PhotogEditUp.scss";
import EDIT_PHOTO_ENUM from "../../../assets/statics/photo-edit.enum";
import RightSideEdit from "./RightSideEdit/RightSideEdit";
import MColorsData from "./Data/MColorsData/MColorsData";
import {useHttpClient} from "../../../hooks/http-hook";
import SpecInstructions from "./Data/SpecInstructions/SpecInstructions";
import AgencyData from "./Data/AgencyData/AgencyData";
import KeywordsData from "./Data/KeywordsData/KeywordsData";
import MarketType from "./Data/MarketType/MarketType";
import {config} from "../../../environments";
import {AuthContext} from "../../../context/auth-context";
import {roleEnum} from '../../../assets/statics/authent-signup.enum';
import TertiaryBtn from '../../Shared/Buttons/Template/TertiaryBtn';
import SecondaryBtn from '../../Shared/Buttons/Template/SecondaryBtn';
import {Redirect} from 'react-router-dom';
import SeriesData from "./Data/SeriesData/SeriesData";
import { render } from "@testing-library/react";
import Toaster from "../../Shared/Toaster/Toaster";
import CertifMobile from "./Mobile/CertifMobile";
import PixtCertif from '../../../assets/images/pixtCertif.png'

const BtnContainer = (props) => {
    return (
        <div className="btns-row-up">
            <TertiaryBtn className="btn-default-width"
                         parentAction={() => props.handleBtnClick(props.type, false)}
            >
                Prev
            </TertiaryBtn>
            <SecondaryBtn className="btn-default-width"
                          parentAction={() => {
                              props.handleBtnClick(props.type, true)
                          }}
            >
                Next
            </SecondaryBtn>
        </div>
    )
}

const PhotogEditUp = ({imageId}) => {
    const imageEndpoint = `${config.baseUrlDev}/images/no-control/${imageId}`;
    const updateEndpoint = `${config.baseUrlDev}/images/${imageId}/update`;
    const {token, userType} = useContext(AuthContext);

    const [error, setError] = useState();

    const {sendRequest} = useHttpClient();
    const {
        setCurrentActive,
        editState,
        setEditState,
        formValues,
        formModify,
    } = useContext(EditContext);

    const [originalData, setOriginalData] = useState();
    const [mobCertif, setMobCertif] = useState(true);

    const updateEditChanges = async (formData) => {
        setError();
        await sendRequest(updateEndpoint, 'POST', JSON.stringify(formData), {
            "Content-Type": "application/json",
            cookies: token,
        })
            .then(() => {
                retrieveData()
                render(<Toaster success toastMsg="Updated successfuly !"/>)
            })
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg="An error occured !"/>)
            });
    }

    const retrieveData = async () => {
        return await sendRequest(imageEndpoint, "GET", null, {cookies: token})
            .then((res) => setOriginalData({...res.data, agency: res.data?.agency?._id}))
            .catch((error) => {
                setOriginalData(null)
                console.log(error)
                render(<Toaster error toastMsg="An error occured during datas retrieve !"/>)
            });
    };

    useEffect(() => {
        if (originalData) {
            const fetched = {...originalData};
            if (fetched.IPTCmetadata && !Array.isArray(fetched.IPTCmetadata)) {
                fetched.iptc = fetched.IPTCmetadata;
            }
            if (fetched.EXIFmetadata && !Array.isArray(fetched.EXIFmetadata)) {
                fetched.exif = fetched.EXIFmetadata;
            }
            formModify(fetched);
        }
    }, [originalData]);

    useEffect(() => {
        retrieveData();
    }, []);

    const handleBtnClick = (element, value) => {
        const next = EDIT_PHOTO_ENUM[element].next;
        const prev = EDIT_PHOTO_ENUM[element].prev;
        setEditState(EDIT_PHOTO_ENUM[element].key, value);
        if (value === true) {
            if (next) {
                setCurrentActive(next);
            }
        } else {
            if (prev) {
                setCurrentActive(prev);
            }
        }
    };
    
    return originalData === null || !imageId
        ? <Redirect to="/not-found"/>
        : (
            <div style={{paddingTop: "100px"}}>
                <BtnBack position="absolute" bg="#FFE942">
                    Back
                </BtnBack>
                <div className="section-ph-edit-uploaded">
                    <div className="cont-ph-editup-l">
                        <div className="left-links-up">
                            <h4>Edit Upload's data</h4>
                            <ul style={{paddingLeft: "0", listStyleType: "none"}}>
                                {Object.keys(EDIT_PHOTO_ENUM).map((key, k) => {
                                    if (EDIT_PHOTO_ENUM[key]?.title) {
                                        const el = (<LinksLeft
                                            parentAction={setCurrentActive}
                                            setCurrent={key}
                                            logs={key}
                                            current={editState.currentActive}
                                            isValid={editState[EDIT_PHOTO_ENUM[key].key]}
                                            title={EDIT_PHOTO_ENUM[key].title}
                                            key={k}
                                        />);
                                        if (userType === roleEnum.AGENCY) {
                                            if (EDIT_PHOTO_ENUM[key] !== EDIT_PHOTO_ENUM.SET_AGENCY) {
                                                return el;
                                            } else {
                                                return <></>
                                            }
                                        } else {
                                            return el;
                                        }
                                    }
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="cont-ph-editup-m">
                        {editState.currentActive === "SET_TITLEND" && (
                            <>
                                <TitlenDesc parentAction={updateEditChanges}
                                            originalData={originalData}
                                />
                                <BtnContainer type={editState.currentActive}
                                              handleBtnClick={handleBtnClick}
                                />
                            </>
                        )}
                        {editState.currentActive === "SET_CREDITS" && (
                            <>
                                <CreditsData parentAction={updateEditChanges}
                                             originalData={originalData}
                                />
                                <BtnContainer type={editState.currentActive}
                                              handleBtnClick={handleBtnClick}
                                />
                            </>
                        )}
                        {editState.currentActive === "SET_METADATA" && (
                            <>
                                <MetadataData parentAction={updateEditChanges}
                                              originalData={originalData}
                                              type={editState.currentActive}
                                />
                                <BtnContainer type={editState.currentActive}
                                              handleBtnClick={handleBtnClick}
                                />
                            </>
                        )}
                        {editState.currentActive === "SET_SPECIAL" && (
                            <>
                                <SpecInstructions originalData={originalData}
                                                  parentAction={updateEditChanges}
                                                  type={editState.currentActive}
                                />
                                <BtnContainer type={editState.currentActive}
                                              handleBtnClick={handleBtnClick}
                                />
                            </>
                        )}
                        {editState.currentActive === "SET_KEYWORD" && (
                            <>
                                <KeywordsData originalData={originalData}
                                              parentAction={updateEditChanges}
                                              type={editState.currentActive}
                                />
                                <BtnContainer type={editState.currentActive}
                                              handleBtnClick={handleBtnClick}
                                />
                            </>
                        )}
                        {editState.currentActive === "SET_AGENCY" && (
                            <AgencyData originalData={originalData}
                                        parentAction={updateEditChanges}
                                        type={editState.currentActive}
                            />
                        )}
                        {editState.currentActive === "SET_MARKET" && (
                            <MarketType originalData={originalData}
                                        parentAction={updateEditChanges}
                                        type={editState.currentActive}
                            />
                        )}
                        {editState.currentActive === "SET_SERIES" && (
                            <SeriesData
                                id1="serie"
                                id2="newSerie"
                                handleBtnClick={handleBtnClick}
                                type="SET_SERIES"
                                imageId={imageId}
                            />
                        )}
                        {editState.currentActive === "SET_COLORS" && (
                            <MColorsData handleBtnClick={handleBtnClick} type="SET_COLORS"/>
                        )}
                    </div>
                    <div className="cont-ph-editup-r">
                        <RightSideEdit image={originalData} data={formValues}/>
                    </div>
                    <div className="mobile-r-certif">
                        <img className="btn-certif-mob" src={PixtCertif} alt="button toggle certification" onClick={() => setMobCertif(true)} />
                    </div>
                </div>
                { mobCertif && <CertifMobile originalData={originalData} formValues={formValues} parentAction={() => setMobCertif(false)} /> }
            </div>
        );
};
export default PhotogEditUp;
