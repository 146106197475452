import React, {useEffect, useRef} from 'react';
import Img from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../../assets/statics/cloud-image';
import SecondaryBtn from '../../../Buttons/Template/SecondaryBtn';
import TertiaryBtn from '../../../Buttons/Template/TertiaryBtn';

const ViewAdded = ({picture, selectedMb}) => {
    const placeholderBtn = useRef();

    useEffect(() => placeholderBtn.current.className += ' displayed', []);

    return (
        <div style={{padding: "1rem"}} className="add-view-success">
            <div style={{padding: "0 3rem"}}>
                <Img src={cloudimageConfig.baseURL + picture.fileName} width="200px" height="100px"/>
            </div>
            <div className="text-center" style={{margin: "0.5rem auto"}}>
                <span className="typo-size-small">Image added to</span><br/><span className="grifitol-bold">{selectedMb.title}</span>
            </div>
            <a href={`/moodboard/edit/${selectedMb._id}`} style={{position: "relative"}}>
                <SecondaryBtn className="btn-full-width btn-default-height"
                              style={{marginLeft: 0, fontSize: "1em", zIndex: "0"}}
                >
                    Modify
                </SecondaryBtn>
                <div className="button-placeholder">
                    <div className="button-placeholder-content" ref={placeholderBtn}>
                        <TertiaryBtn className="button tertiary btn-default-height"
                                     style={{marginLeft: 0, fontSize: "1em", width: "240px", zIndex: "1"}}
                        >
                            Modify
                        </TertiaryBtn>
                    </div>
                </div>
            </a>
        </div>
    );
};
export default ViewAdded;
