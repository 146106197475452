import React, {useContext, useEffect, useState} from 'react';
import './PurchasedImageItem.scss';

import {AuthContext} from '../../../../context/auth-context';
import {useHttpClient} from '../../../../hooks/http-hook';
import {config} from '../../../../environments';
import {BsEye} from 'react-icons/all';

// CloudImage
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';

const PurchasedImageItem = ({imageId, parentAction}) => {
    const {token} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();

    const [image, setImage] = useState();

    const baseUrl = `${config.baseUrlDev}/images/no-control/${imageId}`;

    const retrieveImageData = async () => {
        await sendRequest(baseUrl, 'GET', null, {cookies: token})
            .then((imgData) => setImage(imgData.data))
            .catch((error) => console.warn({error}));
    }

    useEffect(() => retrieveImageData(), imageId);

    return (
        <BackgroundImg className="purchased-grid-item"
                       src={cloudimageConfig.baseURL + image?.fileName}
                       onClick={() => parentAction(image?._id)}
        >
            <div className="grid-item-content">
                <BsEye/>
            </div>
        </BackgroundImg>
    );
};
export default PurchasedImageItem;
