import React from 'react'
import './Notif.css'

const Notif = (props) => {
    return (
        <>
            <div 
            className={`notif ${props.className}`}
            style={{
                background: props.bg,
                padding: props.padding,
                height: props.height,
                width: props.width,
                margin: props.margin,
                flexDirection: props.direction,
                alignItems: props.align,
                justifyContent: props.justify,
                gap: props.gap,
                overflow: props.overflow,
                left: props.left,
                right: props.right,
                top: props.top,
                bottom: props.bottom,
                border: props.border,
                color: props.color,
                position: props.position
              }}
            >
                {props.children}
            </div>
        </>
    )
}

export default Notif
