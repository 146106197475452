import React, {useEffect,useContext, useState} from 'react';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout';
import {MoodBoardForm} from '../../../components/PhotographerAction/Moodboard/Forms/Form';
import {config} from '../../../environments';
import {useHttpClient} from '../../../hooks/http-hook';
import {useHistory} from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';


export const EditMoodboardFormContainer = ({match}) => {
    const [moodboard, setMoodboard] = useState();
    const { userIdProfile} = useContext(AuthContext);
    const moodboardId = match?.params?.moodboardId;
    const getMoodboardUrl = `${config.baseUrlDev}/moodboard/${moodboardId}`;
    const {sendRequest} = useHttpClient();
    const history = useHistory();

    useEffect(async () => {
        if (moodboardId) {
            await sendRequest(getMoodboardUrl, 'GET')
                .then((data) => {

                   if(data.photographerId === userIdProfile ){ setMoodboard(data)
                }else{
                    history.push("/not-found");
                }
                   

                })
                .catch((e) => history.push('/not-found'));
        }
    }, [moodboardId]);

    return (
        <PageLayout>
            <MoodBoardForm initialValues={moodboard}/>
        </PageLayout>
    )
}
