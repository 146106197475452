import React, {useContext} from "react";
import "./AgencyData.scss";
import {AuthContext} from "../../../../../context/auth-context";
import AuthentHeader from '../../../../AuthenticationAction/AuthentHeader/AuthentHeader';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../../Shared/Forms/validators-final-form';
import { usePhotographerAgencies } from '../../../../../hooks/photographer-agencies-hook';



const AgencyData = (props) => {
    const {userIdProfile} = useContext(AuthContext);

    const formTitle = 'Agency';

    const { photographerAgencies: agencies = [] } = usePhotographerAgencies(userIdProfile);
    
    const onSubmit = (formValues) => {
        props.parentAction(formValues);
    }

    return (
        <div className="box-credits-edit">
            <AuthentHeader title={formTitle}/>
            <Form initialValues={props.originalData}
                  onSubmit={onSubmit}
                  render={({submitError, handleSubmit, submitting}) => (
                      <form onSubmit={handleSubmit} className="login-form">
                          <Field name="agency" validate={required}>
                              {({input, meta}) => (
                                  <div className="field-group">
                                      <label htmlFor="agency">Select from existing</label>
                                      <select {...input} type="select"
                                              id="agency"
                                              className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                      >
                                          <option/>
                                          {agencies.map((a, k) => <option value={a._id}
                                                                          key={k}>{a.companyName}</option>)}
                                      </select>
                                  </div>
                              )}
                          </Field>
                          {submitError &&
                          <div>
                              <div className="authent-error">{submitError}</div>
                          </div>
                          }
                          <div className="field-group">
                              <FormSpy subscription={{pristine: true, invalid: true}}>
                                  {props => {
                                      const isInvalid = submitting || props.invalid;
                                      return (
                                          <button
                                              type="submit"
                                              disabled={isInvalid}
                                              className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                              style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                          >
                                              Save
                                          </button>
                                      )
                                  }}
                              </FormSpy>
                          </div>
                      </form>
                  )}
            />
        </div>
    );
};

export default AgencyData;
