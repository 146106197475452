import { render } from "@testing-library/react";
import React from "react";
import {Field, Form} from "react-final-form";
import {required} from "../../Forms/validators-final-form";
import Toaster from "../../Toaster/Toaster";
import "./BioDescription.scss";

const BioDescription = ({description, parentAction, parentRef}) => {
    const onSubmit = (formValues) => {
        if (parentAction) {
            parentAction(formValues, () =>  render(<Toaster success toastMsg="Bio successfuly updated"/>));
        }
    };

    return (
        <Form
            initialValues={{description}}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Field name="description" validate={required} type="textarea">
                        {({input, meta}) => (
                            <div className="field-group-biodesc">
                                <textarea
                                    {...input}
                                    ref={parentRef}
                                    id="description"
                                    className={`input-reg ${
                                        meta.error && meta.touched ? "in-error" : ""
                                    }`}
                                />
                            </div>
                        )}
                    </Field>
                </form>
            )}
        />
    );
};
export default BioDescription;
