import React from 'react';
import './ResultCard.scss';
import {Link} from 'react-router-dom';
import {BsBoxArrowUpRight} from 'react-icons/bs';
import {BackgroundImg} from 'react-cloudimage-responsive';
import {cloudimageConfig} from '../../../../assets/statics/cloud-image';

const ResultCard = (props) => {
    return (
        <li className="upload-result-card">
            Status : {props.status}
            <div className="agency-data">
                <BackgroundImg src={cloudimageConfig.baseURL + props.name}
                               className="uploaded-visu"
                />
                <div className="names-container">
                    <h5>Original name : {props.original}</h5>
                    <h6>File name : {props.name}</h6>
                </div>
                <Link to={'/upload/edit/' + props.id}>
                    <BsBoxArrowUpRight className="redirect-icon"/>
                </Link>
            </div>
        </li>
    );
}
export default ResultCard;
