export const required = value => (value && value !== '' ? undefined : '* Required');
export const maxLength = max => value => (value && value.length <= max ? undefined : `* Must be shorter than ${+max + 1} chars`);
export const minLength = min => value => (value && value.length >= min) ? undefined : `* Should be greater than ${min}`
export const requiredCheckbox = value => {
    return value && value !== '' ? undefined : '* Required';
};
export const requiredPhone = value => {
    return value && +value && value.length >= 7 ? undefined : '* Required';
};
export const checkPassword = (password, confirmPassword) => {
    return (password && confirmPassword && password === confirmPassword) ? undefined : '* Passwords must be same';
};
