import React, { useContext, useState } from 'react';
import { SeriesForm } from '../../../components/PhotographerAction/Series/Forms/Form';
import PageLayout from '../../../components/Shared/PageLayout/PageLayout';
import { AuthContext } from '../../../context/auth-context';
import { useImages } from '../../../hooks/images/useImage';
import { usePhotographerAgencies } from '../../../hooks/photographer-agencies-hook';
import { useSeries } from '../../../hooks/series/useSeries';


export const AddSeriesFormContainer = () => {
    const {userIdProfile, userType} = useContext(AuthContext);
    const {photographerAgencies} = usePhotographerAgencies(userIdProfile);
    const { filterAcceptedImages, filteredImages, isLoading } = useImages();
    const { createSeries } = useSeries();

    const [keywords, setKeywords] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);

    const handleSubmit = async (formValues) => {
        formValues.keywords = keywords;
        formValues.images = selectedImages
        await createSeries(formValues);
    }

    const handleSelectAgency = async (selectedAgencyId) => {
        setSelectedImages([]);
        setSelectedAgency(selectedAgencyId);
        await filterAcceptedImages(userIdProfile, selectedAgencyId);
    }

    const toggleImage = (imageId) => {
        if (selectedImages.includes(imageId)) {
            const i = selectedImages.indexOf(imageId);
            const sI = [...selectedImages];
            sI.splice(i, 1);
            setSelectedImages(sI);
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }
    return(
        <PageLayout>
            <SeriesForm 
                onSubmit={handleSubmit} 
                photographerAgencies={photographerAgencies}
                userType={userType}
                keywords={keywords}
                updateKeywordsList={setKeywords}
                selectedAgency={selectedAgency}
                onSelectAgency={handleSelectAgency}
                selectedImages={selectedImages}
                isLoading= {isLoading}
                agencyPhotographerImages={filteredImages}
                toggleImage={toggleImage}
            />
        </PageLayout>
    )
}