import React from "react";
import "./Footer.scss";
import logo from '../../../../assets/images/logo_pixt.png'

const Footer = () => {
    return (
        <>
            <div className="footer-container">
                <div className="footer boxed-layout gl-col">
                    <div className="footer-box gl-col-4 display-flex justify-content-center"><img src={logo} alt="logo"/></div>
                    <div className="footer-box gl-col-2">
                        <h6 className="secondary-color">About us</h6>
                        <p>Who are we ?</p>
                        <p>Why and for who ?</p>
                        <p>Our Team</p>
                        <p>Career</p>
                        <p>Partners</p>
                    </div>
                    <div className="footer-box gl-col-2">
                        <h6 className="secondary-color">Pix.T For Trust</h6>
                        <p>How ?</p>
                        <p>Support</p>
                        <p>Help Center</p>
                    </div>
                    <div className="footer-box gl-col-2">
                        <h6 className="secondary-color">Terms</h6>
                        <p>Privacy Policy</p>
                        <p>Cookies</p>
                        <p>Site map</p>
                    </div>
                    <div className="footer-box gl-col-2">
                        <h6 className="secondary-color">Follow us</h6>
                        <p>Instagram</p>
                        <p>Twitter</p>
                        <p>Facebook</p>
                    </div>
                </div>
                <div className="copyright">@copyright 2021 - Pix.T</div>
            </div>
        </>
    );
};

export default Footer;
