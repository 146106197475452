import React from 'react'
import "./Form.scss";

import {Field, Form, FormSpy} from 'react-final-form';
import { required } from '../../Shared/Forms/validators-final-form';

import FormActions from '../Moodboard/FormActions/FormActions';
import ImagesManagement from '../Moodboard/ImagesManagement/ImagesManagement';
import { roleEnum } from '../../../assets/statics/authent-signup.enum';
import { SharedMediaHeader } from '../../Shared/MediaHeaders/SharedMediaHeader';
import StoryCredits from './StoryDetails/StoryCredits';


export function StoryForm({
    

    initialValues,
    photographerAgencies,
    onSubmit,
    selectedAgency,
    onSelectAgency,
    agencyPhotographerImages,
    isLoading,
    keywords,
    updateKeywordsList,
    selectedImages,
    toggleImage,
    setOpenSelectImagesModal,
    openSelectImagesModal,
    handleClose,
    userType
}) {
    
    
    return(
        <>
            {userType === roleEnum.AGENCY && <SharedMediaHeader elementValues={initialValues}/>}
            {userType === roleEnum.PHOTOGRAPHER &&
                <section className="box-cont-head page-section boxed-layout">
                    <Form onSubmit={onSubmit} initialValues={initialValues}
                        render={({submitError, handleSubmit, submitting}) => (
                            <form onSubmit={handleSubmit} className="story-edit-form">
                                <div className="form-container">
                                    <Field name="agencyId" validate={required}>
                                        {({input, meta}) => (
                                            <div className="field-group">
                                                <label htmlFor="agency">Agency</label>
                                                <select {...input} 
                                                    type="select"
                                                    id="agency"
                                                    onChangeCapture={(e) => onSelectAgency(e.target.value)}
                                                    className={`input-reg ${meta?.error && meta?.touched ? 'in-error' : ''}`}>
                                                        <option/>
                                                        {photographerAgencies.map((agency, index) => <option value={agency._id} key={index}> {agency.companyName} </option>)}
                                                </select>

                                            </div>
                                        )}    
                                    </Field>
                                    <Field name="title" validate={required}>
                                        {({input, meta}) => (
                                            <div className="field-group">
                                                <label htmlFor="story-title">Title</label>
                                                <input {...input} 
                                                    type="text"
                                                    id="story-title"
                                                    className={`input-reg ${meta?.error && meta?.touched ? 'in-error' : ''}`}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="description">
                                        {({input}) => (
                                            <div className="field-group">
                                                <label htmlFor="story-description">Description</label>
                                                <textarea {...input} 
                                                    type="text" 
                                                    id="story-description"
                                                    className="input-reg"
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="field-group">
                                    <FormSpy subscription={{invalid: true}}>
                                        {props => {
                                            const isInvalid = submitting || props.invalid;
                                            return (
                                                <button
                                                    type="submit"
                                                    disabled={isInvalid || isLoading}
                                                    className={`button primary ${(isInvalid || isLoading) ? 'disabled' : ''}`}
                                                    style={{textAlign: "center", padding: "0.5rem 2rem"}}
                                                >
                                                    Save
                                                </button>
                                            )
                                        }}
                                    </FormSpy>
                                </div>
                                { submitError &&
                                    <div>
                                        <div className="authent-error">{submitError}</div>
                                    </div>
                                }
                            </form>
                        )}
                    >  </Form> {initialValues?._id &&
                        <StoryCredits photographerId={initialValues?.photographerId}
                                          storyId={initialValues?._id}
                                          style={{marginTop: "2rem"}}
                        />
                        }
                  
                    <FormActions 
                        keywords={keywords}
                        isSelectedAgency={selectedAgency && true}
                        setIsOpen={setOpenSelectImagesModal}
                        updateTagsList={updateKeywordsList}
                    />
                </section>
            }
            <ImagesManagement 
                photographerAgencyImages={agencyPhotographerImages}
                toggleImage={toggleImage}
                selectedImages={selectedImages}
                isOpen={openSelectImagesModal}
                handleClose={handleClose}
            />
        </>
    )
}