import React from 'react';
import BtnBack from '../../../Shared/Buttons/BtnBack/BtnBack';
import Label from '../../../Shared/Forms/Input/Label/Label';
import InputReg from '../../../Shared/Forms/Input/InputReg';
import {VALIDATOR_REQUIRE} from '../../../Shared/Forms/validators';

const UploadForm = ({submitForm, handleChange, pseudoInput, title, history, error, submitting, credit}) => {
    const disabled = submitting || title.current?.value.trim() === '' || credit.current?.value.trim() === '';
    return (
        <div className="box-cont-head-notch">
            <form
                method="POST"
                encType="multipart/form-data"
                onSubmit={submitForm}
            >
                <div className="entry-point-notch field-group">
                    <Label htmlFor="title">Title</Label>
                    <InputReg validators={VALIDATOR_REQUIRE}
                              type="text"
                              id="title"
                              width="100%"
                              className="input-reg"
                              parentRef={title}
                              parentAction={() => {
                                  handleChange(title);
                              }}
                    />
                </div>
                <div className="entry-point-notch field-group">
                    <Label htmlFor="title">Credit</Label>
                    <InputReg validators={VALIDATOR_REQUIRE}
                              type="text"
                              id="credit"
                              width="100%"
                              className="input-reg"
                              parentRef={credit}
                              parentAction={() => {
                                  handleChange(credit);
                              }}
                    />
                </div>
                <div className="entry-point-second-notch field-group">
                    <Label htmlFor="file">Choose an image</Label>
                    <input
                        type="file"
                        id="file"
                        name="myImage"
                        multiple
                        accept="image/*"
                        required
                        ref={pseudoInput}
                        onChange={() => {
                            handleChange(pseudoInput);
                        }}
                    />
                    <span className="file-custom"/>
                </div>
                <div className="field-group">
                    <InputReg
                        display="flex"
                        type="submit"
                        left="100px"
                        width="100%"
                        height="40px"
                        className={`button ${disabled ? 'disabled' : 'secondary'}`}
                        disabled={disabled}
                    />
                </div>
                {error &&
                `${error}`
                }
            </form>
        </div>
    );
}
export default UploadForm;
