import React from 'react';
import {NavLink} from 'react-router-dom';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import Name from '../../name';

const AdminLinks = (props) => {
    return (
        <>
            <li>
                <NavLink className="links" to="/admin/dashboard">
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink className="links" to="/admin/reviews">
                    Reviews
                </NavLink>
            </li>
            <li>
                <div className="links user">
                    <span onClick={props.dropdownHandler}>
                        <Name placeholder="Admin"/>
                    </span>
                    <ProfileMenu dropDown={props.dropDown} dropdownHandler={props.dropdownHandler}/>
                </div>
            </li>
        </>
    )
};
export default AdminLinks;
