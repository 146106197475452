import React, {useContext, useState} from 'react';
import './admin-demands.scss';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import {viewAdminEnum} from '../../assets/statics/reviews-admin-step.enum';
import AdminDemandsMenu from '../../components/AdminAction/AdminDemandsMenu/AdminDemandsMenu';
import NoImagesGrid from '../../components/Shared/NoImagesGrid/NoImagesGrid';
import {config} from '../../environments';
import {statusEnum} from '../../assets/statics/reviews-step.enum';
import {roleEnum} from '../../assets/statics/authent-signup.enum';
import {useHttpClient} from '../../hooks/http-hook';
import SidebarPhotographer
    from '../../components/AgencyAction/AgencyPhotographers/SidebarPhotographer/SidebarPhotographer';
import {reviewTypeEnum} from '../../assets/statics/review-type.enum';
import Toaster from '../../components/Shared/Toaster/Toaster';
import { render } from "@testing-library/react";
import {AuthContext} from '../../context/auth-context';

const AdminDemands = () => {
    const [view, setView] = useState(viewAdminEnum.PHOTOGRAPHERS);
    const [modal, setModal] = useState(false);
    const [modalItem, setModalItem] = useState();
    const [modalType, setModalType] = useState();
    const [photographers, setPhotographers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [users, setUsers] = useState([]);
    const {sendRequest} = useHttpClient();

    const { token } = useContext(AuthContext);

    const profileEndpoint = `${config.baseUrlDev}/user/filter`;
    const setStatusEndpoint = `${config.baseUrlDev}/user`;

    const setStatus = async (id, status) => {
        return await sendRequest(
            `${setStatusEndpoint}/${id}/${status}`,
            'POST',
            null,
            {'Content-Type': 'application/json', cookies: token}
        )
            .then(() => {
                refreshRequests()
                if(status === "accept") render(<Toaster success toastMsg="Request accepted !"/>)
                if(status === "decline") render(<Toaster success toastMsg="Request declined !"/>)
                
            })
            .catch((error) => {
                console.warn({error})
                render(<Toaster error toastMsg="Unexpected error !"/>)
            });
    }

    const getRequest = async (url, callback) => {
        return await sendRequest(
            url,
            'GET',
            null,
            { 'Content-Type': 'application/json', cookies: token }
        )
            .then((res) => callback(res))
            .catch((error) => {
                console.warn({error})
                render(<Toaster error toastMsg="Unexpected error !"/>)
            });
    }

    const detailsHandleClick = (item) => {
        setModalItem(item);
        setModalType(view);
        setModal(true);
    }

    const updateRequestStatus = (id, status) => {
        setStatus(id, status === statusEnum.ACCEPTED ? 'accept' : 'decline');
    }

    const retrievePhotographers = () => {
        getRequest(`${profileEndpoint}?status=${statusEnum.PENDING}&role=${roleEnum.PHOTOGRAPHER}`, (data) => {
            setPhotographers(data);
        });
    }

    const retrieveAgencies = () => {
        getRequest(`${profileEndpoint}?status=${statusEnum.PENDING}&role=${roleEnum.AGENCY}`, (data) => {
            setAgencies(data);
        });
    }

    const retrieveMediaUsers = () => {
        getRequest(`${profileEndpoint}?status=${statusEnum.PENDING}&role=${roleEnum.MEDIA_USER}`, (data) => {
            setUsers(data);
        });
    }

    const refreshRequests = () => {
        setModal(false);
        retrieveAgencies();
        retrieveMediaUsers();
        retrievePhotographers();
    }

    return (
        <PageLayout className="admin-reviews-page">
            <div className="admin-reviews-main">
                <AdminDemandsMenu view={view} setView={setView}/>
                <div className="admin-reviews-content">
                    {view === viewAdminEnum.PHOTOGRAPHERS &&
                    <NoImagesGrid className="page-section admin-review"
                                  data={photographers}
                                  setData={setPhotographers}
                                  retrieveData={retrievePhotographers}
                                  mode={reviewTypeEnum.ADMIN_PHOTOGRAPHERS}
                                  onClickDetails={detailsHandleClick}
                                  parentAction={updateRequestStatus}
                    />
                    }
                    {view === viewAdminEnum.AGENCIES &&
                    <NoImagesGrid className="page-section admin-review"
                                  data={agencies}
                                  setData={setAgencies}
                                  retrieveData={retrieveAgencies}
                                  mode={reviewTypeEnum.AGENCIES}
                                  onClickDetails={detailsHandleClick}
                                  parentAction={updateRequestStatus}
                    />
                    }
                    {view === viewAdminEnum.MEDIA_USERS &&
                    <NoImagesGrid className="page-section admin-review"
                                  data={users}
                                  setData={setUsers}
                                  retrieveData={retrieveMediaUsers}
                                  mode={reviewTypeEnum.ADMIN_MEDIA}
                                  onClickDetails={detailsHandleClick}
                                  parentAction={updateRequestStatus}
                    />
                    }
                </div>
                {modal &&
                (<SidebarPhotographer photographer={modalItem}
                                      setModal={setModal}
                                      type={modalType === viewAdminEnum.AGENCIES
                                          ? reviewTypeEnum.AGENCY
                                          : reviewTypeEnum.ADMIN
                                      }
                                      dataType={view}
                                      parentAction={updateRequestStatus}
                />)}
            </div>
        </PageLayout>
    )
}
export default AdminDemands;
