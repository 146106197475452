import React from 'react'
import './Bio.scss'
import Header from '../../Shared/Header/Header';

const Bio = (props) => {
    return (
        <section className="section-bio-container page-section boxed-layout">
            <div className="section-bio">
                <Header titleType={3} title={props.title} thirdLine/>
                <div className="bio-bottom">
                    {props.children}
                </div>
            </div>
        </section>
    )
}
export default Bio;
