import React, {useContext} from "react";
import './PersonalInfos.scss';
import {AuthContext} from '../../../../context/auth-context';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../Forms/validators-final-form';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';
import TertiaryBtn from '../../Buttons/Template/TertiaryBtn';
import Toaster from "../../Toaster/Toaster";
import { render } from "@testing-library/react";

const PersonalInfos = ({firstName, lastName, job, parentAction}) => {
    const {userType} = useContext(AuthContext);

    const onSubmit = (formValues) => {
        parentAction(formValues, () =>  render(<Toaster success toastMsg="Personal infos successfuly updated"/>));
    }

    return (
        <section className="personal-infos-container">
            <h4>Personal Infos</h4>
            <Form
                initialValues={{firstName, lastName, job}}
                onSubmit={onSubmit}
                render={({form, submitError, handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="personal-infos-form">
                        <Field name="firstName" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input {...input} type="text"
                                           id="firstName"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="First name"/>
                                </div>
                            )}
                        </Field>
                        <Field name="lastName" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input {...input} type="text"
                                           id="lastName"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Last name"/>
                                </div>
                            )}
                        </Field>
                        {(userType !== roleEnum.USER && userType !== roleEnum.PHOTOGRAPHER) &&
                        <Field name="job" validate={required}>
                            {({input, meta}) => (
                                <div className="field-group">
                                    <label htmlFor="job">Job title</label>
                                    <input {...input} type="text"
                                           id="job"
                                           className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                           placeholder="Job"/>
                                </div>
                            )}
                        </Field>
                        }
                        <div className="field-group btn-container">
                            <TertiaryBtn style={{padding: "0.5rem 1rem"}}
                                         parentAction={() => form.reset()}
                            >
                                Cancel
                            </TertiaryBtn>
                            <FormSpy subscription={{invalid: true}}>
                                {props => {
                                    const isInvalid = submitting || props.invalid;
                                    return (
                                        <button
                                            type="submit"
                                            disabled={isInvalid}
                                            className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                            style={{padding: "0.5rem 1rem"}}
                                        >
                                            Save
                                        </button>
                                    )
                                }}
                            </FormSpy>
                        </div>
                        {submitError &&
                        <div>
                            <div className="authent-error">{submitError}</div>
                        </div>
                        }
                    </form>
                )}
            />
        </section>
    );
};

export default PersonalInfos;
