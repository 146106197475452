import React from "react";
import "./Edit.css";
import {BsPencil} from 'react-icons/bs'

const Edit = (props) => {
  return (
    <>
      <div
        className={`edit ${props.className}`}
        onClick={props.onClick}
        style={{
          background: props.bg,
          color: props.color,
          width: props.width,
          borderRadius: props.radius,
          height: props.height,
          left: props.left,
          right: props.right,
          top: props.top,
          bottom: props.bottom,
          margin: props.margin,
          position: props.position

        }}
      >
        <BsPencil/>
      </div>
    </>
  );
};

export default Edit;
