import React from "react";
import "./BtnBack.css";
import {CgArrowLongLeft} from 'react-icons/cg'

const BtnBack = (props) => {
    return (
        <>
            <div
                className={`btn-back1 ${props.className}`}
                onClick={props.onClick}
                style={{
                    background: props.bg,
                    color: props.color,
                    width: props.width,
                    borderRadius: props.radius,
                    height: props.height,
                    left: props.left,
                    right: props.right,
                    top: props.top,
                    bottom: props.bottom,
                    margin: props.margin,
                    position: props.position
                }}
            >
                <CgArrowLongLeft className="arrow-back"/>
                {props.children}
            </div>
        </>
    );
};

export default BtnBack;
