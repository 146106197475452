import React from "react";
import "./MicroPayment1.css";
import { SiLetsencrypt } from "react-icons/si";


const MicroPayment1 = () => {
  return (
    <>
      <div className="section-mic1">
        <div className="container-mic-top">
          <h2>
            Copyrights <br /> of digital <br /> images
          </h2>
        <div className="reg-mic" > <SiLetsencrypt className="icon-encrypt1"/> </div>
        </div>
        <div className="container-mic-middle">
          <p>
            Looks like you're here for improper use of images or content.  <br/> <br/> Don't
            panic, however, you can easily remedy this problem by helping to
            protect the work of creatives by purchasing a license that
            corresponds to your use of the image.
          </p>
        </div>
        <div className="container-mic-bottom">
            <div className="btn-mic1">Purchase now</div>
            <div className="btn-mic1 second">View licences</div>
        </div>
      </div>
    </>
  );
};

export default MicroPayment1;
