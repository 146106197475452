import React from "react";
import MostBoxs from "./MostBoxs/MostBoxs";
import "./Statistics.scss";
import Stats from "./Stats/Stats";

const Statistics = () => {
    return (
        <>
            <section className="section-statistics boxed-layout page-section gl-col">
                <Stats
                    title1="Certificates"
                    value1={14}
                    titlebox1="Series"
                    valuebox1={12}
                    titlebox2="Pending Requests"
                    valuebox2={9}
                    title3="Moodboards"
                    value3={22}
                />

                <MostBoxs titleh2="liked" number1={1242} title1="Number of likes" credits="Credit"
                          valuecredits="Andrea Bruce."/>
                <MostBoxs titleh2="viewed" number1={11765} title1="Number of views" credits="Credit"
                          valuecredits="Henrietta Greer"/>
                <MostBoxs titleh2="purchased" number1={976} title1="Number of purchases" credits="Credit"
                          valuecredits="Duane Reynolds"/>

            </section>
        </>
    );
};

export default Statistics;
