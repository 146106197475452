import React, {useState} from 'react';
import './ContactBtn.scss';

const ContactBtn = (props) => {
    const [href, setHref] = useState('');
    return (
        <a onMouseEnter={() => setHref(`mailto:${props.email}`)}
           onMouseLeave={() => setHref('')}
           href={href}
           className="contact-email-btn"
        >
            {props.children}
        </a>
    );
}
export default ContactBtn;
