import React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {required} from '../../../Shared/Forms/validators-final-form';
import {roleEnum} from '../../../../assets/statics/authent-signup.enum';

const RoleForm = ({registrationBody, submitStep, btnLabel}) => {
    const accountTypes = Object.keys(roleEnum).map((key) => roleEnum[key]);
    return (
        <Form
            initialValues={registrationBody}
            onSubmit={submitStep}
            render={({handleSubmit, submitting}) => (
                <form onSubmit={handleSubmit} className="registration-form">
                    <Field name="roles" validate={required}>
                        {({input, meta}) => (
                            <div className="field-group">
                                <label>User role</label>
                                <select {...input} type="select"
                                        className={`input-reg ${meta.error && meta.touched ? 'in-error' : ''}`}
                                >
                                    <option/>
                                    {accountTypes.map((type, k) => <option value={type} key={k}>{type}</option>)}
                                </select>
                            </div>
                        )}
                    </Field>
                    <div className="field-group">
                        <FormSpy subscription={{pristine: true, invalid: true}}>
                            {props => {
                                const isInvalid = submitting || props.invalid;
                                return (
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={`button secondary ${isInvalid ? 'disabled' : ''}`}
                                        style={{width: "100%", textAlign: "center", padding: "0.5rem 0"}}
                                    >
                                        {btnLabel}
                                    </button>
                                )
                            }}
                        </FormSpy>
                    </div>
                </form>
            )}
        />
    )
};
export default RoleForm;
