import React from 'react';
import BigBanderole from "../../../Shared/Banderole/Template/BigBanderole/BigBanderole";

const PhotographersSeries = (props) => {
    const fakeData = {
        title: 'Photographers Series',
        data: [
            { title: 'Series 1', main: 'bg-about1.jpg' },
            { title: 'Series 2', main: 'bg-myimg1.jpg' },
            { title: 'Series 3', main: 'bg-myimg2.jpg' },
            { title: 'Series 4', main: 'bg-same3.jpg' },
            { title: 'Series 5', main: 'bg-myimg3.jpg' },
            { title: 'Series 6', main: 'bg-myimg4.jpg' },
        ]
    };
    return (
        <BigBanderole title={fakeData.title} data={fakeData.data} titleType={props.titleType}
                      containerId="photographers-series" />
    );
};
export default PhotographersSeries;
