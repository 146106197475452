import React, {useEffect, useState} from "react";
import "./MoodBoardHedaer.scss";
import MoodboardCredits from './MoodboardCredits/MoodboardCredits';

const MoodboardHeader = ({moodboard}) => {
    const defaultTitle = `My<br/>moodboard`;
    const defaultDesc = `When asked, “If you could wish for one thing only, what would that
                    wish be?” almost everyone; from beauty pagent contestants, to
                    politicians, to religious leaders, to children, to the average
                    person on the street states, “Peace On Earth” or “An end to all
                    wars”.`;

    const [title, setTitle] = useState(defaultTitle);
    const [desc, setDesc] = useState(defaultDesc)

    useEffect(() => {
        if (moodboard) {
            setTitle(moodboard.title ? moodboard.title : defaultTitle);
            setDesc(moodboard.description ? moodboard.description : defaultDesc);
        }
    })

    return (
        <section className="section-moodboard-header page-section boxed-layout">
            <div className="moodboard-hedaer-content">
                <header className="main-title moodboard-title">
                    <h1 className="typo-size-bigger" dangerouslySetInnerHTML={{__html: title}}/>
                </header>
                <p className="moodboard-description" style={{marginBottom: "2rem"}}>
                    {desc}
                </p>
                <MoodboardCredits photographerId={moodboard?.photographerId} moodboardId={moodboard?._id}/>
            </div>
        </section>
    );
};

export default MoodboardHeader;
