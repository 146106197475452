import React from 'react'
import './LinksLeft.scss'
import {TiTick} from 'react-icons/ti'

const LinksLeft = (props) => {
    return (
        <li className="row-left-links">
            {props.isValid  &&
                <TiTick className="tick"/>
            }
            <p className={`${(props.setCurrent === props.current) || props.isValid ? 'active phantom-bold' : ''}`}
               onClick={() => props.parentAction(props.setCurrent)}
               style={{marginBottom: "0"}}
            >
                {props.title}
            </p>
        </li>
    )
}

export default LinksLeft
