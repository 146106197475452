import React from 'react';
import SecondaryBtn from '../../../Shared/Buttons/Template/SecondaryBtn';
import TagsInput from '../../../Shared/KeywordsInput/TagsInput';

const FormActions = ({isSelectedAgency = false, setIsOpen, keywords, updateTagsList}) => {
    return (
        <div className="moodboard-actions"
             style={{display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
            <SecondaryBtn className={`btn-default-height ${!isSelectedAgency ? 'disabled' : ''}`}
                          parentAction={() => isSelectedAgency ? setIsOpen(true) : null}
            >
                Add images
            </SecondaryBtn>
            <TagsInput tagsList={keywords}
                       updateTagsList={updateTagsList}
            />
        </div>
    );
};
export default FormActions;
