import React, {useContext, useState} from 'react';
import './agenct-photographers.scss';
import PageLayout from '../../components/Shared/PageLayout/PageLayout';
import MenuPhotographers from '../../components/AgencyAction/AgencyPhotographers/MenuPhotographers/MenuPhotographers';
import SidebarPhotographer
    from '../../components/AgencyAction/AgencyPhotographers/SidebarPhotographer/SidebarPhotographer';
import {statusEnum} from '../../assets/statics/reviews-step.enum';
import {AuthContext} from '../../context/auth-context';
import {useHttpClient} from '../../hooks/http-hook';
import NoImagesGrid from '../../components/Shared/NoImagesGrid/NoImagesGrid';
import {config} from '../../environments';
import {reviewTypeEnum} from '../../assets/statics/review-type.enum';
import Toaster from '../../components/Shared/Toaster/Toaster';
import { render } from '@testing-library/react';

const AgencyDemandsPhotographers = () => {
    const [view, setView] = useState(statusEnum.ACCEPTED);
    const [modal, setModal] = useState(false);

    const [modalPhotographer, setModalPhotographer] = useState();

    const [acceptedReviews, setAcceptedReviews] = useState([]);
    const [declinedReviews, setDeclinedReviews] = useState([]);
    const [pendingReviews, setPendingReviews] = useState([]);

    const [requestData, setRequestData] = useState();

    const {userIdProfile} = useContext(AuthContext);
    const {sendRequest} = useHttpClient();

    const retrieveDemands = async (requestStatus) => {
        const agencyDemandsEndpoint = `${config.baseUrlDev}/join-agency-request/agency/${userIdProfile}/${requestStatus}`;
        return await sendRequest(
            agencyDemandsEndpoint, 'GET', null, {'Content-Type': 'application/json'}
        )
            .then((data) => {
                switch (requestStatus) {
                    case statusEnum.ACCEPTED:
                        setAcceptedReviews(data)
                        break;
                    case statusEnum.DECLINED:
                        setDeclinedReviews(data)
                        break;
                    case statusEnum.PENDING:
                        setPendingReviews(data)
                        break;
                    default:
                        break;
                }
            })
            .catch((e) => {
                console.warn({e})
                render(<Toaster error toastMsg="Unexpected error !"/>)
            });
    };

    const reloadAllDemands = async () => {
        setModal(false);
        Object.keys(statusEnum).map((key) => retrieveDemands(statusEnum[key]));
    }

    const updateStatus = async (requestId, status) => {
        let s = (status === statusEnum.ACCEPTED) ? 'accept' : 'decline';
        const updateStatus = `${config.baseUrlDev}/join-agency-request/${requestId}/${s}`;
        return await sendRequest(
            updateStatus, 'POST', null, {'Content-Type': 'application/json'}
        )
            .then(() => {
                reloadAllDemands()
                if(s === 'accept') render(<Toaster success toastMsg="Photographer accepted !" />)
                if(s === 'decline') render(<Toaster success toastMsg="Photographer declined !" />)
            })
            .catch((e) => {
                console.warn(e)
                render(<Toaster error toastMsg="Unexpected error !" />)
            });
    };

    const onClickDetails = (data, request) => {
        setRequestData(request);
        setModalPhotographer(data);
        setModal(true);
    };

    return (
        <PageLayout>
            <div className="photographers-demands-container">
                <MenuPhotographers setView={setView} view={view}/>
                <div className="agency-reviews-content">
                    {view === statusEnum.ACCEPTED &&
                    <NoImagesGrid className="page-section photographers-demands"
                                  data={acceptedReviews}
                                  retrieveData={() => retrieveDemands(statusEnum.ACCEPTED)}
                                  status={statusEnum.ACCEPTED}
                                  parentAction={updateStatus}
                                  onClickDetails={onClickDetails}
                                  mode={reviewTypeEnum.AGENCY_PHOTOGRAPHERS}
                    />
                    }
                    {view === statusEnum.PENDING &&
                    <NoImagesGrid className="page-section photographers-demands"
                                  data={pendingReviews}
                                  retrieveData={() => retrieveDemands(statusEnum.PENDING)}
                                  status={statusEnum.PENDING}
                                  parentAction={updateStatus}
                                  onClickDetails={onClickDetails}
                                  mode={reviewTypeEnum.AGENCY_PHOTOGRAPHERS}
                    />
                    }
                    {view === statusEnum.DECLINED &&
                    <NoImagesGrid className="page-section photographers-demands"
                                  data={declinedReviews}
                                  retrieveData={() => retrieveDemands(statusEnum.DECLINED)}
                                  status={statusEnum.DECLINED}
                                  parentAction={updateStatus}
                                  onClickDetails={onClickDetails}
                                  mode={reviewTypeEnum.AGENCY_PHOTOGRAPHERS}
                    />
                    }
                </div>
            </div>
            {modal &&
            <SidebarPhotographer photographer={modalPhotographer}
                                 setModal={setModal}
                                 parentAction={updateStatus}
                                 requestData={requestData}
            />
            }
        </PageLayout>
    )
}
export default AgencyDemandsPhotographers;
