/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import PageLayout from '../../../components/Shared/PageLayout/PageLayout';
import { SeriesForm } from '../../../components/PhotographerAction/Series/Forms/Form';

import { AuthContext } from '../../../context/auth-context';
import { useImages } from '../../../hooks/images/useImage';
import { usePhotographerAgencies } from '../../../hooks/photographer-agencies-hook';
import { useSeries } from '../../../hooks/series/useSeries';
import {useHistory} from 'react-router-dom';
import { roleEnum } from '../../../assets/statics/authent-signup.enum';
import { SharedMediaHeader } from '../../../components/Shared/MediaHeaders/SharedMediaHeader';
import { config } from '../../../environments';
import { useHttpClient } from '../../../hooks/http-hook';


export const EditSeriesFormContainer = ({match}) => {
    const seriesId = match?.params?.seriesId;
    const getSerieUrl = `${config.baseUrlDev}/serie/${seriesId}`;
    const {userIdProfile, userType} = useContext(AuthContext);
    const {photographerAgencies} = usePhotographerAgencies(userIdProfile);
    const { filterAcceptedImages, filteredImages, isLoading } = useImages();
    const { updateSeries, foundSeries, getSeriesById } = useSeries();
    const {sendRequest} = useHttpClient();
    const [keywords, setKeywords] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const history = useHistory();
    const handleSubmit = async (formValues) => {
       
        formValues.keywords = keywords;
        formValues.images = selectedImages
        await updateSeries(seriesId, formValues);
    }

    const handleSelectAgency = async (selectedAgencyId) => {
        setSelectedImages([]);
        setSelectedAgency(selectedAgencyId);
        await filterAcceptedImages(userIdProfile, selectedAgencyId);
    }

    const toggleImage = (imageId) => {
        if (selectedImages.includes(imageId)) {
            const i = selectedImages.indexOf(imageId);
            const sI = [...selectedImages];
            sI.splice(i, 1);
            setSelectedImages(sI);
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }

    useEffect(async () => {
       if(seriesId) {
        await sendRequest(getSerieUrl, 'GET')
        .then((data) => {

           if(data.photographerId === userIdProfile ){  getSeriesById(seriesId);
        }else{
            history.push("/not-found");
        }
           

        })
        .catch((e) => history.push('/not-found'));

        
       }
    },[seriesId])

    useEffect(async () => {
        if(selectedAgency) {
            await filterAcceptedImages(userIdProfile, selectedAgency);
        }
    },[selectedAgency])

    useEffect(async () => {
        setKeywords(foundSeries?.keywords);
        setSelectedImages(foundSeries?.images);
        setSelectedAgency(foundSeries?.agencyId);
    },[foundSeries])

    return(
        <PageLayout>
                <SeriesForm
                    initialValues={foundSeries}
                    onSubmit={handleSubmit} 
                    photographerAgencies={photographerAgencies}
                    userType={userType}
                    keywords={keywords}
                    updateKeywordsList={setKeywords}
                    selectedAgency={selectedAgency}
                    onSelectAgency={handleSelectAgency}
                    selectedImages={selectedImages}
                    isLoading= {isLoading}
                    agencyPhotographerImages={filteredImages}
                    toggleImage={toggleImage}
                />
        </PageLayout>
    )
}