import React from 'react'
import './Stats.scss'

const Stats = (props) => {
    return (
        <>
            <div className="container-stats gl-col-3">
                <h3 style={{height: "75px"}}>Statistics</h3>
                <div className="banderole-stats">
                    <div className="number-stats">{props.value1}</div>
                    <div className="title-stats">{props.title1}</div>
                </div>
                <div className="banderole-stats v2">
                    <div className="box-stats1">
                        <div className="number-stats">{props.valuebox1}</div>
                        <div className="title-stats">{props.titlebox1}</div>
                    </div>
                    <div className="box-stats2">
                        <div className="number-stats">{props.valuebox2}</div>
                        <div className="title-stats">{props.titlebox2}</div>
                    </div>
                </div>
                <div className="banderole-stats">
                    <div className="number-stats">{props.value3}</div>
                    <div className="title-stats">{props.title3}</div>
                </div>
            </div>
        </>
    )
}

export default Stats
