import React, {useEffect, useState} from 'react';
import './PhotographerField.scss';

const PhotographerField = ({label, value, className}) => {
    const [text, setText] = useState('');

    useEffect(() => {
        if (value) {
            (typeof value === 'object')
                ? !Array.isArray(value)
                ? setText(Object.keys(value).map((k) => value[k] ? value[k] : '-').join(', '))
                : setText(value.join(', '))
                : setText(value)
        } else {
            setText('-')
        }
    }, [value]);

    return (
        <div className={`photographer-field ${className ? className : ''}`}>
            {label &&
            <p className="field-label">{label}</p>
            }
            <p className="field-value">{text}</p>
        </div>
    )
}
export default PhotographerField;
