import React from "react";
import BoxContent from "../../components/AboutPage/BoxContent/BoxContent";
import Community from "../../components/AboutPage/Community/Community";
import Innovative from "../../components/AboutPage/Innovative/Innovative";
import AboutTitle from "../../components/AboutPage/Title/AboutTitle";
import SectionAboutTitle from "../../components/AboutPage/Title/SectionAboutTitle";
import Whatispixt from "../../components/AboutPage/WhatIsPixt/Whatispixt";
import About1 from '../../assets/images/bg-about9.jpg'
import About2 from '../../assets/images/bg-about10.jpg'
import PageLayout from '../../components/Shared/PageLayout/PageLayout';

const About = () => {
    return (
        <PageLayout>
            <Whatispixt/>
            <Community/>
            <SectionAboutTitle>
                <AboutTitle bg1={About1}/>
            </SectionAboutTitle>
            <SectionAboutTitle>
                <AboutTitle reverse="reverse" bg2={About2}/>
            </SectionAboutTitle>
            <Innovative/>
            <BoxContent/>
        </PageLayout>
    );
};

export default About;
